const flowerData =
    [
        //Hoa Chúc mừng
        { Name: 'Congrats mini size 3', Category: 'hoa-chuc-mung', Price: '950000', PriceOld: '', Images: '/HoaKhaiTruong/13370_congrats-mini-size.png' },
        { Name: 'Congrats', Price: '500000', Category: 'hoa-chuc-mung', PriceOld: '', Images: '/BoHoa/13237_congrats.jpg' },
        { Name: 'Điệu valse mùa xuân', Price: '550000', Category: 'hoa-chuc-mung', PriceOld: '', Images: '/HoaChucMung/13231_dieu-valse-mua-xuan.jpg' },
        { Name: 'Chuyện của nắng 1', Category: 'hoa-chuc-mung', Price: '750000', PriceOld: '800000', Images: '/BoHoa/13258_chuyen-cua-nang.jpg' },
        { Name: 'Kệ Chúc Mừng 02', Category: 'hoa-chuc-mung', Price: '950000', PriceOld: '1100000', Images: '/HoaChucMung/13150_ke-chuc-mung.jpg' },
        { Name: 'Congrats mini size 2', Category: 'hoa-chuc-mung', Price: '900000', PriceOld: '', Images: '/HoaChucMung/13369_congrats-mini-size.png' },
        { Name: 'Great Achievement', Category: 'hoa-chuc-mung', Price: '1200000', PriceOld: '', Images: '/HoaChucMung/12504_great-achievement.jpg' },
        { Name: 'Perfection 02', Category: 'hoa-chuc-mung', Price: '1400000', PriceOld: '', Images: '/HoaChucMung/13166_perfection.jpg' },
        { Name: 'Congrats mini size 1', Category: 'hoa-chuc-mung', Price: '950000', PriceOld: '', Images: '/HoaChucMung/13368_congrats-mini-size.jpg' },
        { Name: 'Glass vase 4', Category: 'hoa-chuc-mung', Price: '1100000', PriceOld: '1150000', Images: '/HoaChucMung/12608_glass-vase.jpg' },
        { Name: 'Hoa hạnh phúc', Category: 'hoa-chuc-mung', Price: '1700000', PriceOld: '1800000', Images: '/HoaChucMung/13305_hoa-hanh-phuc.jpg' },
        { Name: 'Ngày tươi sáng 2', Price: '1000000', Category: 'hoa-chuc-mung', PriceOld: '', Images: '/HopHoaTuoi/13194_ngay-tuoi-sang.jpg' },
        { Name: 'Luxury vase 22', Category: 'hoa-chuc-mung', Price: '1500000', PriceOld: '1700000', Images: '/HoaChucMung/13312_luxury-vase.jpg' },
        { Name: 'Premium vase 1', Price: '2800000', Category: 'hoa-chuc-mung', PriceOld: '', Images: '/HoaChucMung/13306_premium-vase.jpg' },
        { Name: 'Premium vase 4', Price: '6000000', Category: 'hoa-chuc-mung', PriceOld: '', Images: '/HoaChucMung/13309_premium-vase.jpg' },
        { Name: 'Congratulations 4', Price: '2500000', Category: 'hoa-chuc-mung', PriceOld: '', Images: '/HoaChucMung/13320_congratulations.png' },
        { Name: 'Party', Price: '950000', Category: 'hoa-chuc-mung', PriceOld: '1000000', Images: '/HopHoaTuoi/13223_party.jpg' },
        { Name: 'Scarlet', Price: '1650000', Category: 'hoa-chuc-mung', PriceOld: '1731481', Images: '/HoaChucMung/8141_scarlet.png' },
        { Name: 'Hưng Phát', Price: '2500000', Category: 'hoa-chuc-mung', PriceOld: '3055000', Images: '/HoaChucMung/12990_hung-phat.jpg' },
        { Name: 'Thẹn thùng', Category: 'hoa-chuc-mung', Price: '650000', PriceOld: '700000', Images: '/BoHoa/13232_then-thung.jpg' },
        { Name: 'Big Step', Price: '3500000', Category: 'hoa-chuc-mung', PriceOld: '', Images: '/HoaChucMung/13105_big-step.jpg' },
        { Name: 'Proud of you', Category: 'hoa-chuc-mung', Price: '700000', PriceOld: '850000', Images: '/BoHoa/13246_proud-of-you.jpg' },
        { Name: 'Tango', Category: 'hoa-chuc-mung', Price: '600000', PriceOld: '', Images: '/BoHoa/13245_tango.jpg' },
        { Name: 'Đỉnh cao', Price: '3000000', Category: 'hoa-chuc-mung', PriceOld: '', Images: '/HoaChucMung/13113_dinh-cao.jpg' },
        { Name: 'Tương Lai Tươi Sáng', Price: '2000000', Category: 'hoa-chuc-mung', PriceOld: '', Images: '/HoaChucMung/6910_tuong-lai-tuoi-sang.png' },
        { Name: 'Nỗ lực tiến bước', Price: '4500000', Category: 'hoa-chuc-mung', PriceOld: '5000000', Images: '/HoaChucMung/11570_no-luc-tien-buoc.png' },
        { Name: 'Congratulations 5', Price: '2700000', Category: 'hoa-chuc-mung', PriceOld: '', Images: '/HoaChucMung/13321_congratulations.png' },
        { Name: 'My dear', Price: '900000', Category: 'hoa-chuc-mung', PriceOld: '', Images: '/HopHoaTuoi/8369_my-dear.jpg' },
        { Name: 'Khởi đầu thuận lợi', Price: '4000000', Category: 'hoa-chuc-mung', PriceOld: '', Images: '/HoaChucMung/11571_khoi-dau-thuan-loi.png' },
        { Name: 'Hồng Phát', Price: '1200000', Category: 'hoa-chuc-mung', PriceOld: '', Images: '/HoaChucMung/9448_hong-phat.png' },
        { Name: 'Thành Đạt', Price: '1200000', Category: 'hoa-chuc-mung', PriceOld: '', Images: '/HoaChucMung/5263_thanh-dat.jpg' },
        { Name: 'Khởi sắc thành công', Price: '4500000', Category: 'hoa-chuc-mung', PriceOld: '4950000', Images: '/HoaChucMung/11033_khoi-sac-thanh-cong.png' },
        { Name: 'Vạn sự tốt đẹp', Price: '4600000', Category: 'hoa-chuc-mung', PriceOld: '5060000', Images: '/HoaChucMung/11573_van-su-tot-dep.jpg' },
        { Name: 'Đôi ta', Price: '500000', Category: 'hoa-chuc-mung', PriceOld: '', Images: '/GioHoaTuoi/11611_doi-ta.jpg' },
        { Name: 'Vạn Đạt', Price: '1500000', Category: 'hoa-chuc-mung', PriceOld: '5060000', Images: '/HoaChucMung/6905_van-dat.png' },
        { Name: 'Red box', Price: '1600000', Category: 'hoa-chuc-mung', PriceOld: '1875000', Images: '/HopHoaTuoi/8289_red-box.png' },
        { Name: 'Hoa tặng sự kiện 3(+20 bó)', Price: '1600000', Category: 'hoa-chuc-mung', PriceOld: '1875000', Images: '/HoaChucMung/10899_hoa-tang-su-kien--bo.jpg' },
        { Name: 'La vie en rose', Price: '1100000', Category: 'hoa-chuc-mung', PriceOld: '', Images: '/HopHoaTuoi/10686_la-vie-en-rose.png' },
        { Name: 'Good Luck 2', Price: '850000', Category: 'hoa-chuc-mung', PriceOld: '', Images: '/HoaChucMung/3966_good-luck.jpg' },
        { Name: 'Phát lộc', Price: '1300000', Category: 'hoa-chuc-mung', PriceOld: '', Images: '/HoaChucMung/4369_phat-loc.jpg' },
        { Name: 'Touch my heart', Price: '1300000', Category: 'hoa-chuc-mung', PriceOld: '1600000', Images: '/HopHoaTuoi/8041_touch-my-heart.jpg' },
        { Name: 'Thành công 4', Price: '2500000', Category: 'hoa-chuc-mung', PriceOld: '', Images: '/HoaChucMung/3856_thanh-cong.jpg' },
        { Name: 'Lộc Phát', Price: '1800000', Category: 'hoa-chuc-mung', PriceOld: '', Images: '/HoaChucMung/4940_loc-phat.jpg' },
        { Name: 'Be All You Can Be', Price: '2300000', Category: 'hoa-chuc-mung', PriceOld: '', Images: '/HoaChucMung/2994_be-all-you-can-be.jpg' },
        { Name: 'Sự Nghiệp Tấn Tới', Price: '4500000', Category: 'hoa-chuc-mung', PriceOld: '', Images: '/HoaChucMung/5303_su-nghiep-tan-toi.jpg' },
        { Name: 'Vạn Sự Hanh Thông 3', Price: '2500000', Category: 'hoa-chuc-mung', PriceOld: '2700000', Images: '/HoaChucMung/2091_van-su-hanh-thong.jpg' },
        { Name: 'Ngày yêu', Price: '750000', Category: 'hoa-chuc-mung', PriceOld: '800000', Images: '/GioHoaTuoi/8829_ngay-yeu.png' },
        // 
        //BÓ HOA//
        { Name: 'Everyday with you', Category: 'bo-hoa', Price: '200000', PriceOld: '250000', Images: '/BoHoa/13111_everyday-with-you.jpg' },
        { Name: 'Dating', Category: 'bo-hoa', Price: '250000', PriceOld: '', Images: '/BoHoa/14270_dating.jpg' },
        { Name: 'Nồng nàn', Category: 'bo-hoa', Price: '300000', PriceOld: '400000', Images: '/BoHoa/14470_nong-nan.jpg' },
        { Name: 'Điều thân thương', Category: 'bo-hoa', Price: '150000', PriceOld: '', Images: '/BoHoa/13234_dieu-than-thuong.jpg' },
        { Name: 'Say you do', Category: 'bo-hoa', Price: '300000', PriceOld: '400000', Images: '/BoHoa/14339_say-you-do.jpg' },
        { Name: 'Tana baby 2', Category: 'bo-hoa', Price: '350000', PriceOld: '', Images: '/BoHoa/13291_my-tana.jpg' },
        { Name: 'Bó Hoa Tú Cầu', Category: 'bo-hoa', Price: '400000', PriceOld: '440000', Images: '/BoHoa/6041_bo-hoa-tu-cau.jpg' },
        { Name: 'Đắm say 2', Category: 'bo-hoa', Price: '250000', PriceOld: '', Images: '/BoHoa/14432_dam-say.jpg' },
        { Name: 'Adore', Category: 'bo-hoa', Price: '400000', PriceOld: '', Images: '/BoHoa/14645_adore.jpg' },
        { Name: 'The greatest thing', Category: 'bo-hoa', Price: '500000', PriceOld: '350000', Images: '/BoHoa/13293_the-greatest-thing.jpg' },
        { Name: 'Mừng thành công', Category: 'bo-hoa', Price: '300000', PriceOld: '', Images: '/BoHoa/14474_mung-thanh-cong.jpg' },
        { Name: 'Eternal Bloom', Category: 'bo-hoa', Price: '500000', PriceOld: '', Images: '/BoHoa/14649_eternal-bloom.jpg' },
        { Name: 'My Tana', Category: 'bo-hoa', Price: '600000', PriceOld: '', Images: '/BoHoa/13291_my-tana.jpg' },
        { Name: 'Proud of you', Category: 'bo-hoa', Price: '700000', PriceOld: '850000', Images: '/BoHoa/13246_proud-of-you.jpg' },
        { Name: 'Blink', Category: 'bo-hoa', Price: '400000', PriceOld: '', Images: '/BoHoa/14356_blink.jpg' },
        { Name: 'Thuần khiết', Category: 'bo-hoa', Price: '550000', PriceOld: '650000', Images: '/BoHoa/14689_thuan-khiet.jpg' },
        { Name: 'How much I love you', Category: 'bo-hoa', Price: '850000', PriceOld: '', Images: '/BoHoa/13112_how-much-i-love-you.jpg' },
        { Name: 'Tiamo', Category: 'bo-hoa', Price: '900000', PriceOld: '', Images: '/BoHoa/13112_how-much-i-love-you.jpg' },
        { Name: 'Only rose 15', Category: 'bo-hoa', Price: '550000', PriceOld: '750000', Images: '/BoHoa/8382_only-rose.jpg' },
        { Name: 'Red elegance', Category: 'bo-hoa', Price: '850000', PriceOld: '900000', Images: '/BoHoa/13898_red-elegance.jpg' },
        { Name: 'Yêu kiều', Category: 'bo-hoa', Price: '1100000', PriceOld: '1200000', Images: '/BoHoa/12624_yeu-kieu.jpg' },
        { Name: 'Mắt biếc', Category: 'bo-hoa', Price: '1200000', PriceOld: '', Images: '/BoHoa/12492_mat-biec.jpg' },
        { Name: 'Darling Blooms', Category: 'bo-hoa', Price: '400000', PriceOld: '', Images: '/BoHoa/14651_darling-blooms.jpg' },
        { Name: 'Cơn gió lạ', Category: 'bo-hoa', Price: '1000000', PriceOld: '1100000', Images: '/BoHoa/12525_con-gio-la.jpg' },
        { Name: 'Ngày có nắng', Category: 'bo-hoa', Price: '1500000', PriceOld: '1650000', Images: '/BoHoa/14434_ngay-co-nang.jpg' },
        { Name: 'My universe', Category: 'bo-hoa', Price: '2000000', PriceOld: '2250000', Images: '/BoHoa/13899_my-universe.jpg' },
        { Name: 'Bầu trời trong xanh', Category: 'bo-hoa', Price: '1500000', PriceOld: '', Images: '/BoHoa/14435_bau-troi-trong-xanh.jpg' },
        { Name: 'Hạnh phúc ngọt ngào', Category: 'bo-hoa', Price: '1500000', PriceOld: '1600000', Images: '/BoHoa/3205_hanh-phuc-ngot-ngao.jpg' },
        { Name: 'Pink Lady', Category: 'bo-hoa', Price: '2400000', PriceOld: '', Images: '/BoHoa/7240_pink-lady.png' },
        { Name: 'A Thousand roses', Category: 'bo-hoa', Price: '25000000', PriceOld: '28000000', Images: '/BoHoa/12994_a-thousand-roses.jpg' },
        { Name: 'Morning kiss', Category: 'bo-hoa', Price: '1500000', PriceOld: '', Images: '/BoHoa/14427_morning-kiss.jpg' },
        { Name: 'Tình cờ', Category: 'bo-hoa', Price: '150000', PriceOld: '1650000', Images: '/BoHoa/13252_tinh-co.jpg' },
        { Name: 'Yêu xa', Category: 'bo-hoa', Price: '200000', PriceOld: '250000', Images: '/BoHoa/3199_yeu-xa.jpg' },
        { Name: 'Mơ mộng', Category: 'bo-hoa', Price: '350000', PriceOld: '', Images: '/BoHoa/14501_mo-mong.jpg' },
        { Name: 'Yêu thương đong đầy', Category: 'bo-hoa', Price: '300000', PriceOld: '', Images: '/BoHoa/13260_yeu-thuong-dong-day.jpg' },
        { Name: 'Gặp gỡ', Category: 'bo-hoa', Price: '300000', PriceOld: '', Images: '/BoHoa/5598_gap-go.jpg' },
        { Name: 'New energy', Category: 'bo-hoa', Price: '300000', PriceOld: '350000', Images: '/BoHoa/12583_new-energy.jpg' },
        { Name: 'Người thương', Category: 'bo-hoa', Price: '350000', PriceOld: '400000', Images: '/BoHoa/13372_nguoi-thuong.jpg' },
        { Name: 'Pretty Clouds', Category: 'bo-hoa', Price: '450000', PriceOld: '', Images: '/BoHoa/13036_pretty-clouds.jpg' },
        { Name: 'Ngọt ngào như hoa', Category: 'bo-hoa', Price: '350000', PriceOld: '450000', Images: '/BoHoa/14338_ngot-ngao-nhu-hoa.jpg' },
        { Name: 'Remember you', Category: 'bo-hoa', Price: '500000', PriceOld: '', Images: '/BoHoa/14271_remember-you.jpg' },
        { Name: 'Cầu hôn', Category: 'bo-hoa', Price: '650000', PriceOld: '750000', Images: '/BoHoa/13251_cau-hon.jpg' },
        { Name: 'Sắc cam rực rỡ', Category: 'bo-hoa', Price: '300000', PriceOld: '', Images: '/BoHoa/11041_sac-cam-ruc-ro.jpg' },
        { Name: 'Yêu em', Category: 'bo-hoa', Price: '500000', PriceOld: '', Images: '/BoHoa/8381_yeu-em.jpg' },
        { Name: 'Mong chờ', Category: 'bo-hoa', Price: '300000', PriceOld: '350000', Images: '/BoHoa/9445_mong-cho.jpg' },
        { Name: 'Nồng say', Category: 'bo-hoa', Price: '950000', PriceOld: '1100000', Images: '/BoHoa/12588_nong-say.jpg' },
        { Name: 'Rạng ngời 2', Category: 'bo-hoa', Price: '450000', PriceOld: '', Images: '/BoHoa/13083_rang-ngoi.jpg' },
        { Name: 'Only rose premium 1', Category: 'bo-hoa', Price: '650000', PriceOld: '850000', Images: '/BoHoa/13254_only-rose-premium.jpg' },
        { Name: 'Lady Queen', Category: 'bo-hoa', Price: '1000000', PriceOld: '', Images: '/BoHoa/13035_lady-queen.jpg' },
        { Name: 'Vẫn mãi yêu', Category: 'bo-hoa', Price: '1000000', PriceOld: '', Images: '/BoHoa/8384_van-mai-yeu.jpg' },
        { Name: 'Hẹn hò', Category: 'bo-hoa', Price: '750000', PriceOld: '', Images: '/BoHoa/11589_hen-ho.jpg' },
        { Name: 'Nắng ban mai', Category: 'bo-hoa', Price: '1500000', PriceOld: '1600000', Images: '/BoHoa/14353_nang-ban-mai.jpg' },
        { Name: 'Tình Yêu Vĩnh Cửu 2', Category: 'bo-hoa', Price: '2500000', PriceOld: '2700000', Images: '/BoHoa/6020_tinh-yeu-vinh-cuu.jpg' },
        { Name: 'Only rose 3', Category: 'bo-hoa', Price: '550000', PriceOld: '750000', Images: '/BoHoa/12985_only-rose.jpg' },
        { Name: 'Pink baby', Category: 'bo-hoa', Price: '1200000', PriceOld: '1350000', Images: '/BoHoa/10693_pink-baby.png' },
        { Name: 'Bó hoa cẩm chướng đỏ', Category: 'bo-hoa', Price: '500000', PriceOld: '', Images: '/BoHoa/8756_bo-hoa-cam-chuong-do.jpg' },
        { Name: 'Trọn đời có nhau', Category: 'bo-hoa', Price: '1350000', PriceOld: '1400000', Images: '/BoHoa/13383_tron-doi-co-nhau.jpg' },
        { Name: 'Romantic Date', Category: 'bo-hoa', Price: '200000', PriceOld: '', Images: '/BoHoa/11585_romantic-date.jpg' },
        { Name: 'Take My Hand', Category: 'bo-hoa', Price: '150000', PriceOld: '', Images: '/BoHoa/9440_take-my-hand.jpg' },
        { Name: 'Babys Breath 2', Category: 'bo-hoa', Price: '500000', PriceOld: '', Images: '/BoHoa/4075_babys-breath.jpg' },
        { Name: 'Trong ánh nắng', Category: 'bo-hoa', Price: '300000', PriceOld: '', Images: '/BoHoa/14433_trong-anh-nang.jpg' },
        { Name: 'Tana Baby', Category: 'bo-hoa', Price: '550000', PriceOld: '', Images: '/BoHoa/11868_tana-baby.jpg' },
        { Name: 'Romance 3', Category: 'bo-hoa', Price: '800000', PriceOld: '850000', Images: '/BoHoa/12490_romance.jpg' },
        { Name: 'Hẹn em', Category: 'bo-hoa', Price: '650000', PriceOld: '', Images: '/BoHoa/13257_hen-em.jpg' },
        { Name: 'Sasa roses', Category: 'bo-hoa', Price: '950000', PriceOld: '1400000', Images: '/BoHoa/8284_sasa-roses.png' },
        { Name: 'Trăm năm tình yêu', Category: 'bo-hoa', Price: '2800000', PriceOld: '', Images: '/BoHoa/13278_tram-nam-tinh-yeu.jpg' },
        { Name: 'Ấm áp', Category: 'bo-hoa', Price: '250000', PriceOld: '', Images: '/BoHoa/9443_am-ap.jpg' },
        { Name: 'Rung động', Category: 'bo-hoa', Price: '850000', PriceOld: '', Images: '/BoHoa/9540_rung-dong.png' },
        { Name: 'Freshness', Category: 'bo-hoa', Price: '800000', PriceOld: '', Images: '/BoHoa/10261_freshness.png' },
        { Name: 'Think about you', Category: 'bo-hoa', Price: '280000', PriceOld: '350000', Images: '/BoHoa/13085_think-about-you.jpg' },
        { Name: 'Điệu valse mùa xuân', Category: 'bo-hoa', Price: '550000', PriceOld: '', Images: '/BoHoa/13231_dieu-valse-mua-xuan.jpg' },
        { Name: 'Closer', Category: 'bo-hoa', Price: '650000', PriceOld: '', Images: '/BoHoa/8032_closer.png' },
        { Name: 'Tỏa sáng 2', Category: 'bo-hoa', Price: '550000', PriceOld: '600000', Images: '/BoHoa/13286_toa-sang.jpg' },
        { Name: 'Sự Quan Tâm 2', Category: 'bo-hoa', Price: '700000', PriceOld: '750000', Images: '/BoHoa/6032_su-quan-tam.png' },
        { Name: 'All of love', Category: 'bo-hoa', Price: '550000', PriceOld: '875000', Images: '/BoHoa/8524_all-of-love.jpg' },
        { Name: 'Điều bất ngờ', Category: 'bo-hoa', Price: '300000', PriceOld: '350000', Images: '/BoHoa/11740_dieu-bat-ngo.png' },
        { Name: 'Niềm vui nhỏ 2', Category: 'bo-hoa', Price: '550000', PriceOld: '', Images: '/BoHoa/12600_niem-vui-nho.jpg' },
        { Name: 'Me before you', Category: 'bo-hoa', Price: '550000', PriceOld: '750000', Images: '/BoHoa/4618_me-before-you.jpg' },
        { Name: 'Ngày mới ngọt ngào', Category: 'bo-hoa', Price: '550000', PriceOld: '', Images: '/BoHoa/13282_ngay-moi-ngot-ngao.jpg' },
        { Name: 'Khát Vọng', Category: 'bo-hoa', Price: '800000', PriceOld: '1000000', Images: '/BoHoa/2427_khat-vong.jpg' },
        { Name: 'Ohara', Category: 'bo-hoa', Price: '950000', PriceOld: '1045000', Images: '/BoHoa/11040_ohara.jpg' },
        { Name: 'Real Love', Category: 'bo-hoa', Price: '1000000', PriceOld: '1500000', Images: '/BoHoa/5045_real-love.png' },
        { Name: 'Only rose 8', Category: 'bo-hoa', Price: '1000000', PriceOld: '1250000', Images: '/BoHoa/8383_only-rose.jpg' },
        { Name: 'Remember', Category: 'bo-hoa', Price: '680000', PriceOld: '', Images: '/BoHoa/8116_remember.png' },
        { Name: 'Món quà hạnh phúc', Category: 'bo-hoa', Price: '570000', PriceOld: '', Images: '/BoHoa/13906_mon-qua-hanh-phuc.jpg' },
        { Name: 'Million Stars', Category: 'bo-hoa', Price: '570000', PriceOld: '', Images: '/BoHoa/7191_million-stars.png' },
        { Name: 'Sự Quan Tâm', Category: 'bo-hoa', Price: '700000', PriceOld: '750000', Images: '/BoHoa/5423_su-quan-tam.png' },
        { Name: 'Tình', Category: 'bo-hoa', Price: '450000', PriceOld: '500000', Images: '/BoHoa/10704_tinh.jpg' },
        { Name: 'Tươi vui', Category: 'bo-hoa', Price: '500000', PriceOld: '', Images: '/BoHoa/11867_tuoi-vui.jpg' },
        { Name: 'Vui cười', Category: 'bo-hoa', Price: '200000', PriceOld: '', Images: '/BoHoa/13443_vui-cuoi.jpg' },
        { Name: 'Only rose 20', Category: 'bo-hoa', Price: '550000', PriceOld: '750000', Images: '/BoHoa/12982_only-rose.jpg' },
        { Name: 'Hoa may mắn', Category: 'bo-hoa', Price: '600000', PriceOld: '660000', Images: '/BoHoa/9442_hoa-may-man.jpg' },
        { Name: 'Ngày bên em', Category: 'bo-hoa', Price: '1000000', PriceOld: '', Images: '/BoHoa/12598_ngay-ben-em.jpg' },
        { Name: 'Bó hoa sao tím', Category: 'bo-hoa', Price: '500000', PriceOld: '', Images: '/BoHoa/12526_bo-hoa-sao-tim.jpg' },
        { Name: 'Simple', Category: 'bo-hoa', Price: '350000', PriceOld: '', Images: '/BoHoa/11443_simple.jpg' },
        { Name: 'Bó cúc tana', Category: 'bo-hoa', Price: '1000000', PriceOld: '', Images: '/BoHoa/12591_bo-cuc-tana.jpg' },
        { Name: 'Keep loving', Category: 'bo-hoa', Price: '1500000', PriceOld: '1600000', Images: '/BoHoa/14423_keep-loving.jpg' },
        { Name: 'Giản dị', Category: 'bo-hoa', Price: '300000', PriceOld: '350000', Images: '/BoHoa/9444_gian-di.jpg' },
        { Name: 'Only rose 12', Category: 'bo-hoa', Price: '1000000', PriceOld: '1250000', Images: '/BoHoa/8382_only-rose.jpg' },
        { Name: 'Hẹn hò 2', Category: 'bo-hoa', Price: '500000', PriceOld: '', Images: '/BoHoa/13284_hen-ho.jpg' },
        { Name: 'Only rose 14', Category: 'bo-hoa', Price: '1000000', PriceOld: '1250000', Images: '/BoHoa/12581_only-rose.jpg' },
        { Name: 'My chance', Category: 'bo-hoa', Price: '350000', PriceOld: '400000', Images: '/BoHoa/13381_my-chance.jpg' },
        { Name: 'Encounter', Category: 'bo-hoa', Price: '320000', PriceOld: '', Images: '/BoHoa/13374_encounter.jpg' },
        { Name: 'Sweet moment', Category: 'bo-hoa', Price: '500000', PriceOld: '', Images: '/BoHoa/13250_sweet-moment.jpg' },
        { Name: 'Rạng ngời', Category: 'bo-hoa', Price: '350000', PriceOld: '400000', Images: '/BoHoa/12584_rang-ngoi.jpg' },
        { Name: 'Only rose 19', Category: 'bo-hoa', Price: '550000', PriceOld: '750000', Images: '/BoHoa/12582_only-rose.jpg' },
        { Name: 'Nắng sớm ngọt ngào', Category: 'bo-hoa', Price: '600000', PriceOld: '', Images: '/BoHoa/12590_nang-som-ngot-ngao.jpg' },
        { Name: 'Back to love', Category: 'bo-hoa', Price: '300000', PriceOld: '400000', Images: '/BoHoa/12596_back-to-love.jpg' },
        { Name: 'My sky', Category: 'bo-hoa', Price: '700000', PriceOld: '1250000', Images: '/BoHoa/12592_my-sky.jpg' },
        { Name: 'Make me smile', Category: 'bo-hoa', Price: '750000', PriceOld: '1000000', Images: '/BoHoa/13255_make-me-smile.jpg' },
        { Name: 'Ngày có em', Category: 'bo-hoa', Price: '1000000', PriceOld: '1100000', Images: '/BoHoa/13233_ngay-co-em.jpg' },
        { Name: 'Only rose 22', Category: 'bo-hoa', Price: '550000', PriceOld: '750000', Images: '/BoHoa/12984_only-rose.jpg' },
        { Name: 'Only You', Category: 'bo-hoa', Price: '250000', PriceOld: '280000', Images: '/BoHoa/4840_only-you.jpg' },
        { Name: 'Kem hoa', Category: 'bo-hoa', Price: '280000', PriceOld: '', Images: '/BoHoa/11739_kem-hoa.png' },
        { Name: 'Sương mai', Category: 'bo-hoa', Price: '1100000', PriceOld: '1200000', Images: '/BoHoa/9372_suong-mai.jpg' },
        { Name: 'Keep you in my heart', Category: 'bo-hoa', Price: '2800000', PriceOld: '', Images: '/BoHoa/13388_keep-you-in-my-heart.jpg' },
        { Name: 'Hoa yêu thương', Category: 'bo-hoa', Price: '500000', PriceOld: '', Images: '/BoHoa/13110_hoa-yeu-thuong.jpg' },
        { Name: 'Candy roses', Category: 'bo-hoa', Price: '600000', PriceOld: '750000', Images: '/BoHoa/8287_candy-roses.jpg' },
        { Name: 'Vì em là nắng', Category: 'bo-hoa', Price: '1250000', PriceOld: '1300000', Images: '/BoHoa/11728_vi-em-la-nang.jpg' },
        { Name: 'Only rose premium 3', Category: 'bo-hoa', Price: '650000', PriceOld: '850000', Images: '/BoHoa/13277_only-rose-premium.jpg' },
        { Name: 'Thạch thảo', Category: 'bo-hoa', Price: '300000', PriceOld: '', Images: '/BoHoa/3573_thach-thao.jpg' },
        { Name: 'Dancing Lady Orchid', Category: 'bo-hoa', Price: '1000000', PriceOld: '', Images: '/BoHoa/13038_dancing-lady-orchid.jpg' },
        { Name: 'Congrats', Category: 'bo-hoa', Price: '500000', PriceOld: '', Images: '/BoHoa/13237_congrats.jpg' },
        { Name: 'Bó hồng shimmer', Category: 'bo-hoa', Price: '500000', PriceOld: '', Images: '/BoHoa/14355_bo-hong-shimmer.jpg' },
        { Name: 'Only rose premium 4', Category: 'bo-hoa', Price: '650000', PriceOld: '850000', Images: '/BoHoa/13279_only-rose-premium.jpg' },
        { Name: 'Tana mộc mạc', Category: 'bo-hoa', Price: '550000', PriceOld: '570000', Images: '/BoHoa/8425_tana-moc-mac.png' },
        { Name: 'My Girl', Category: 'bo-hoa', Price: '550000', PriceOld: '600000', Images: '/BoHoa/12666_my-girl.jpg' },
        { Name: 'My muse', Category: 'bo-hoa', Price: '500000', PriceOld: '550000', Images: '/BoHoa/12983_my-muse.jpg' },
        { Name: 'Món quà tặng em', Category: 'bo-hoa', Price: '500000', PriceOld: '550000', Images: '/BoHoa/12523_mon-qua-tang-em.jpg' },
        { Name: 'My Babe', Category: 'bo-hoa', Price: '550000', PriceOld: '', Images: '/BoHoa/13292_my-babe.jpg' },
        { Name: 'Vì yêu là nhớ', Category: 'bo-hoa', Price: '2800000', PriceOld: '3000000', Images: '/BoHoa/13384_vi-yeu-la-nho.jpg' },
        { Name: 'Niềm tin 2', Category: 'bo-hoa', Price: '350000', PriceOld: '', Images: '/BoHoa/12589_niem-tin.jpg' },
        { Name: 'Sáng tươi', Category: 'bo-hoa', Price: '550000', PriceOld: '', Images: '/BoHoa/13438_sang-tuoi.jpg' },
        { Name: 'Thổn thức', Category: 'bo-hoa', Price: '650000', PriceOld: '', Images: '/BoHoa/13238_thon-thuc.jpg' },
        { Name: 'Shyly', Category: 'bo-hoa', Price: '750000', PriceOld: '', Images: '/BoHoa/13236_shyly.jpg' },
        { Name: 'Tango', Category: 'bo-hoa', Price: '600000', PriceOld: '', Images: '/BoHoa/13245_tango.jpg' },
        { Name: 'Hẹn ước', Category: 'bo-hoa', Price: '500000', PriceOld: '550000', Images: '/BoHoa/13139_hen-uoc.jpg' },
        { Name: 'Only rose premium 6', Category: 'bo-hoa', Price: '650000', PriceOld: '850000', Images: '/BoHoa/13281_only-rose-premium.jpg' },
        { Name: 'Ngày êm đềm', Category: 'bo-hoa', Price: '500000', PriceOld: '550000', Images: '/BoHoa/13382_ngay-em-dem.jpg' },
        { Name: 'Tươi tắn', Category: 'bo-hoa', Price: '250000', PriceOld: '', Images: '/BoHoa/12587_tuoi-tan.jpg' },
        { Name: 'Yêu thương 2', Category: 'bo-hoa', Price: '1000000', PriceOld: '1050000', Images: '/BoHoa/2450_yeu-thuong.jpg' },
        { Name: 'Only rose 27', Category: 'bo-hoa', Price: '1000000', PriceOld: '1750000', Images: '/BoHoa/12992_only-rose.jpg' },
        { Name: 'Beautiful you', Category: 'bo-hoa', Price: '1200000', PriceOld: '1475000', Images: '/BoHoa/11442_beautiful-you.jpg' },
        { Name: 'Sweetie', Category: 'bo-hoa', Price: '350000', PriceOld: '', Images: '/BoHoa/11444_sweetie.jpg' },
        { Name: 'Chuyện yêu', Category: 'bo-hoa', Price: '600000', PriceOld: '750000', Images: '/BoHoa/13376_chuyen-yeu.jpg' },
        { Name: 'Mùa hồng', Category: 'bo-hoa', Price: '1000000', PriceOld: '', Images: '/BoHoa/6830_mua-hong.png' },
        { Name: 'Only rose premium 2', Category: 'bo-hoa', Price: '650000', PriceOld: '1750000', Images: '/BoHoa/13276_only-rose-premium.jpg' },
        { Name: 'Destiny date', Category: 'bo-hoa', Price: '2800000', PriceOld: '3500000', Images: '/BoHoa/13375_destiny-date.jpg' },
        { Name: 'Thẹn thùng', Category: 'bo-hoa', Price: '650000', PriceOld: '700000', Images: '/BoHoa/13232_then-thung.jpg' },
        { Name: 'Dear My Love', Category: 'bo-hoa', Price: '450000', PriceOld: '500000', Images: '/BoHoa/12665_dear-my-love.jpg' },
        { Name: 'Hoa sen', Category: 'bo-hoa', Price: '500000', PriceOld: '', Images: '/BoHoa/10959_hoa-sen.png' },
        { Name: 'My Queen', Category: 'bo-hoa', Price: '600000', PriceOld: '650000', Images: '/BoHoa/11588_my-queen.png' },
        { Name: 'My life', Category: 'bo-hoa', Price: '360000', PriceOld: '', Images: '/BoHoa/13447_my-life.png' },
        { Name: 'Telling you', Category: 'bo-hoa', Price: '400000', PriceOld: '440000', Images: '/BoHoa/13248_telling-you.jpg' },
        { Name: 'Vui tươi', Category: 'bo-hoa', Price: '300000', PriceOld: '', Images: '/BoHoa/13445_vui-tuoi.jpg' },
        { Name: 'Một sớm mai', Category: 'bo-hoa', Price: '280000', PriceOld: '', Images: '/BoHoa/13444_mot-som-mai.jpg' },
        { Name: 'Only rose 11', Category: 'bo-hoa', Price: '1000000', PriceOld: '1250000', Images: '/BoHoa/8386_only-rose.jpg' },
        { Name: 'Tình hồng', Category: 'bo-hoa', Price: '300000', PriceOld: '', Images: '/BoHoa/13442_tinh-hong.jpg' },
        { Name: 'Mây hồng', Category: 'bo-hoa', Price: '450000', PriceOld: '', Images: '/BoHoa/9390_may-hong.jpg' },
        { Name: 'Yêu Xa 2', Category: 'bo-hoa', Price: '500000', PriceOld: '550000', Images: '/BoHoa/5256_yeu-xa.png' },
        { Name: 'Rustic', Category: 'bo-hoa', Price: '390000', PriceOld: '400000', Images: '/BoHoa/8424_rustic.png' },
        { Name: 'Bình minh', Category: 'bo-hoa', Price: '250000', PriceOld: '', Images: '/BoHoa/13437_binh-minh.jpg' },
        { Name: 'Điều ước', Category: 'bo-hoa', Price: '360000', PriceOld: '', Images: '/BoHoa/13436_dieu-uoc.jpg' },
        { Name: 'Áng mây hồng', Category: 'bo-hoa', Price: '750000', PriceOld: '1000000', Images: '/BoHoa/13256_ang-may-hong.jpg' },
        { Name: 'Tỏa Nắng', Category: 'bo-hoa', Price: '300000', PriceOld: '400000', Images: '/BoHoa/2507_toa-nang.jpg' },
        { Name: 'Sớm mai 2', Category: 'bo-hoa', Price: '500000', PriceOld: '550000', Images: '/BoHoa/13380_som-mai.jpg' },
        { Name: 'Only rose 6', Category: 'bo-hoa', Price: '550000', PriceOld: '750000', Images: '/BoHoa/8387_only-rose.jpg' },
        { Name: 'My everything', Category: 'bo-hoa', Price: '750000', PriceOld: '1000000', Images: '/BoHoa/4734_my-everything.jpg' },
        { Name: 'Dynamic', Category: 'bo-hoa', Price: '550000', PriceOld: '875000', Images: '/BoHoa/13082_dynamic.jpg' },
        { Name: 'Ánh Dương', Category: 'bo-hoa', Price: '150000', PriceOld: '165000', Images: '/BoHoa/2065_anh-duong.jpg' },
        { Name: 'Phút giây tỏa sáng', Category: 'bo-hoa', Price: '200000', PriceOld: '', Images: '/BoHoa/13253_phut-giay-toa-sang.jpg' },
        { Name: 'Say nắng', Category: 'bo-hoa', Price: '335000', PriceOld: '', Images: '/BoHoa/13446_say-nang.png' },
        { Name: 'Chan hòa', Category: 'bo-hoa', Price: '500000', PriceOld: '', Images: '/BoHoa/13907_chan-hoa.jpg' },
        { Name: 'Hạnh phúc mới', Category: 'bo-hoa', Price: '1000000', PriceOld: '', Images: '/BoHoa/12597_hanh-phuc-moi.jpg' },
        { Name: 'Chân trời mới', Category: 'bo-hoa', Price: '200000', PriceOld: '', Images: '/BoHoa/13261_chan-troi-moi.jpg' },
        { Name: 'For you 2', Category: 'bo-hoa', Price: '500000', PriceOld: '600000', Images: '/BoHoa/13972_for-you.jpg' },
        { Name: 'Bối rối', Category: 'bo-hoa', Price: '850000', PriceOld: '', Images: '/BoHoa/13230_boi-roi.jpg' },
        { Name: 'Purple Love', Category: 'bo-hoa', Price: '2800000', PriceOld: '3000000', Images: '/BoHoa/5384_purple-love.jpg' },
        { Name: 'Có em', Category: 'bo-hoa', Price: '300000', PriceOld: '330000', Images: '/BoHoa/13084_co-em.jpg' },
        { Name: 'White roses', Category: 'bo-hoa', Price: '350000', PriceOld: '450000', Images: '/BoHoa/10258_white-roses.jpg' },
        { Name: 'The greatest thing 2', Category: 'bo-hoa', Price: '1250000', PriceOld: '1350000', Images: '/BoHoa/13259_the-greatest-thing.jpg' },
        { Name: 'Điều dễ thương', Category: 'bo-hoa', Price: '400000', PriceOld: '', Images: '/BoHoa/13441_dieu-de-thuong.jpg' },
        { Name: 'Black pink', Category: 'bo-hoa', Price: '400000', PriceOld: '', Images: '/BoHoa/14386_black-pink.jpg' },
        { Name: 'Romantic heart', Category: 'bo-hoa', Price: '1500000', PriceOld: '1600000', Images: '/BoHoa/11593_romantic-heart.jpg' },
        { Name: 'Tỏa nắng 3', Category: 'bo-hoa', Price: '1000000', PriceOld: '', Images: '/BoHoa/14426_toa-nang.jpg' },
        { Name: 'Passionate Love', Category: 'bo-hoa', Price: '1500000', PriceOld: '1600000', Images: '/BoHoa/14424_passionate-love.jpg' },
        { Name: 'Sweet heart', Category: 'bo-hoa', Price: '1400000', PriceOld: '1500000', Images: '/BoHoa/13235_sweet-heart.jpg' },
        { Name: 'Dành cho em', Category: 'bo-hoa', Price: '1500000', PriceOld: '', Images: '/BoHoa/14422_danh-cho-em.jpg' },
        { Name: 'Bó cẩm chướng hồng', Category: 'bo-hoa', Price: '500000', PriceOld: '', Images: '/BoHoa/11586_bo-cam-chuong-hong.jpg' },
        { Name: 'Chỉ mình em', Category: 'bo-hoa', Price: '300000', PriceOld: '350000', Images: '/BoHoa/12585_chi-minh-em.jpg' },
        { Name: 'Nắng hồng 2', Category: 'bo-hoa', Price: '250000', PriceOld: '', Images: '/BoHoa/8283_nang-hong.jpg' },
        { Name: 'Babys breath', Category: 'bo-hoa', Price: '1500000', PriceOld: '', Images: '/BoHoa/1961_babys-breath.jpg' },
        { Name: 'Sapphire Sky', Category: 'bo-hoa', Price: '450000', PriceOld: '', Images: '/BoHoa/14465_sapphire-sky.jpg' },
        { Name: 'Tím hồng dịu dàng', Category: 'bo-hoa', Price: '450000', PriceOld: '', Images: '/BoHoa/14466_tim-hong-diu-dang.jpg' },
        { Name: 'Roseberry', Category: 'bo-hoa', Price: '300000', PriceOld: '330000', Images: '/BoHoa/14467_roseberry.jpg' },
        { Name: 'Tình đầu thơ ngây', Category: 'bo-hoa', Price: '250000', PriceOld: '275000', Images: '/BoHoa/13262_tinh-dau-tho-ngay.jpg' },
        { Name: 'Hương sắc mộng mơ', Category: 'bo-hoa', Price: '650000', PriceOld: '', Images: '/BoHoa/14469_huong-sac-mong-mo.jpg' },
        { Name: 'Violet blush', Category: 'bo-hoa', Price: '300000', PriceOld: '330000', Images: '/BoHoa/14468_violet-blush.jpg' },
        { Name: 'Chuyện của nắng', Category: 'bo-hoa', Price: '750000', PriceOld: '800000', Images: '/BoHoa/13258_chuyen-cua-nang.jpg' },
        { Name: 'Nắng Bình Minh', Category: 'bo-hoa', Price: '1000000', PriceOld: '1100000', Images: '/BoHoa/11592_nang-binh-minh.jpg' },
        { Name: 'My sun', Category: 'bo-hoa', Price: '150000', PriceOld: '800000', Images: '/BoHoa/13385_my-sun.jpg' },
        { Name: 'Congrats Blossom', Category: 'bo-hoa', Price: '600000', PriceOld: '', Images: '/BoHoa/14648_congrats-blossom.jpg' },
        { Name: 'Hoa sen đá 20', Category: 'bo-hoa', Price: '1000000', PriceOld: '', Images: '/BoHoa/14519_hoa-sen-da.jpg' },
        { Name: 'Cheers', Category: 'bo-hoa', Price: '700000', PriceOld: '', Images: '/BoHoa/14647_cheers.jpg' },
        { Name: 'Thời khắc rực rỡ', Category: 'bo-hoa', Price: '550000', PriceOld: '600000', Images: '/BoHoa/14646_thoi-khac-ruc-ro.jpg' },
        { Name: 'Baby flower', Category: 'bo-hoa', Price: '400000', PriceOld: '450000', Images: '/BoHoa/13294_baby-flower.jpg' },
        { Name: 'Lovely Morning', Category: 'bo-hoa', Price: '700000', PriceOld: '', Images: '/BoHoa/11439_lovely-morning.jpg' },
        { Name: 'Tuổi 18', Category: 'bo-hoa', Price: '550000', PriceOld: '650000', Images: '/BoHoa/13109_tuoi.jpg' },
        { Name: 'Surround You', Category: 'bo-hoa', Price: '500000', PriceOld: '550000', Images: '/BoHoa/12721_surround-you.jpg' },
        { Name: 'Ước mơ xanh', Category: 'bo-hoa', Price: '700000', PriceOld: '', Images: '/BoHoa/13247_uoc-mo-xanh.jpg' },
        //Xong
        //GIỎ HOA-HỘP HOA
        //
        { Name: 'Lux basket mini 5', Category: 'gio-hoa', Price: '300000', PriceOld: '350000', Images: '/GioHoaTuoi/14566_lux-basket-mini.jpg' },
        { Name: 'Rạng rỡ', Category: 'gio-hoa', Price: '350000', PriceOld: '400000', Images: '/GioHoaTuoi/14710_rang-ro.jpg' },
        { Name: 'Lux basket 4', Category: 'gio-hoa', Price: '400000', PriceOld: '', Images: '/GioHoaTuoi/14580_lux-basket.jpg' },
        { Name: 'Lan tỏa yêu thương', Category: 'gio-hoa', Price: '550000', PriceOld: '', Images: '/GioHoaTuoi/14843_lan-toa-yeu-thuong.jpg' },
        { Name: 'Sound of love', Category: 'gio-hoa', Price: '350000', PriceOld: '450000', Images: '/GioHoaTuoi/11610_sound-of-love.jpg' },
        { Name: 'Bất ngờ nhỏ', Category: 'gio-hoa', Price: '450000', PriceOld: '', Images: '/GioHoaTuoi/14842_bat-ngo-nho.jpg' },
        { Name: 'Chiều hoàng hôn', Category: 'gio-hoa', Price: '600000', PriceOld: '650000', Images: '/GioHoaTuoi/11609_chieu-hoang-hon.jpg' },
        { Name: 'Love is you', Category: 'gio-hoa', Price: '650000', PriceOld: '', Images: '/GioHoaTuoi/14399_love-is-you.jpg' },
        { Name: 'Tuổi xanh', Category: 'gio-hoa', Price: '550000', PriceOld: '', Images: '/GioHoaTuoi/13219_tuoi-xanh.jpg' },
        { Name: 'Nữ hoàng', Category: 'gio-hoa', Price: '600000', PriceOld: '700000', Images: '/GioHoaTuoi/13075_nu-hoang.jpg' },
        { Name: 'Capuchino love', Category: 'gio-hoa', Price: '600000', PriceOld: '', Images: '/GioHoaTuoi/14833_capuchino-love.jpg' },
        { Name: 'A little wish', Category: 'gio-hoa', Price: '700000', PriceOld: '', Images: '/GioHoaTuoi/8671_a-little-wish.jpg' },
        { Name: 'Cloud and you', Category: 'gio-hoa', Price: '800000', PriceOld: '900000', Images: '/GioHoaTuoi/13066_cloud-and-you.jpg' },
        { Name: 'Cutie bear and rose 14', Category: 'gio-hoa', Price: '750000', PriceOld: '', Images: '/GioHoaTuoi/14713_cutie-bear-and-rose.jpg' },
        { Name: 'You are beautiful', Category: 'gio-hoa', Price: '900000', PriceOld: '1000000', Images: '/GioHoaTuoi/10708_you-are-beautiful.jpg' },
        { Name: 'Ngọt ngào 3', Category: 'gio-hoa', Price: '1000000', PriceOld: '1200000', Images: '/GioHoaTuoi/12528_ngot-ngao.jpg' },
        { Name: 'Hoa sen đá 8', Category: 'gio-hoa', Price: '1000000', PriceOld: '1050000', Images: '/GioHoaTuoi/14390_hoa-sen-da.jpg' },
        { Name: 'The best thing you are', Category: 'gio-hoa', Price: '600000', PriceOld: '', Images: '/GioHoaTuoi/14834_the-best-thing-you-are.jpg' },
        { Name: 'Hoa sen đá 7', Category: 'gio-hoa', Price: '1000000', PriceOld: '1050000', Images: '/GioHoaTuoi/14389_hoa-sen-da.jpg' },
        { Name: 'Strong Spirit', Category: 'gio-hoa', Price: '1200000', PriceOld: '', Images: '/GioHoaTuoi/13268_strong-spirit.jpg' },
        { Name: 'Faithful Promise 2', Category: 'gio-hoa', Price: '1700000', PriceOld: '', Images: '/GioHoaTuoi/14340_faithful-promise.jpg' },
        { Name: 'Great day', Category: 'gio-hoa', Price: '1500000', PriceOld: '1600000', Images: '/GioHoaTuoi/8048_great-day.jpg' },
        { Name: 'Color Life 2', Category: 'gio-hoa', Price: '1800000', PriceOld: '2000000', Images: '/GioHoaTuoi/8047_color-life.jpg' },
        { Name: 'Dịu Êm', Category: 'gio-hoa', Price: '1800000', PriceOld: '1900000', Images: '/GioHoaTuoi/4933_diu-em.jpg' },
        { Name: 'Vươn xa 2', Category: 'gio-hoa', Price: '2000000', PriceOld: '2100000', Images: '/GioHoaTuoi/14425_vuon-xa.jpg' },
        { Name: 'Hoa sen đá 9', Category: 'gio-hoa', Price: '1500000', PriceOld: '1550000', Images: '/GioHoaTuoi/14391_hoa-sen-da.jpg' },
        { Name: 'Đồng xanh 2', Category: 'gio-hoa', Price: '1600000', PriceOld: '', Images: '/GioHoaTuoi/13829_dong-xanh.jpg' },
        { Name: 'Tháng năm rực rỡ', Category: 'gio-hoa', Price: '1800000', PriceOld: '2000000', Images: '/GioHoaTuoi/13063_thang-nam-ruc-ro.jpg' },
        { Name: 'Điều khác biệt', Category: 'gio-hoa', Price: '2200000', PriceOld: '', Images: '/GioHoaTuoi/14344_dieu-khac-biet.jpg' },
        { Name: 'Hồng Dịu Dàng', Category: 'gio-hoa', Price: '350000', PriceOld: '', Images: '/GioHoaTuoi/14455_hong-diu-dang.jpg' },
        { Name: 'Hoa sen đá 6', Category: 'gio-hoa', Price: '650000', PriceOld: '700000', Images: '/GioHoaTuoi/14387_hoa-sen-da.jpg' },
        { Name: 'Hương vị ngọt ngào', Category: 'gio-hoa', Price: '500000', PriceOld: '', Images: '/GioHoaTuoi/14835_huong-vi-ngot-ngao.jpg' },
        { Name: 'Feel good', Price: '550000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/14446_feel-good.jpg' },
        { Name: 'Sunshine Bliss', Price: '350000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/14454_sunshine-bliss.jpg' },
        { Name: 'Lux basket mini 1', Price: '600000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/14565_lux-basket-mini.jpg' },
        { Name: 'Lux basket 2', Price: '400000', Category: 'gio-hoa', PriceOld: '500000', Images: '/GioHoaTuoi/14567_lux-basket.jpg' },
        { Name: 'Vẹn tròn', Price: '1000000', Category: 'gio-hoa', PriceOld: '1050000', Images: '/GioHoaTuoi/13658_ven-tron.jpg' },
        { Name: 'Fantasy', Price: '1100000', Category: 'gio-hoa', PriceOld: '1200000', Images: '/GioHoaTuoi/13657_fantasy.jpg' },
        { Name: 'Hoa mặt trời', Price: '1000000', Category: 'gio-hoa', PriceOld: '1050000', Images: '/GioHoaTuoi/11602_hoa-mat-troi.jpg' },
        { Name: 'Pink Delight', Price: '350000', Category: 'gio-hoa', PriceOld: '400000', Images: '/GioHoaTuoi/14453_pink-delight.jpg' },
        { Name: 'A little love', Price: '800000', Category: 'gio-hoa', PriceOld: '850000', Images: '/GioHoaTuoi/13065_a-little-love.jpg' },
        { Name: 'Giỏ Hoa Hồng', Price: '900000', Category: 'gio-hoa', PriceOld: '950000', Images: '/GioHoaTuoi/5062_gio-hoa-hong.jpg' },
        { Name: 'A Thousand years', Price: '850000', Category: 'gio-hoa', PriceOld: '900000', Images: '/GioHoaTuoi/13074_a-thousand-years.jpg' },
        { Name: 'Passion', Price: '990000', Category: 'gio-hoa', PriceOld: '1100000', Images: '/GioHoaTuoi/8087_passion.jpg' },
        { Name: 'Rose Bliss', Price: '600000', Category: 'gio-hoa', PriceOld: '650000', Images: '/GioHoaTuoi/14452_rose-bliss.jpg' },
        { Name: 'Tình yêu đầu', Price: '650000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/8830_tinh-yeu-dau.jpg' },
        { Name: 'Thuận lợi', Price: '1500000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/12348_thuan-loi.jpg' },
        { Name: 'Động lực', Price: '600000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/13215_dong-luc.jpg' },
        { Name: 'Ngàn ánh dương', Price: '500000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/11607_ngan-anh-duong.png' },
        { Name: 'Charming Basket', Price: '1100000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/12478_charming-basket.jpg' },
        { Name: 'Missing you', Price: '990000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/8088_missing-you.jpg' },
        { Name: 'Thanh âm', Price: '800000', Category: 'gio-hoa', PriceOld: '985000', Images: '/GioHoaTuoi/10709_thanh-am.jpg' },
        { Name: 'Tinh khôi 2', Price: '900000', Category: 'gio-hoa', PriceOld: '950000', Images: '/GioHoaTuoi/12477_tinh-khoi.jpg' },
        { Name: 'Gió mùa hạ', Price: '500000', Category: 'gio-hoa', PriceOld: '650000', Images: '/GioHoaTuoi/13217_gio-mua-ha.jpg' },
        { Name: 'Gần bên em', Price: '550000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/8832_gan-ben-em.jpg' },
        { Name: 'Good luck 4', Price: '450000', Category: 'gio-hoa', PriceOld: '500000', Images: '/GioHoaTuoi/13300_good-luck.jpg' },
        { Name: 'Purple blooming', Price: '850000', Category: 'gio-hoa', PriceOld: '900000', Images: '/GioHoaTuoi/12347_purple-blooming.jpg' },
        { Name: 'Chiều thương', Price: '600000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/8831_chieu-thuong.jpg' },
        { Name: 'Color Life', Price: '850000', Category: 'gio-hoa', PriceOld: '900000', Images: '/GioHoaTuoi/13133_color-life.jpg' },
        { Name: 'My Treasure', Price: '850000', Category: 'gio-hoa', PriceOld: '900000', Images: '/GioHoaTuoi/13072_my-treasure.jpg' },
        { Name: 'Giỏ Hồng shimmer', Price: '1200000', Category: 'gio-hoa', PriceOld: '1250000', Images: '/GioHoaTuoi/13298_gio-hong-shimmer.jpg' },
        { Name: 'Purity', Price: '500000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/8447_purity.jpg' },
        { Name: 'Sunny hill', Price: '1300000', Category: 'gio-hoa', PriceOld: '1500000', Images: '/GioHoaTuoi/13216_sunny-hill.jpg' },
        { Name: 'Người Tôi Thương', Price: '1500000', Category: 'gio-hoa', PriceOld: '1600000', Images: '/GioHoaTuoi/5647_nguoi-toi-thuong.jpg' },
        { Name: 'Vì em', Price: '1100000', Category: 'gio-hoa', PriceOld: '1150000', Images: '/GioHoaTuoi/10688_vi-em.jpg' },
        { Name: 'Yêu đời', Price: '950000', Category: 'gio-hoa', PriceOld: '1000000', Images: '/GioHoaTuoi/13135_yeu-doi.jpg' },
        { Name: 'Sunrise', Price: '800000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/13297_sunrise.jpg' },
        { Name: 'Đóa hoa rực rỡ', Price: '1000000', Category: 'gio-hoa', PriceOld: '1050000', Images: '/GioHoaTuoi/12910_doa-hoa-ruc-ro.jpg' },
        { Name: 'Đôi ta', Price: '500000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/11611_doi-ta.jpg' },
        { Name: 'Mãi yêu', Price: '450000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/11612_mai-yeu.jpg' },
        { Name: 'Paradise', Price: '900000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/12345_paradise.jpg' },
        { Name: 'Cutie bear and rose 12', Price: '650000', Category: 'gio-hoa', PriceOld: '700000', Images: '/GioHoaTuoi/14711_cutie-bear-and-rose.jpg' },
        { Name: 'Hoa sen đá 10', Price: '1200000', Category: 'gio-hoa', PriceOld: '1250000', Images: '/GioHoaTuoi/14392_hoa-sen-da.jpg' },
        { Name: 'New Sun', Price: '550000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/13656_new-sun.jpg' },
        { Name: 'Giỏ cúc tana lớn', Price: '1200000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/14430_gio-cuc-tana-lon.jpg' },
        { Name: 'Ngọc sắc', Price: '850000', Category: 'gio-hoa', PriceOld: '900000', Images: '/GioHoaTuoi/10707_ngoc-sac.jpg' },
        { Name: 'Reply', Price: '700000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/14449_reply.jpg' },
        { Name: 'Gửi ngàn lời yêu', Price: '550000', Category: 'gio-hoa', PriceOld: '630000', Images: '/GioHoaTuoi/13116_gui-ngan-loi-yeu.jpg' },
        { Name: 'Only rose 30', Price: '1000000', Category: 'gio-hoa', PriceOld: '1250000', Images: '/GioHoaTuoi/12960_only-rose.jpg' },
        { Name: 'Giỏ cúc tana mini 2', Price: '500000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/14515_gio-cuc-tana-mini.jpg' },
        { Name: 'Cutie bear and rose 13', Price: '700000', Category: 'gio-hoa', PriceOld: '750000', Images: '/GioHoaTuoi/14712_cutie-bear-and-rose.jpg' },
        { Name: 'Giỏ cúc rossi hồng', Price: '600000', Category: 'gio-hoa', PriceOld: '700000', Images: '/GioHoaTuoi/14479_gio-cuc-rossi-hong.jpg' },
        { Name: 'Giỏ cúc rossi hoa hồng', Price: '750000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/14480_gio-cuc-rossi-hoa-hong.jpg' },
        { Name: 'Sweet', Price: '400000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/14447_sweet.jpg' },
        { Name: 'Happier', Price: '500000', Category: 'gio-hoa', PriceOld: '540000', Images: '/GioHoaTuoi/14504_happier.jpg' },
        { Name: 'Cheer up', Price: '700000', Category: 'gio-hoa', PriceOld: '750000', Images: '/GioHoaTuoi/13079_cheer-up.jpg' },
        { Name: 'Món quà bất ngờ', Price: '750000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/14516_mon-qua-bat-ngo.jpg' },
        { Name: 'Little wish', Price: '550000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/14517_little-wish.jpg' },
        { Name: 'Ước mong', Price: '500000', Category: 'gio-hoa', PriceOld: '540000', Images: '/GioHoaTuoi/14518_uoc-mong.jpg' },
        { Name: 'Sweet girl', Price: '650000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/14652_sweet-girl.jpg' },
        { Name: 'Perfect gift', Price: '700000', Category: 'gio-hoa', PriceOld: '750000', Images: '/GioHoaTuoi/14653_perfect-gift.jpg' },
        { Name: 'Điều tự nhiên', Price: '550000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/14654_dieu-tu-nhien.jpg' },
        { Name: 'Baby Anh Yêu Em', Price: '800000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/13218_baby-anh-yeu-em.jpg' },
        { Name: 'Vút cao', Price: '500000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/14503_vut-cao.jpg' },
        { Name: 'Mùa xuân của mẹ', Price: '800000', Category: 'gio-hoa', PriceOld: '850000', Images: '/GioHoaTuoi/5652_mua-xuan-cua-me.jpg' },
        { Name: 'Hẹn yêu', Price: '500000', Category: 'gio-hoa', PriceOld: '550000', Images: '/GioHoaTuoi/11608_hen-yeu.jpg' },
        { Name: 'Only rose 4', Price: '600000', Category: 'gio-hoa', PriceOld: '750000', Images: '/GioHoaTuoi/12959_only-rose.jpg' },
        { Name: 'Trong veo 2', Price: '450000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/14561_trong-veo.jpg' },
        { Name: 'Lucky', Price: '450000', Category: 'gio-hoa', PriceOld: '500000', Images: '/GioHoaTuoi/13299_lucky.jpg' },
        { Name: 'Tuổi hồng', Price: '450000', Category: 'gio-hoa', PriceOld: '500000', Images: '/GioHoaTuoi/13301_tuoi-hong.jpg' },
        { Name: 'Thanh khiết', Price: '350000', Category: 'gio-hoa', PriceOld: '450000', Images: '/GioHoaTuoi/14709_thanh-khiet.jpg' },
        { Name: 'Ngày yêu', Price: '750000', Category: 'gio-hoa', PriceOld: '800000', Images: '/GioHoaTuoi/8829_ngay-yeu.png' },
        { Name: 'Như ánh ban mai', Price: '1000000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/14343_nhu-anh-ban-mai.jpg' },
        { Name: 'Cutie bear and rose 9', Price: '650000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/14560_cutie-bear-and-rose.jpg' },
        { Name: 'Freedom', Price: '550000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/13592_freedom.jpg' },
        { Name: 'Cuộc sống tươi mới', Price: '550000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/14836_cuoc-song-tuoi-moi.jpg' },
        { Name: 'Ngày mộng mơ', Price: '500000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/13295_ngay-mong-mo.png' },
        { Name: 'Blushing Joy', Price: '500000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/14450_blushing-joy.jpg' },
        { Name: 'Giỏ cát tường xanh', Price: '600000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/14451_gio-cat-tuong-xanh.jpg' },
        { Name: 'Giỏ cúc tana mini', Price: '550000', Category: 'gio-hoa', PriceOld: '600000', Images: '/GioHoaTuoi/14431_gio-cuc-tana-mini.jpg' },
        { Name: 'Lux basket 3', Price: '450000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/14579_lux-basket.jpg' },
        { Name: 'Rainbow', Price: '350000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/14887_rainbow.jpg' },
        { Name: 'Chân ái', Price: '350000', Category: 'gio-hoa', PriceOld: '', Images: '/GioHoaTuoi/14886_chan-ai.jpg' },
        //Xong Giỏ hoa
        //Hộp hoa
        { Name: 'Phút Dấu Yêu', Price: '400000', Category: 'hop-hoa', PriceOld: '450000', Images: '/HopHoaTuoi/14672_phut-dau-yeu.jpg' },
        { Name: 'This love', Price: '500000', Category: 'hop-hoa', PriceOld: '600000', Images: '/HopHoaTuoi/13127_this-love.jpg' },
        { Name: 'Sắc hương', Price: '500000', Category: 'hop-hoa', PriceOld: '600000', Images: '/HopHoaTuoi/13129_sac-huong.jpg' },
        { Name: 'Bản tình ca mùa đông', Price: '500000', Category: 'hop-hoa', PriceOld: '600000', Images: '/HopHoaTuoi/8038_ban-tinh-ca-mua-dong.jpg' },
        { Name: 'Ao ước', Price: '400000', Category: 'hop-hoa', PriceOld: '450000', Images: '/HopHoaTuoi/11614_ao-uoc.jpg' },
        { Name: 'Mây ngàn', Price: '500000', Category: 'hop-hoa', PriceOld: '550000', Images: '/HopHoaTuoi/11613_may-ngan.jpg' },
        { Name: 'Trái tim mùa thu', Price: '500000', Category: 'hop-hoa', PriceOld: '550000', Images: '/HopHoaTuoi/13140_trai-tim-mua-thu.jpg' },
        { Name: 'Đa sắc', Price: '750000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14604_da-sac.jpg' },
        { Name: 'Sớm mai ngọt ngào', Price: '500000', Category: 'hop-hoa', PriceOld: '600000', Images: '/HopHoaTuoi/13161_som-mai-ngot-ngao.jpg' },
        { Name: 'Ngọc xanh', Price: '650000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14692_ngoc-xanh.jpg' },
        { Name: 'Người đặc biệt', Price: '900000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14606_nguoi-dac-biet.jpg' },
        { Name: 'Cutie bear and rose 11', Price: '1200000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14551_cutie-bear-and-rose.jpg' },
        { Name: 'Ước mơ hồng', Price: '650000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14600_uoc-mo-hong.jpg' },
        { Name: 'Thắp sáng', Price: '800000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14603_thap-sang.jpg' },
        { Name: 'Ngày tươi sáng', Price: '1000000', Category: 'hop-hoa', PriceOld: '1100000', Images: '/HopHoaTuoi/13180_ngay-tuoi-sang.jpg' },
        { Name: 'Hoa sen đá 4', Price: '1200000', Category: 'hop-hoa', PriceOld: '1250000', Images: '/HopHoaTuoi/14395_hoa-sen-da.jpg' },
        { Name: 'Che chở', Price: '700000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14844_che-cho.jpg' },
        { Name: 'Cutie bear and rose 3', Price: '900000', Category: 'hop-hoa', PriceOld: '1000000', Images: '/HopHoaTuoi/11583_cutie-bear-and-rose.jpg' },
        { Name: 'Trong lành', Price: '1100000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14500_trong-lanh.jpg' },
        { Name: 'Ngọt ngào sắc hoa', Price: '200000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14688_ngot-ngao-sac-hoa.jpg' },
        { Name: 'Ánh Sáng', Price: '850000', Category: 'hop-hoa', PriceOld: '950000', Images: '/HopHoaTuoi/13654_anh-sang.jpg' },
        { Name: 'Sức sống', Price: '1000000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14553_suc-song.jpg' },
        { Name: 'Niềm hi vọng', Price: '1500000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14677_niem-hi-vong.jpg' },
        { Name: 'Trái tim chân thành', Price: '1800000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14675_trai-tim-chan-thanh.jpg' },
        { Name: 'Morning of Garden', Price: '950000', Category: 'hop-hoa', PriceOld: '1000000', Images: '/HopHoaTuoi/10908_morning-of-garden.png' },
        { Name: 'Lối rẽ', Price: '1800000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14679_loi-re.jpg' },
        { Name: 'Hoa sen đá 3', Price: '2000000', Category: 'hop-hoa', PriceOld: '2050000', Images: '/HopHoaTuoi/14394_hoa-sen-da.jpg' },
        { Name: 'Love Heart', Price: '2300000', Category: 'hop-hoa', PriceOld: '2500000', Images: '/HopHoaTuoi/5128_love-heart.jpg' },
        { Name: 'Bùng cháy 4', Price: '1100000', Category: 'hop-hoa', PriceOld: '1150000', Images: '/HopHoaTuoi/13211_bung-chay.jpg' },
        { Name: 'Hội tụ', Price: '1800000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14686_hoi-tu.jpg' },
        { Name: 'Thiên thanh', Price: '2000000', Category: 'hop-hoa', PriceOld: '2200000', Images: '/HopHoaTuoi/14694_thien-thanh.jpg' },
        { Name: 'Tinh hoa', Price: '2300000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14691_tinh-hoa.jpg' },
        { Name: 'Hương mùa hè', Price: '500000', Category: 'hop-hoa', PriceOld: '550000', Images: '/HopHoaTuoi/13131_huong-mua-he.jpg' },
        { Name: 'Hương thầm', Price: '650000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/4514_huong-tham.jpg' },
        { Name: 'Ngày dịu dàng', Price: '750000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/3215_ngay-diu-dang.jpg' },
        { Name: 'Lightly 2', Price: '1500000', Category: 'hop-hoa', PriceOld: '1700000', Images: '/HopHoaTuoi/14683_lightly.jpg' },
        { Name: 'Lightly', Price: '750000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/13187_lightly.jpg' },
        { Name: 'Cutie bear and rose 8', Price: '900000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14190_cutie-bear-and-rose.jpg' },
        { Name: 'Ngày tươi sáng 2', Price: '1000000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/13194_ngay-tuoi-sang.jpg' },
        { Name: 'Gửi Người Tôi Yêu', Price: '850000', Category: 'hop-hoa', PriceOld: '1150000', Images: '/HopHoaTuoi/3197_gui-nguoi-toi-yeu.jpg' },
        { Name: 'Thank you', Price: '1000000', Category: 'hop-hoa', PriceOld: '1100000', Images: '/HopHoaTuoi/13210_thank-you.jpg' },
        { Name: 'Blooming', Price: '1250000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/11693_blooming.jpg' },
        { Name: 'Đỏ nhiệt huyết', Price: '1600000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14476_do-nhiet-huyet.jpg' },
        { Name: 'Amour', Price: '1000000', Category: 'hop-hoa', PriceOld: '1100000', Images: '/HopHoaTuoi/13222_amour.jpg' },
        { Name: 'Đồng hành', Price: '1500000', Category: 'hop-hoa', PriceOld: '1550000', Images: '/HopHoaTuoi/13192_dong-hanh.jpg' },
        { Name: 'Touch my heart', Price: '1300000', Category: 'hop-hoa', PriceOld: '1600000', Images: '/HopHoaTuoi/8041_touch-my-heart.jpg' },
        { Name: 'Trái tim yêu thương', Price: '1550000', Category: 'hop-hoa', PriceOld: '1800000', Images: '/HopHoaTuoi/11045_trai-tim-yeu-thuong.png' },
        { Name: 'Cutie bear and rose 7', Price: '1000000', Category: 'hop-hoa', PriceOld: '1100000', Images: '/HopHoaTuoi/14183_cutie-bear-and-rose.jpg' },
        { Name: 'Điều ngọt ngào 2', Price: '1000000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/13193_dieu-ngot-ngao.jpg' },
        { Name: 'Năng lượng tràn đầy', Price: '1500000', Category: 'hop-hoa', PriceOld: '1550000', Images: '/HopHoaTuoi/10906_nang-luong-tran-day.jpg' },
        { Name: 'Bright sky', Price: '850000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/8042_bright-sky.jpg' },
        { Name: 'Hoàng hôn ngọt ngào', Price: '850000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/10905_hoang-hon-ngot-ngao.png' },
        { Name: 'Home sweet home', Price: '880000', Category: 'hop-hoa', PriceOld: '1200000', Images: '/HopHoaTuoi/8367_home-sweet-home.jpg' },
        { Name: 'Hoa sen đá 1', Price: '1400000', Category: 'hop-hoa', PriceOld: '1450000', Images: '/HopHoaTuoi/10411_hoa-sen-da.jpg' },
        { Name: 'Niềm Tin', Price: '700000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/5201_niem-tin.jpg' },
        { Name: 'Tự tình', Price: '800000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/11584_tu-tinh.jpg' },
        { Name: 'Trọn Vẹn Bên Nhau', Price: '800000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/6026_tron-ven-ben-nhau.png' },
        { Name: 'Ấn tượng', Price: '800000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/13184_an-tuong.jpg' },
        { Name: 'Only rose 2', Price: '950000', Category: 'hop-hoa', PriceOld: '1000000', Images: '/HopHoaTuoi/12958_only-rose.jpg' },
        { Name: 'Sự sống', Price: '1500000', Category: 'hop-hoa', PriceOld: '1700000', Images: '/HopHoaTuoi/4695_su-song.jpg' },
        { Name: 'Love box 3', Price: '400000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/3699_love-box.jpg' },
        { Name: 'Em về tinh khôi', Price: '1100000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/13191_em-ve-tinh-khoi.jpg' },
        { Name: 'Trong xanh', Price: '1000000', Category: 'hop-hoa', PriceOld: '1150000', Images: '/HopHoaTuoi/13221_trong-xanh.jpg' },
        { Name: 'Jolie', Price: '1500000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/13242_jolie.jpg' },
        { Name: 'Mama  Love', Price: '850000', Category: 'hop-hoa', PriceOld: '900000', Images: '/HopHoaTuoi/4920_mama-love.jpg' },
        { Name: 'Feeling', Price: '1200000', Category: 'hop-hoa', PriceOld: '1300000', Images: '/HopHoaTuoi/10682_feeling.png' },
        { Name: 'Bản nhạc dịu êm', Price: '400000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/3339_ban-nhac-diu-em.jpg' },
        { Name: 'Nắng Sớm Mai', Price: '900000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/5282_nang-som-mai.jpg' },
        { Name: 'Pink Box', Price: '1000000', Category: 'hop-hoa', PriceOld: '1100000', Images: '/HopHoaTuoi/8290_pink-box.jpg' },
        { Name: 'Hương gió', Price: '500000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/8443_huong-gio.png' },
        { Name: 'Mộng mơ', Price: '600000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/4337_mong-mo.jpg' },
        { Name: 'Đong đầy', Price: '1100000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/13188_dong-day.jpg' },
        { Name: 'Một ngày nắng hồng', Price: '750000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14498_mot-ngay-nang-hong.jpg' },
        { Name: 'Hạnh phúc bất tận 2', Price: '1500000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/11044_hanh-phuc-bat-tan.png' },
        { Name: 'Beauty queen', Price: '1400000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/8025_beauty-queen.jpg' },
        { Name: 'La vie en rose', Price: '1100000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/10686_la-vie-en-rose.png' },
        { Name: 'Ngọt ngào 2', Price: '900000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/12529_ngot-ngao.jpg' },
        { Name: 'My dear', Price: '900000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/8369_my-dear.jpg' },
        { Name: 'Tình Yêu Màu Nắng', Price: '850000', Category: 'hop-hoa', PriceOld: '916000', Images: '/HopHoaTuoi/8819_tinh-yeu-mau-nang.jpg' },
        { Name: 'Tím yêu thương', Price: '500000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/3772_tim-yeu-thuong.jpg' },
        { Name: 'Sắc Hồng Xinh', Price: '950000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/5424_sac-hong-xinh.jpg' },
        { Name: 'Celebrate', Price: '750000', Category: 'hop-hoa', PriceOld: '850000', Images: '/HopHoaTuoi/8444_celebrate.jpg' },
        { Name: 'Future light', Price: '1100000', Category: 'hop-hoa', PriceOld: '1150000', Images: '/HopHoaTuoi/11576_future-light.jpg' },
        { Name: 'Ngọt ngào bên em', Price: '900000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/12601_ngot-ngao-ben-em.jpg' },
        { Name: 'Vươn xa', Price: '1000000', Category: 'hop-hoa', PriceOld: '1200000', Images: '/HopHoaTuoi/11582_vuon-xa.jpg' },
        { Name: 'Hoa sen đá 2', Price: '1800000', Category: 'hop-hoa', PriceOld: '1850000', Images: '/HopHoaTuoi/8022_hoa-sen-da.jpg' },
        { Name: 'Chào buổi sáng', Price: '950000', Category: 'hop-hoa', PriceOld: '1000000', Images: '/HopHoaTuoi/13651_chao-buoi-sang.jpg' },
        { Name: 'Moonlight sonate', Price: '1300000', Category: 'hop-hoa', PriceOld: '1400000', Images: '/HopHoaTuoi/12987_moonlight-sonate.jpg' },
        { Name: 'Tiền tài như nước', Price: '1500000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/8021_tien-tai-nhu-nuoc.jpg' },
        { Name: 'Strong belief', Price: '1200000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/13186_strong-belief.jpg' },
        { Name: 'Hoa sen đá 5', Price: '1400000', Category: 'hop-hoa', PriceOld: '1450000', Images: '/HopHoaTuoi/14396_hoa-sen-da.jpg' },
        { Name: 'Light in the morning', Price: '800000', Category: 'hop-hoa', PriceOld: '950000', Images: '/HopHoaTuoi/13212_light-in-the-morning.jpg' },
        { Name: 'Ngày tôn vinh', Price: '2000000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14687_ngay-ton-vinh.jpg' },
        { Name: 'Tâm huyết', Price: '1000000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14499_tam-huyet.jpg' },
        { Name: 'Duyên Dáng', Price: '1100000', Category: 'hop-hoa', PriceOld: '1200000', Images: '/HopHoaTuoi/8024_duyen-dang.jpg' },
        { Name: 'Thầm mong', Price: '750000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/13213_tham-mong.jpg' },
        { Name: 'Biết ơn 2', Price: '850000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14607_biet-on.jpg' },
        { Name: 'Hạnh Phúc', Price: '800000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/5205_hanh-phuc.jpg' },
        { Name: 'Lời cám ơn 2', Price: '800000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14605_loi-cam-on.jpg' },
        { Name: 'Hạnh Phúc Ngọt Ngào', Price: '700000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/3205_hanh-phuc-ngot-ngao.jpg' },
        { Name: 'Một ngày nắng xanh', Price: '800000', Category: 'hop-hoa', PriceOld: '900000', Images: '/HopHoaTuoi/14497_mot-ngay-nang-xanh.jpg' },
        { Name: 'Ước mơ lớn', Price: '750000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14602_uoc-mo-lon.jpg' },
        { Name: 'Hành trình rực rỡ', Price: '700000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14601_hanh-trinh-ruc-ro.jpg' },
        { Name: 'Love story', Price: '600000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/11692_love-story.jpg' },
        { Name: 'Khúc hát yêu thương', Price: '750000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14599_khuc-hat-yeu-thuong.jpg' },
        { Name: 'Ước mơ bay cao', Price: '700000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14598_uoc-mo-bay-cao.jpg' },
        { Name: 'Party', Price: '950000', Category: 'hop-hoa', PriceOld: '1000000', Images: '/HopHoaTuoi/13223_party.jpg' },
        { Name: 'Cutie bear and rose 10', Price: '1100000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14550_cutie-bear-and-rose.jpg' },
        { Name: 'Tấm lòng biết ơn', Price: '900000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14552_tam-long-biet-on.jpg' },
        { Name: 'Rosa Grace', Price: '1200000', Category: 'hop-hoa', PriceOld: '1300000', Images: '/HopHoaTuoi/14457_rosa-grace.jpg' },
        { Name: 'Sun flower', Price: '600000', Category: 'hop-hoa', PriceOld: '720000', Images: '/HopHoaTuoi/8040_sun-flower.png' },
        { Name: 'Dạ khúc bên em', Price: '450000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14673_da-khuc-ben-em.jpg' },
        { Name: 'Vững chắc', Price: '450000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14674_vung-chac.jpg' },
        { Name: 'A little sweet', Price: '1500000', Category: 'hop-hoa', PriceOld: '1700000', Images: '/HopHoaTuoi/13225_a-little-sweet.jpg' },
        { Name: 'Only rose 1', Price: '800000', Category: 'hop-hoa', PriceOld: '875000', Images: '/HopHoaTuoi/12888_only-rose.jpg' },
        { Name: 'Xanh trong', Price: '1500000', Category: 'hop-hoa', PriceOld: '1700000', Images: '/HopHoaTuoi/14475_xanh-trong.jpg' },
        { Name: 'Thanh tao', Price: '2000000', Category: 'hop-hoa', PriceOld: '2300000', Images: '/HopHoaTuoi/14678_thanh-tao.jpg' },
        { Name: 'Hồng tinh khôi', Price: '1800000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14477_hong-tinh-khoi.jpg' },
        { Name: 'Ước Mơ Vươn Xa', Price: '2000000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14680_uoc-mo-vuon-xa.jpg' },
        { Name: 'Bước ngoặt', Price: '1800000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14681_buoc-ngoat.jpg' },
        { Name: 'Momy love', Price: '2500000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14682_momy-love.jpg' },
        { Name: 'Good vibes', Price: '750000', Category: 'hop-hoa', PriceOld: '800000', Images: '/HopHoaTuoi/14502_good-vibes.jpg' },
        { Name: 'For Gentlemen 2', Price: '2000000', Category: 'hop-hoa', PriceOld: '2100000', Images: '/HopHoaTuoi/14684_for-gentlemen.jpg' },
        { Name: 'Graceful 2', Price: '2500000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14685_graceful.jpg' },
        { Name: 'Hồng tươi sáng', Price: '1500000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14478_hong-tuoi-sang.jpg' },
        { Name: 'Điều Mong Ước', Price: '1500000', Category: 'hop-hoa', PriceOld: '1700000', Images: '/HopHoaTuoi/6024_dieu-mong-uoc.png' },
        { Name: 'Gentle Bloom', Price: '1300000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14456_gentle-bloom.jpg' },
        { Name: 'Tiếp bước', Price: '2000000', Category: 'hop-hoa', PriceOld: '1700000', Images: '/HopHoaTuoi/11567_tiep-buoc.png' },
        { Name: 'Red box', Price: '1600000', Category: 'hop-hoa', PriceOld: '1875000', Images: '/HopHoaTuoi/8289_red-box.png' },
        { Name: 'Tim yêu', Price: '2200000', Category: 'hop-hoa', PriceOld: '2500000', Images: '/HopHoaTuoi/14693_tim-yeu.jpg' },
        { Name: 'Tung cánh bay xa', Price: '2500000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14690_tung-canh-bay-xa.jpg' },
        { Name: 'Hy Vọng', Price: '650000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/5129_hy-vong.jpg' },
        { Name: 'Khúc hát thành công', Price: '750000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14676_khuc-hat-thanh-cong.jpg' },
        { Name: 'Màu nắng mới', Price: '700000', Category: 'hop-hoa', PriceOld: '', Images: '/HopHoaTuoi/14845_mau-nang-moi.jpg' },
        //Xong
        //CHẬU HOA
        { Name: 'Chậu cúc Nhật', Price: '350000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-cuc-nhat_300x300.png' },
        { Name: 'Chậu cây hoa hồng 09', Price: '380000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-cay-hoa-hong-09_300x300.jpg' },
        { Name: 'Chậu đồng tiền 01', Price: '380000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-dong-tien-01_300x300.jpg' },
        { Name: 'Chậu đồng tiền 02', Price: '380000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-dong-tien-02_300x300.png' },
        { Name: 'Chậu đồng tiền 03', Price: '380000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-dong-tien-03_300x300.png' },
        { Name: 'Chậu đồng tiền 04', Price: '380000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-dong-tien-04_300x300.png' },
        { Name: 'Chậu đồng tiền 05', Price: '380000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-dong-tien-05_300x300.png' },
        { Name: 'Chậu cây oải hương 03', Price: '390000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-cay-oai-huong-03_300x300.png' },
        { Name: 'Chậu cây hoa hồng 03', Price: '380000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-cay-hoa-hong-03_300x300.png' },
        { Name: 'Chậu cây hoa hồng 04', Price: '400000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-cay-hoa-hong-04_300x300.png' },
        { Name: 'Chậu cây hoa hồng 06', Price: '400000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-cay-hoa-hong-06_300x300.png' },
        { Name: 'Cây trạng nguyên 09', Price: '420000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/cay-trang-nguyen-09_300x300.png' },
        { Name: 'Chậu cây oải hương 01', Price: '450000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-cay-oai-huong-01_300x300.png' },
        { Name: 'Chậu cây oải hương 02', Price: '450000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-cay-oai-huong-02_300x300.png' },
        { Name: 'Chậu cúc calimero 01', Price: '450000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-cuc-calimero-01_300x300.png' },
        { Name: 'Cây trạng nguyên 01', Price: '450000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/cay-trang-nguyen-01_300x300.jpg' },
        { Name: 'Cây trạng nguyên 05', Price: '450000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/cay-trang-nguyen-05_300x300.png' },
        { Name: 'Cây trạng nguyên 02', Price: '460000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/cay-trang-nguyen-02_300x300.png' },
        { Name: 'Chậu cây hoa hồng 01', Price: '480000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-cay-hoa-hong-01_300x300.png' },
        { Name: 'Chậu cây hoa hồng 07', Price: '480000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-cay-hoa-hong-07_300x300.png' },
        { Name: 'Chậu cúc thân gỗ', Price: '480000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-cuc-than-go_300x300.jpg' },
        { Name: 'Cây trạng nguyên 03', Price: '480000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/cay-trang-nguyen-03_300x300.png' },
        { Name: 'Cây trạng nguyên 06', Price: '480000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/cay-trang-nguyen-06_300x300.jpg' },
        { Name: 'Chậu cây hoa hồng 05', Price: '500000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-cay-hoa-hong-05_300x300.png' },
        { Name: 'Chậu hồng môn 02', Price: '500000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-hong-mon-02_300x300.png' },
        { Name: 'Cây trạng nguyên 10', Price: '500000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-hong-mon-02_300x300.png' },
        { Name: 'Chậu cây cẩm tú cầu 03', Price: '550000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-cay-cam-tu-cau-03_300x300.png' },
        { Name: 'Cây trạng nguyên 08', Price: '550000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/cay-trang-nguyen-08_300x300.png' },
        { Name: 'Chậu cây cẩm tú cầu 01', Price: '600000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-cay-cam-tu-cau-01_300x300.png' },
        { Name: 'Chậu cây cẩm tú cầu 04', Price: '600000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-cay-cam-tu-cau-04_300x300.png' },
        { Name: 'Cây trạng nguyên 07', Price: '600000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/cay-trang-nguyen-07_300x300.png' },
        { Name: 'Cây trạng nguyên 13', Price: '600000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/cay-trang-nguyen-13_300x300.jpg' },
        { Name: 'Cây trạng nguyên 14', Price: '600000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/cay-trang-nguyen-14_300x300.jpg' },
        { Name: 'Chậu cây cẩm tú cầu 02', Price: '650000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-cay-cam-tu-cau-02_300x300.png' },
        { Name: 'Chậu hồng môn 01', Price: '650000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-hong-mon-01_300x300.png' },
        { Name: 'Cây trạng nguyên 04', Price: '650000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/cay-trang-nguyen-04_300x300.png' },
        { Name: 'Cây trạng nguyên 16', Price: '650000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/cay-trang-nguyen-16_300x300.jpg' },
        { Name: 'Cây trạng nguyên 19', Price: '650000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/cay-trang-nguyen-19_300x300.png' },
        { Name: 'Chậu cây cẩm tú cầu 07', Price: '680000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-cay-cam-tu-cau-07_300x300.png' },
        { Name: 'Cây trạng nguyên 15', Price: '700000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/cay-trang-nguyen-15_300x300.png' },
        { Name: 'Chậu cây cẩm tú cầu 05', Price: '750000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-cay-cam-tu-cau-05_300x300.png' },
        { Name: 'Cây trạng nguyên 17', Price: '750000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/cay-trang-nguyen-17_300x300.png' },
        { Name: 'Chậu cây cẩm tú cầu 06', Price: '800000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-cay-cam-tu-cau-06_300x300.png' },
        { Name: 'Chậu cây hoa hồng 08', Price: '800000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-cay-hoa-hong-08_300x300.png' },
        { Name: 'Cây trạng nguyên 11', Price: '800000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/cay-trang-nguyen-11_300x300.png' },
        { Name: 'Chậu cây hoa hồng 02', Price: '850000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-cay-hoa-hong-02_300x300.png' },
        { Name: 'Cây trạng nguyên 12', Price: '950000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/cay-trang-nguyen-12_300x300.png' },
        { Name: 'Chậu cây tulip 01', Price: '1200000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-cay-tulip-01_300x300.png' },
        { Name: 'Chậu cây tulip 02', Price: '1450000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-cay-tulip-02_300x300.png' },
        { Name: 'Cây trạng nguyên 18', Price: '1450000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/cay-trang-nguyen-18_300x300.png' },
        { Name: 'Chậu cây tulip 03', Price: '1800000', Category: 'chau-hoa-tuoi', PriceOld: '', Images: '/ChauHoa/chau-cay-tulip-03_300x300.png' },
        //Hoa Sáp
        { Name: 'Bó hoa sáp 1', Price: '450000', Category: 'hoa-sap', PriceOld: '', Images: '/HoaSap/hoasap1.jpg' },
        { Name: 'Bó hoa sáp đỏ', Price: '450000', Category: 'hoa-sap', PriceOld: '', Images: '/HoaSap/hoasap2.jpg' },
        { Name: 'Bó hoa sáp 3', Price: '350000', Category: 'hoa-sap', PriceOld: '', Images: '/HoaSap/hoasap3.jpg' },
        { Name: 'Bó hoa sáp 4', Price: '350000', Category: 'hoa-sap', PriceOld: '', Images: '/HoaSap/hoasap4.jpg' },
        { Name: 'Bó hoa sáp 5', Price: '1475000', Category: 'hoa-sap', PriceOld: '', Images: '/HoaSap/hoasap5.jpg' },
        // Xong
        //Hoa chia buồn
        { Name: 'Thành Kính Phân Ưu 3', Price: '2650000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/2124_thanh-kinh-phan-uu.jpg' },
        { Name: 'Lời tạm biệt', Price: '650000', Category: 'hoa-chia-buon', PriceOld: '700000', Images: '/HoaChiaBuon/12722_loi-tam-biet.jpg' },
        { Name: 'Thành Kính Phân Ưu 1', Price: '750000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/2123_thanh-kinh-phan-uu.jpg' },
        { Name: 'Kệ Chia Buồn 04', Price: '850000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/13146_ke-chia-buon.jpg' },
        { Name: 'Kệ Chia Buồn 02', Price: '650000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/13144_ke-chia-buon.jpg' },
        { Name: 'Deep condolences', Price: '850000', Category: 'hoa-chia-buon', PriceOld: '950000', Images: '/HoaChiaBuon/8396_deep-condolences.png' },
        { Name: 'Tâm nguyện', Price: '1000000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/13099_tam-nguyen.jpg' },
        { Name: 'Dòng kí ức', Price: '800000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/11852_dong-ki-uc.jpg' },
        { Name: 'Ly Biệt', Price: '1500000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/13104_ly-biet.jpg' },
        { Name: 'Nguyện cầu', Price: '1500000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/12489_nguyen-cau.jpg' },
        { Name: 'Ước Nguyện', Price: '1500000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/12501_uoc-nguyen.jpg' },
        { Name: 'Kệ Chia Buồn 01', Price: '600000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/13143_ke-chia-buon.jpg' },
        { Name: 'Thênh Thang', Price: '1200000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/12488_thenh-thang.jpg' },
        { Name: 'Rest in peace', Price: '1350000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/8388_rest-in-peace.png' },
        { Name: 'Condolence 2', Price: '1500000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/13322_condolence.jpg' },
        { Name: 'Condolence 4', Price: '1400000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/13316_condolence.jpg' },
        { Name: 'Kí ức xưa', Price: '1000000', Category: 'hoa-chia-buon', PriceOld: '1100000', Images: '/HoaChiaBuon/8390_ki-uc-xua.png' },
        { Name: 'Hư Vô', Price: '1550000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/13100_hu-vo.jpg' },
        { Name: 'Tâm Thành', Price: '1500000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/12500_tam-thanh.jpg' },
        { Name: 'Lệ dâng', Price: '1800000', Category: 'hoa-chia-buon', PriceOld: '1935185', Images: '/HoaChiaBuon/12673_le-dang.jpg' },
        { Name: 'Vô Định', Price: '1600000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/13101_vo-dinh.jpg' },
        { Name: 'Condolence 5', Price: '1600000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/13315_condolence.jpg' },
        { Name: 'Condolence 3', Price: '2000000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/13318_condolence.jpg' },
        { Name: 'Mãi xa', Price: '1500000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/10966_mai-xa.png' },
        { Name: 'Hợp tan', Price: '1900000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/12487_hop-tan.jpg' },
        { Name: 'Cõi an yên', Price: '2000000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/11616_coi-an-yen.jpg' },
        { Name: 'Dĩ Vãng', Price: '2800000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/5332_di-vang.jpg' },
        { Name: 'Condolence 1', Price: '2500000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/13319_condolence.png' },
        { Name: 'Tiễn đưa', Price: '1600000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/13103_tien-dua.jpg' },
        { Name: 'Hư không 2', Price: '2000000', Category: 'hoa-chia-buon', PriceOld: '2240741', Images: '/HoaChiaBuon/12629_hu-khong.jpg' },
        { Name: 'Kiếp Vô Thường', Price: '3300000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/5596_kiep-vo-thuong.jpg' },
        { Name: 'Kiếp nhân sinh 2', Price: '2000000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/12628_kiep-nhan-sinh.jpg' },
        { Name: 'Thánh Giá', Price: '2200000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/6952_thanh-gia.png' },
        { Name: 'Cánh Hồng Phai', Price: '2500000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/4702_canh-hong-phai.png' },
        { Name: 'Deep condolence 2', Price: '4000000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/12720_deep-condolence.jpg' },
        { Name: 'Mãi nhớ', Price: '2000000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/4353_mai-nho.jpg' },
        { Name: 'Vĩnh biệt 2', Price: '1200000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/3064_vinh-biet.jpg' },
        { Name: 'Lặng Lẽ', Price: '1600000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/9452_lang-le.png' },
        { Name: 'The end', Price: '1300000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/4589_the-end.jpg' },
        { Name: 'Tiếc thương 2', Price: '4000000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/8399_tiec-thuong.png' },
        { Name: 'Yên Nghỉ', Price: '2700000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/5260_yen-nghi.jpg' },
        { Name: 'Phù du', Price: '1500000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/4672_phu-du.jpg' },
        { Name: 'Vô Thường', Price: '1300000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/5203_vo-thuong.jpg' },
        { Name: 'Heaven', Price: '3300000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/5127_heaven.jpg' },
        { Name: 'Giọt mưa sa', Price: '2500000', Category: 'hoa-chia-buon', PriceOld: '3055556', Images: '/HoaChiaBuon/4409_giot-mua-sa.jpg' },
        { Name: 'Giã Biệt 1', Price: '2500000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/3013_gia-biet.jpg' },
        { Name: 'Cát bụi', Price: '1200000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/4344_cat-bui.jpg' },
        { Name: 'Kính Dâng', Price: '1150000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/5974_kinh-dang.jpg' },
        { Name: 'Luyến Tiếc 1', Price: '1650000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/3087_luyen-tiec.jpg' },
        { Name: 'Hạc Trắng 3', Price: '1450000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/2663_hac-trang.jpg' },
        { Name: 'Hạ trắng', Price: '1200000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/4507_ha-trang.jpg' },
        { Name: 'Hai thế giới 2', Price: '1400000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/8397_hai-the-gioi.png' },
        { Name: 'Tiễn Biệt 2', Price: '1100000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/3113_tien-biet.jpg' },
        { Name: 'Như Mây Trời', Price: '1900000', Category: 'hoa-chia-buon', PriceOld: '2037037', Images: '/HoaChiaBuon/12502_nhu-may-troi.jpg' },
        { Name: 'Thiên thu', Price: '1550000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/4412_thien-thu.jpg' },
        { Name: 'Thiên thu 2', Price: '1500000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/8778_thien-thu.png' },
        { Name: 'Luyến Tiếc 4', Price: '1500000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/2485_luyen-tiec.jpg' },
        { Name: 'Về Nơi Xa', Price: '1550000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/5125_ve-noi-xa.jpg' },
        { Name: 'Ký Ức', Price: '2300000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/5218_ky-uc.jpg' },
        { Name: 'Ngày Chia Xa', Price: '1800000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/5405_ngay-chia-xa.jpg' },
        { Name: 'Dịu Êm', Price: '1800000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/4933_diu-em.jpg' },
        { Name: 'Khúc biệt ly', Price: '650000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/8395_khuc-biet-ly.png' },
        { Name: 'Lãng quên', Price: '1250000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/4681_lang-quen.jpg' },
        { Name: 'Lệ buồn', Price: '1500000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/9451_le-buon.png' },
        { Name: 'Lời tiễn biệt', Price: '1790000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/4675_loi-tien-biet.jpg' },
        { Name: 'Miền Cực Lạc', Price: '1250000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/5051_mien-cuc-lac.jpg' },
        { Name: 'Một cõi đi về', Price: '1100000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/4372_mot-coi-di-ve.jpg' },
        { Name: 'Ánh Chiều Tà', Price: '1000000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/6014_anh-chieu-ta.jpg' },
        { Name: 'Phút Chia Ly', Price: '1000000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/5239_phut-chia-ly.jpg' },
        { Name: 'Kệ Chia Buồn 03', Price: '600000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/13145_ke-chia-buon.jpg' },
        { Name: 'Kệ Chia Buồn 05', Price: '700000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/13147_ke-chia-buon.jpg' },
        { Name: 'Hư không', Price: '650000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/4371_hu-khong.jpg' },
        { Name: 'Heaven 02', Price: '1000000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/13200_heaven.jpg' },
        { Name: 'Sương Trắng', Price: '2200000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/6950_suong-trang.png' },
        { Name: 'Xa cách', Price: '1500000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/13102_xa-cach.jpg' },
        { Name: 'Niềm thương tiếc', Price: '2000000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/11865_niem-thuong-tiec.jpg' },
        { Name: 'An lạc', Price: '1800000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/10965_an-lac.png' },
        { Name: 'Nhân sinh', Price: '1700000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/12503_nhan-sinh.jpg' },
        { Name: 'An yên', Price: '9500000', Category: 'hoa-chia-buon', PriceOld: '10185185', Images: '/HoaChiaBuon/13402_an-yen.jpg' },
        { Name: 'Tôn nghiêm', Price: '1400000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/13632_ton-nghiem.jpg' },
        { Name: 'Bó hoa chia buồn 1', Price: '350000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/14336_bo-hoa-chia-buon.jpg' },
        { Name: 'Bó hoa chia buồn 2', Price: '350000', Category: 'hoa-chia-buon', PriceOld: '', Images: '/HoaChiaBuon/14337_bo-hoa-chia-buon.jpg' },
        // Xong 
        //Giỏ trái cây
        { Name: 'Giỏ trái cây 217', Category: 'gio-qua-trai-cay', Price: '1300000', PriceOld: '', Images: '/GioQuaTraiCay/gio-trai-cay-217_300x300.jpg' },
        { Name: 'Giỏ trái cây 292', Category: 'gio-qua-trai-cay', Price: '1200000', PriceOld: '', Images: '/GioQuaTraiCay/gio-trai-cay-292_300x300.jpg' },
        { Name: 'Giỏ trái cây 295', Category: 'gio-qua-trai-cay', Price: '1200000', PriceOld: '', Images: '/GioQuaTraiCay/gio-trai-cay-295_300x300.jpg' },
        { Name: 'Giỏ trái cây 299', Category: 'gio-qua-trai-cay', Price: '1200000', PriceOld: '', Images: '/GioQuaTraiCay/gio-trai-cay-299_300x300.jpg' },
        { Name: 'Giỏ trái cây 301', Category: 'gio-qua-trai-cay', Price: '1200000', PriceOld: '', Images: '/GioQuaTraiCay/gio-trai-cay-301_300x300.jpg' },
        { Name: 'Giỏ trái cây 309', Category: 'gio-qua-trai-cay', Price: '1200000', PriceOld: '', Images: '/GioQuaTraiCay/gio-trai-cay-309_300x300.jpg' },
        { Name: 'Giỏ trái cây 316', Category: 'gio-qua-trai-cay', Price: '1200000', PriceOld: '', Images: '/GioQuaTraiCay/gio-trai-cay-316_300x300.jpg' },
        { Name: 'Giỏ trái cây 003', Category: 'gio-qua-trai-cay', Price: '1300000', PriceOld: '', Images: '/GioQuaTraiCay/giotraicay003-1300000.jpg' },
        { Name: 'Giỏ trái cây 44', Category: 'gio-qua-trai-cay', Price: '1600000', PriceOld: '', Images: '/GioQuaTraiCay/giotraicay44-1600000.jpg' },
        { Name: 'Giỏ trái cây 201', Category: 'gio-qua-trai-cay', Price: '1500000', PriceOld: '', Images: '/GioQuaTraiCay/giotraicay201-1500000.jpg' },
        { Name: 'Giỏ trái cây 222', Category: 'gio-qua-trai-cay', Price: '1200000', PriceOld: '', Images: '/GioQuaTraiCay/giotraicay222-1200000.jpg' },
        { Name: 'Giỏ trái cây 305', Category: 'gio-qua-trai-cay', Price: '700000', PriceOld: '', Images: '/GioQuaTraiCay/giotraicay350-700.jpg' },
        { Name: 'Giỏ trái cây 313', Category: 'gio-qua-trai-cay', Price: '850000', PriceOld: '', Images: '/GioQuaTraiCay/giotraicay313-850000.jpg' },
        { Name: 'Giỏ trái cây 350', Category: 'gio-qua-trai-cay', Price: '700000', PriceOld: '', Images: '/GioQuaTraiCay/giotraicay350-700.jpg' },
        { Name: 'Giỏ trái cây 501', Category: 'gio-qua-trai-cay', Price: '500000', PriceOld: '', Images: '/GioQuaTraiCay/giotraicay501-500000.jpg' },
        { Name: 'Giỏ trái cây 666', Category: 'gio-qua-trai-cay', Price: '900000', PriceOld: '', Images: '/GioQuaTraiCay/giotraicay666-900000.jpg' },
        { Name: 'Giỏ trái cây 675', Category: 'gio-qua-trai-cay', Price: '1050000', PriceOld: '', Images: '/GioQuaTraiCay/giotraicay675-1050000.jpg' },
        { Name: 'Giỏ trái cây 777', Category: 'gio-qua-trai-cay', Price: '900000', PriceOld: '', Images: '/GioQuaTraiCay/giotraicay777-900000.jpg' },
        { Name: 'Giỏ trái cây 2201', Category: 'gio-qua-trai-cay', Price: '950000', PriceOld: '', Images: '/GioQuaTraiCay/giotraicay2201-950000.jpg' },
        { Name: 'Giỏ trái cây 625', Category: 'gio-qua-trai-cay', Price: '1000000', PriceOld: '', Images: '/GioQuaTraiCay/giotraicay1000000.jpg' },
        //Xong 
        //Bó hoa bi
        { Name: 'Pink baby', Category: 'hoa-bi', Price: '1200000', PriceOld: '1350000', Images: '/BoHoa/10693_pink-baby.png' },
        { Name: 'Gặp gỡ', Category: 'hoa-bi', Price: '300000', PriceOld: '', Images: '/BoHoa/5598_gap-go.jpg' },
        { Name: 'Million Stars', Category: 'hoa-bi', Price: '570000', PriceOld: '', Images: '/BoHoa/7191_million-stars.png' },
        { Name: 'Babys Breath 2', Category: 'hoa-bi', Price: '600000', PriceOld: '', Images: ' /BoHoaBi/4075_babys-breath.jpg' },
        { Name: 'Babys breath', Category: 'hoa-bi', Price: '1500000', PriceOld: '', Images: '/BoHoa/1961_babys-breath.jpg' },
        { Name: 'Bó hoa bi 20', Category: 'hoa-bi', Price: '520000', PriceOld: '', Images: '/BoHoaBi/bohoabi520.png' },
        { Name: 'Tana Baby', Category: 'hoa-bi', Price: '550000', PriceOld: '', Images: '/BoHoa/11868_tana-baby.jpg' },
        { Name: 'Pretty Clouds', Category: 'hoa-bi', Price: '450000', PriceOld: '', Images: '/BoHoa/13036_pretty-clouds.jpg' },
        { Name: 'Baby flower', Category: 'hoa-bi', Price: '400000', PriceOld: '450000', Images: '/BoHoa/13294_baby-flower.jpg' },
        { Name: 'My Babe', Category: 'hoa-bi', Price: '550000', PriceOld: '', Images: '/BoHoa/13292_my-babe.jpg' },
        { Name: 'The greatest thing', Category: 'hoa-bi', Price: '500000', PriceOld: '350000', Images: '/BoHoa/13293_the-greatest-thing.jpg' },
        { Name: 'Ngọt ngào 3', Category: 'hoa-bi', Price: '1000000', PriceOld: '1200000', Images: '/GioHoaTuoi/12528_ngot-ngao.jpg' },
        { Name: 'Baby Anh Yêu Em', Price: '800000', Category: 'hoa-bi', PriceOld: '', Images: '/GioHoaTuoi/13218_baby-anh-yeu-em.jpg' },
        { Name: 'Tana baby 2', Category: 'hoa-bi', Price: '350000', PriceOld: '', Images: '/BoHoa/13291_my-tana.jpg' },
        { Name: 'Baby heart', Category: 'hoa-bi', Price: '350000', PriceOld: '', Images: '/BoHoaBi/14428_baby-heart.jpg' },
        //bó hoa cẩm chướng
        { Name: 'Tình Yêu Màu Nắng', Price: '850000', Category: 'bo-hoa-cam-chuong', PriceOld: '916000', Images: '/HopHoaTuoi/8819_tinh-yeu-mau-nang.jpg' },
        { Name: 'My dear', Price: '900000', Category: 'bo-hoa-cam-chuong', PriceOld: '', Images: '/HopHoaTuoi/8369_my-dear.jpg' },
        { Name: 'Tình', Category: 'bo-hoa-cam-chuong', Price: '450000', PriceOld: '500000', Images: '/BoHoa/10704_tinh.jpg' },
        { Name: 'Tươi vui', Category: 'bo-hoa-cam-chuong', Price: '500000', PriceOld: '', Images: '/BoHoa/11867_tuoi-vui.jpg' },
        { Name: 'Tình Yêu Vĩnh Cửu 2', Category: 'bo-hoa-cam-chuong', Price: '2500000', PriceOld: '2700000', Images: '/BoHoa/6020_tinh-yeu-vinh-cuu.jpg' },
        { Name: 'Giản dị', Category: 'bo-hoa-cam-chuong', Price: '300000', PriceOld: '350000', Images: '/BoHoa/9444_gian-di.jpg' },
        { Name: 'Hoa tặng sự kiện 3(+20 bó)', Price: '1600000', Category: 'bo-hoa-cam-chuong', PriceOld: '1875000', Images: '/HoaChucMung/10899_hoa-tang-su-kien--bo.jpg' },
        { Name: 'Freshness', Category: 'bo-hoa-cam-chuong', Price: '800000', PriceOld: '', Images: '/BoHoa/10261_freshness.png' },
        { Name: 'Closer', Category: 'bohoa', Price: '650000', PriceOld: '', Images: '/BoHoa/8032_closer.png' },
        { Name: 'Tím yêu thương', Price: '500000', Category: 'bo-hoa-cam-chuong', PriceOld: '', Images: '/HopHoaTuoi/3772_tim-yeu-thuong.jpg' },
        { Name: 'Mộng mơ', Price: '600000', Category: 'bo-hoa-cam-chuong', PriceOld: '', Images: '/HopHoaTuoi/4337_mong-mo.jpg' },
        { Name: 'Sắc Hồng Xinh', Price: '950000', Category: 'bo-hoa-cam-chuong', PriceOld: '', Images: '/HopHoaTuoi/5424_sac-hong-xinh.jpg' },
        { Name: 'Tiếp bước', Price: '2000000', Category: 'bo-hoa-cam-chuong', PriceOld: '1700000', Images: '/HopHoaTuoi/11567_tiep-buoc.png' },
        { Name: 'Celebrate', Price: '750000', Category: 'bo-hoa-cam-chuong', PriceOld: '850000', Images: '/HopHoaTuoi/8444_celebrate.jpg' },
        { Name: 'Gửi Người Tôi Yêu', Price: '850000', Category: 'bo-hoa-cam-chuong', PriceOld: '1150000', Images: '/HopHoaTuoi/3197_gui-nguoi-toi-yeu.jpg' },
        { Name: 'Hoàng hôn ngọt ngào', Price: '850000', Category: 'bo-hoa-cam-chuong', PriceOld: '', Images: '/HopHoaTuoi/10905_hoang-hon-ngot-ngao.png' },
        { Name: 'Hương gió', Price: '500000', Category: 'bo-hoa-cam-chuong', PriceOld: '', Images: '/HopHoaTuoi/8443_huong-gio.png' },
        { Name: 'Home sweet home', Price: '880000', Category: 'bo-hoa-cam-chuong', PriceOld: '1200000', Images: '/HopHoaTuoi/8367_home-sweet-home.jpg' },
        { Name: 'Thanh âm', Price: '800000', Category: 'bo-hoa-cam-chuong', PriceOld: '985000', Images: '/GioHoaTuoi/10709_thanh-am.jpg' },
        { Name: 'Nắng Sớm Mai', Price: '900000', Category: 'bo-hoa-cam-chuong', PriceOld: '', Images: '/HopHoaTuoi/5282_nang-som-mai.jpg' },
        { Name: 'Missing you', Price: '990000', Category: 'bo-hoa-cam-chuong', PriceOld: '', Images: '/GioHoaTuoi/8088_missing-you.jpg' },
        { Name: 'Ngày tươi vui', Price: '600000', Category: 'bo-hoa-cam-chuong', PriceOld: '', Images: '/HopHoaTuoi/12485_ngay-tuoi-vui.jpg' },
        { Name: 'Tươi tắn', Category: 'bo-hoa-cam-chuong', Price: '250000', PriceOld: '', Images: '/BoHoa/12587_tuoi-tan.jpg' },
        { Name: 'Say nắng', Category: 'bo-hoa-cam-chuong', Price: '335000', PriceOld: '', Images: '/BoHoa/13446_say-nang.png' },
        { Name: 'Vui tươi', Category: 'bo-hoa-cam-chuong', Price: '300000', PriceOld: '', Images: '/BoHoa/13445_vui-tuoi.jpg' },
        { Name: 'Một sớm mai', Category: 'bo-hoa-cam-chuong', Price: '280000', PriceOld: '', Images: '/BoHoa/13444_mot-som-mai.jpg' },
        { Name: 'Niềm vui nhỏ 2', Category: 'bo-hoa-cam-chuong', Price: '550000', PriceOld: '', Images: '/BoHoa/12600_niem-vui-nho.jpg' },
        { Name: 'Hẹn hò 2', Category: 'bo-hoa-cam-chuong', Price: '500000', PriceOld: '', Images: '/BoHoa/13284_hen-ho.jpg' },
        { Name: 'Ngày mới ngọt ngào', Category: 'bo-hoa-cam-chuong', Price: '550000', PriceOld: '', Images: '/BoHoa/13282_ngay-moi-ngot-ngao.jpg' },
        { Name: 'Điệu valse mùa xuân', Category: 'bo-hoa-cam-chuong', Price: '550000', PriceOld: '', Images: '/BoHoa/13231_dieu-valse-mua-xuan.jpg' },
        { Name: 'Cutie bear and rose 8', Price: '900000', Category: 'bo-hoa-cam-chuong', PriceOld: '', Images: '/HopHoaTuoi/14190_cutie-bear-and-rose.jpg' },
        { Name: 'Sweet heart', Category: 'bo-hoa-cam-chuong', Price: '1400000', PriceOld: '1500000', Images: '/BoHoa/13235_sweet-heart.jpg' },
        { Name: 'Món quà hạnh phúc', Category: 'bo-hoa-cam-chuong', Price: '570000', PriceOld: '', Images: '/BoHoa/13906_mon-qua-hanh-phuc.jpg' },
        { Name: 'Động lực', Price: '600000', Category: 'bo-hoa-cam-chuong', PriceOld: '', Images: '/GioHoaTuoi/13215_dong-luc.jpg' },
        { Name: 'Bó hoa cẩm chướng đỏ', Category: 'bo-hoa-cam-chuong', Price: '500000', PriceOld: '', Images: '/BoHoa/8756_bo-hoa-cam-chuong-do.jpg' },
        { Name: 'Bó cẩm chướng hồng', Category: 'bo-hoa-cam-chuong', Price: '500000', PriceOld: '', Images: '/BoHoa/11586_bo-cam-chuong-hong.jpg' },
        { Name: 'Dating', Category: 'bo-hoa-cam-chuong', Price: '250000', PriceOld: '', Images: '/BoHoa/14270_dating.jpg' },
        { Name: 'Phút giây tỏa sáng', Category: 'bo-hoa-cam-chuong', Price: '200000', PriceOld: '', Images: '/BoHoa/13253_phut-giay-toa-sang.jpg' },
        { Name: 'Hương mùa hè', Price: '500000', Category: 'bo-hoa-cam-chuong', PriceOld: '550000', Images: '/HopHoaTuoi/13131_huong-mua-he.jpg' },
        { Name: 'Blink', Category: 'bo-hoa-cam-chuong', Price: '400000', PriceOld: '', Images: '/BoHoa/14356_blink.jpg' },
        { Name: 'Trong ánh nắng', Category: 'bo-hoa-cam-chuong', Price: '300000', PriceOld: '', Images: '/BoHoa/14433_trong-anh-nang.jpg' },
        { Name: 'Đắm say 2', Category: 'bo-hoa-cam-chuong', Price: '250000', PriceOld: '', Images: '/BoHoa/14432_dam-say.jpg' },
        { Name: 'Sweet', Price: '400000', Category: 'bo-hoa-cam-chuong', PriceOld: '', Images: '/GioHoaTuoi/14447_sweet.jpg' },
        { Name: 'Một ngày nắng hồng', Price: '750000', Category: 'bo-hoa-cam-chuong', PriceOld: '', Images: '/HopHoaTuoi/14498_mot-ngay-nang-hong.jpg' },
        { Name: 'Sweet girl', Price: '650000', Category: 'bo-hoa-cam-chuong', PriceOld: '', Images: '/GioHoaTuoi/14652_sweet-girl.jpg' },
        { Name: 'Cutie bear and rose 11', Price: '1200000', Category: 'bo-hoa-cam-chuong', PriceOld: '', Images: '/HopHoaTuoi/14551_cutie-bear-and-rose.jpg' },
        { Name: 'Sức sống', Price: '1000000', Category: 'bo-hoa-cam-chuong', PriceOld: '', Images: '/HopHoaTuoi/14553_suc-song.jpg' },
        { Name: 'Lời cám ơn 2', Price: '800000', Category: 'bo-hoa-cam-chuong', PriceOld: '', Images: '/HopHoaTuoi/14605_loi-cam-on.jpg' },
        { Name: 'Dạ khúc bên em', Price: '450000', Category: 'bo-hoa-cam-chuong', PriceOld: '', Images: '/HopHoaTuoi/14673_da-khuc-ben-em.jpg' },
        // Xong
        //Bó hoa cát tường
        { Name: 'Điều Mong Ước', Price: '1500000', Category: 'bohoacattuong', PriceOld: '1700000', Images: '/HopHoaTuoi/6024_dieu-mong-uoc.png' },
        { Name: 'Hoa tặng sự kiện 2(+20 bó)', Price: '2500000', Category: 'bohoacattuong', PriceOld: '', Images: '/BoHoaCatTuong/10898_hoa-tang-su-kien--bo.jpg' },
        { Name: 'Tươi vui', Category: 'bohoacattuong', Price: '500000', PriceOld: '', Images: '/BoHoa/11867_tuoi-vui.jpg' },
        { Name: 'Love box 3', Price: '400000', Category: 'bohoacattuong', PriceOld: '', Images: '/HopHoaTuoi/3699_love-box.jpg' },
        { Name: 'Mây ngàn', Price: '500000', Category: 'bohoacattuong', PriceOld: '550000', Images: '/HopHoaTuoi/11613_may-ngan.jpg' },
        { Name: 'Trọn Vẹn Bên Nhau', Price: '800000', Category: 'bohoacattuong', PriceOld: '', Images: '/HopHoaTuoi/6026_tron-ven-ben-nhau.png' },
        { Name: 'Niềm Tin', Price: '700000', Category: 'bohoacattuong', PriceOld: '', Images: '/HopHoaTuoi/5201_niem-tin.jpg' },
        { Name: 'Hương gió', Price: '500000', Category: 'bohoacattuong', PriceOld: '', Images: '/HopHoaTuoi/8443_huong-gio.png' },
        { Name: 'Đôi ta', Price: '500000', Category: 'bohoacattuong', PriceOld: '', Images: '/GioHoaTuoi/11611_doi-ta.jpg' },
        { Name: 'Tình yêu đầu', Price: '650000', Category: 'bohoacattuong', PriceOld: '', Images: '/GioHoaTuoi/8830_tinh-yeu-dau.jpg' },
        { Name: 'Ngọc sắc', Price: '850000', Category: 'bohoacattuong', PriceOld: '900000', Images: '/GioHoaTuoi/10707_ngoc-sac.jpg' },
        { Name: 'Luyến Tiếc 1', Price: '1650000', Category: 'bohoacattuong', PriceOld: '', Images: '/HoaChiaBuon/3087_luyen-tiec.jpg' },
        { Name: 'Miền Cực Lạc', Price: '1250000', Category: 'bohoacattuong', PriceOld: '', Images: '/HoaChiaBuon/5051_mien-cuc-lac.jpg' },
        { Name: 'Kiếp Vô Thường', Price: '3300000', Category: 'bohoacattuong', PriceOld: '', Images: '/HoaChiaBuon/5596_kiep-vo-thuong.jpg' },
        { Name: 'Priceless Heart', Price: '1900000', Category: 'bohoacattuong', PriceOld: '', Images: '/BoHoaCatTuong/10442_priceless-heart.png' },
        { Name: 'Thầm lặng', Price: '660000', Category: 'bohoacattuong', PriceOld: '', Images: '/BoHoaCatTuong/10266_tham-lang.png' },
        { Name: 'Be good', Price: '650000', Category: 'bohoacattuong', PriceOld: '', Images: '/BoHoaCatTuong/12486_be-good.jpg' },
        { Name: 'Cutie bear and rose 8', Price: '900000', Category: 'bohoacattuong', PriceOld: '', Images: '/HopHoaTuoi/14190_cutie-bear-and-rose.jpg' },
        { Name: 'Bản tình ca mùa đông', Price: '500000', Category: 'bohoacattuong', PriceOld: '600000', Images: '/HopHoaTuoi/8038_ban-tinh-ca-mua-dong.jpg' },
        { Name: 'Năng lượng tràn đầy', Price: '1500000', Category: 'bohoacattuong', PriceOld: '1550000', Images: '/HopHoaTuoi/10906_nang-luong-tran-day.jpg' },
        { Name: 'Nắng ban mai', Category: 'bohoacattuong', Price: '1500000', PriceOld: '1600000', Images: '/BoHoa/14353_nang-ban-mai.jpg' },
        { Name: 'Giỏ cát tường xanh', Price: '600000', Category: 'bohoacattuong', PriceOld: '', Images: '/GioHoaTuoi/14451_gio-cat-tuong-xanh.jpg' },
        { Name: 'Sunshine Bliss', Price: '350000', Category: 'bohoacattuong', PriceOld: '', Images: '/GioHoaTuoi/14454_sunshine-bliss.jpg' },
        { Name: 'Món quà bất ngờ', Price: '750000', Category: 'bohoacattuong', PriceOld: '', Images: '/GioHoaTuoi/14516_mon-qua-bat-ngo.jpg' },
        { Name: 'A little wish', Category: 'bohoacattuong', Price: '700000', PriceOld: '', Images: '/GioHoaTuoi/8671_a-little-wish.jpg' },
        { Name: 'Thanh khiết', Price: '350000', Category: 'bohoacattuong', PriceOld: '450000', Images: '/GioHoaTuoi/14709_thanh-khiet.jpg' },
        { Name: 'Rạng rỡ', Category: 'bohoacattuong', Price: '350000', PriceOld: '400000', Images: '/GioHoaTuoi/14710_rang-ro.jpg' },
        { Name: 'New Sun', Price: '550000', Category: 'bohoacattuong', PriceOld: '', Images: '/GioHoaTuoi/13656_new-sun.jpg' },
        // Xong
        // Hoa cúc
        { Name: 'Only You', Category: 'hoa-cuc', Price: '250000', PriceOld: '280000', Images: '/BoHoa/4840_only-you.jpg' },
        { Name: 'Tình', Category: 'hoa-cuc', Price: '450000', PriceOld: '500000', Images: '/BoHoa/10704_tinh.jpg' },
        { Name: 'Rung động', Category: 'hoa-cuc', Price: '850000', PriceOld: '', Images: '/BoHoa/9540_rung-dong.png' },
        { Name: 'Giản dị', Category: 'hoa-cuc', Price: '300000', PriceOld: '350000', Images: '/BoHoa/9444_gian-di.jpg' },
        { Name: 'Mong chờ', Category: 'hoa-cuc', Price: '300000', PriceOld: '350000', Images: '/BoHoa/9445_mong-cho.jpg' },
        { Name: 'Tana mộc mạc', Category: 'hoa-cuc', Price: '550000', PriceOld: '570000', Images: '/BoHoa/8425_tana-moc-mac.png' },
        { Name: 'Sương mai', Category: 'hoa-cuc', Price: '1100000', PriceOld: '1200000', Images: '/BoHoa/9372_suong-mai.jpg' },
        { Name: 'My Queen', Category: 'hoa-cuc', Price: '600000', PriceOld: '650000', Images: '/BoHoa/11588_my-queen.png' },
        { Name: 'Freshness', Category: 'hoa-cuc', Price: '800000', PriceOld: '', Images: '/BoHoa/10261_freshness.png' },
        { Name: 'Rustic', Category: 'hoa-cuc', Price: '390000', PriceOld: '400000', Images: '/BoHoa/8424_rustic.png' },
        { Name: 'Sắc cam rực rỡ', Category: 'hoa-cuc', Price: '300000', PriceOld: '', Images: '/BoHoa/11041_sac-cam-ruc-ro.jpg' },
        { Name: 'Yêu Xa 2', Category: 'hoa-cuc', Price: '500000', PriceOld: '550000', Images: '/BoHoa/5256_yeu-xa.png' },
        { Name: 'Ao ước', Price: '400000', Category: 'hoa-cuc', PriceOld: '450000', Images: '/HopHoaTuoi/11614_ao-uoc.jpg' },
        { Name: 'Love story', Price: '600000', Category: 'hoa-cuc', PriceOld: '', Images: '/HopHoaTuoi/11692_love-story.jpg' },
        { Name: 'Sun flower', Price: '600000', Category: 'hoa-cuc', PriceOld: '720000', Images: '/HopHoaTuoi/8040_sun-flower.png' },
        { Name: 'Mây ngàn', Price: '500000', Category: 'hoa-cuc', PriceOld: '550000', Images: '/HopHoaTuoi/11613_may-ngan.jpg' },
        { Name: 'Mộng mơ', Price: '600000', Category: 'hoa-cuc', PriceOld: '', Images: '/HopHoaTuoi/4337_mong-mo.jpg' },
        { Name: 'Sắc Hồng Xinh', Price: '950000', Category: 'hoa-cuc', PriceOld: '', Images: '/HopHoaTuoi/5424_sac-hong-xinh.jpg' },
        { Name: 'Tự tình', Price: '800000', Category: 'hoa-cuc', PriceOld: '', Images: '/HopHoaTuoi/11584_tu-tinh.jpg' },
        { Name: 'Morning of Garden', Price: '950000', Category: 'hoa-cuc', PriceOld: '1000000', Images: '/HopHoaTuoi/10908_morning-of-garden.png' },
        { Name: 'Celebrate', Price: '750000', Category: 'hoa-cuc', PriceOld: '850000', Images: '/HopHoaTuoi/8444_celebrate.jpg' },
        { Name: 'Hạnh phúc ngọt ngào', Category: 'hoa-cuc', Price: '1500000', PriceOld: '1600000', Images: '/BoHoa/3205_hanh-phuc-ngot-ngao.jpg' },
        { Name: 'Bản nhạc dịu êm', Price: '400000', Category: 'hoa-cuc', PriceOld: '', Images: '/HopHoaTuoi/3339_ban-nhac-diu-em.jpg' },
        { Name: 'Ngàn ánh dương', Price: '500000', Category: 'hoa-cuc', PriceOld: '', Images: '/GioHoaTuoi/11607_ngan-anh-duong.png' },
        { Name: 'Purity', Price: '500000', Category: 'hoa-cuc', PriceOld: '', Images: '/GioHoaTuoi/8447_purity.jpg' },
        { Name: 'Hẹn yêu', Price: '500000', Category: 'hoa-cuc', PriceOld: '550000', Images: '/GioHoaTuoi/11608_hen-yeu.jpg' },
        { Name: 'Công Danh Phú Quý', Price: '1500000', Category: 'hoa-cuc', PriceOld: '', Images: '/BoHoaCuc/5401_cong-danh-phu-quy.jpg' },
        { Name: 'Giã Biệt 1', Price: '2500000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/3013_gia-biet.jpg' },
        { Name: 'Hư không', Price: '650000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/4371_hu-khong.jpg' },
        { Name: 'Deep condolences', Price: '850000', Category: 'hoa-cuc', PriceOld: '950000', Images: '/HoaChiaBuon/8396_deep-condolences.png' },
        { Name: 'Tiễn Biệt 2', Price: '1100000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/3113_tien-biet.jpg' },
        { Name: 'Kí ức xưa', Price: '1000000', Category: 'hoa-cuc', PriceOld: '1100000', Images: '/HoaChiaBuon/8390_ki-uc-xua.png' },
        { Name: 'Mãi xa', Price: '1500000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/10966_mai-xa.png' },
        { Name: 'Thành Kính Phân Ưu 1', Price: '750000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/2123_thanh-kinh-phan-uu.jpg' },
        { Name: 'Kính Dâng', Price: '1150000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/5974_kinh-dang.jpg' },
        { Name: 'Hai thế giới 2', Price: '1400000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/8397_hai-the-gioi.png' },
        { Name: 'Lặng Lẽ', Price: '1600000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/9452_lang-le.png' },
        { Name: 'Dòng kí ức', Price: '800000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/11852_dong-ki-uc.jpg' },
        { Name: 'Rest in peace', Price: '1350000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/8388_rest-in-peace.png' },
        { Name: 'Luyến Tiếc 1', Price: '1650000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/3087_luyen-tiec.jpg' },
        { Name: 'An lạc', Price: '1800000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/10965_an-lac.png' },
        { Name: 'Vĩnh biệt 2', Price: '1200000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/3064_vinh-biet.jpg' },
        { Name: 'Thiên thu 2', Price: '1500000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/8778_thien-thu.png' },
        { Name: 'Về Nơi Xa', Price: '1550000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/5125_ve-noi-xa.jpg' },
        { Name: 'Cõi an yên', Price: '2000000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/11616_coi-an-yen.jpg' },
        { Name: 'Ánh Chiều Tà', Price: '1000000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/6014_anh-chieu-ta.jpg' },
        { Name: 'Lệ buồn', Price: '1500000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/9451_le-buon.png' },
        { Name: 'Sương Trắng', Price: '2200000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/6950_suong-trang.png' },
        { Name: 'Miền Cực Lạc', Price: '1250000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/5051_mien-cuc-lac.jpg' },
        { Name: 'Kiếp Vô Thường', Price: '3300000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/5596_kiep-vo-thuong.jpg' },
        { Name: 'Ngày Chia Xa', Price: '1800000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/5405_ngay-chia-xa.jpg' },
        { Name: 'Vô Thường', Price: '1300000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/5203_vo-thuong.jpg' },
        { Name: 'Vô Thường', Price: '750000', Category: 'hoa-cuc', PriceOld: '800000', Images: '/BoHoaCuc/11729_pretty-stars.png' },
        { Name: 'Yên Nghỉ', Price: '2700000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/5260_yen-nghi.jpg' },
        { Name: 'Cánh Hồng Phai', Price: '2500000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/4702_canh-hong-phai.png' },
        { Name: 'The end', Price: '1300000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/4589_the-end.jpg' },
        { Name: 'Luyến Tiếc 4', Price: '1500000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/2485_luyen-tiec.jpg' },
        { Name: 'Mãi nhớ', Price: '2000000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/4353_mai-nho.jpg' },
        { Name: 'Cát bụi', Price: '1200000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/4344_cat-bui.jpg' },
        { Name: 'Nắng Hồng', Price: '550000', Category: 'hoa-cuc', PriceOld: '650000', Images: '/BoHoaCuc/10267_nang-hong.jpg' },
        { Name: 'Phù du', Price: '1500000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/4672_phu-du.jpg' },
        { Name: 'Lãng quên', Price: '1250000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/4681_lang-quen.jpg' },
        { Name: 'Tana Baby', Category: 'hoa-cuc', Price: '550000', PriceOld: '', Images: '/BoHoa/11868_tana-baby.jpg' },
        { Name: 'Luxury vase 9', Price: '2200000', Category: 'hoa-cuc', PriceOld: '', Images: '/BoHoaCuc/12498_luxury-vase.jpg' },
        { Name: 'Luxury vase 8', Price: '2700000', Category: 'hoa-cuc', PriceOld: '2772000', Images: '/BoHoaCuc/12498_luxury-vase.jpg' },
        { Name: 'Luxury vase 7', Price: '2200000', Category: 'hoa-cuc', PriceOld: '2772000', Images: '/BoHoaCuc/12496_luxury-vase.jpg' },
        { Name: 'Ngày tươi vui', Price: '600000', Category: 'hoa-cuc', PriceOld: '', Images: '/HopHoaTuoi/12485_ngay-tuoi-vui.jpg' },
        { Name: 'Ngọt ngào 1', Price: '600000', Category: 'hoa-cuc', PriceOld: '627000', Images: '/BoHoaCuc/12484_ngot-ngao.jpg' },
        { Name: 'Luxury vase 6', Price: '350000', Category: 'hoa-cuc', PriceOld: '627000', Images: '/BoHoaCuc/12483_luxury-vase.jpg' },
        { Name: 'Thênh Thang', Price: '1200000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/12488_thenh-thang.jpg' },
        { Name: 'Nguyện cầu', Price: '1500000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/12489_nguyen-cau.jpg' },
        { Name: 'Tâm Thành', Price: '1500000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/12500_tam-thanh.jpg' },
        { Name: 'Mắt biếc', Category: 'hoa-cuc', Price: '1200000', PriceOld: '', Images: '/BoHoa/12492_mat-biec.jpg' },
        { Name: 'Hạnh phúc mới', Category: 'hoa-cuc', Price: '1000000', PriceOld: '', Images: '/BoHoa/12597_hanh-phuc-moi.jpg' },
        { Name: 'My sky', Category: 'hoa-cuc', Price: '700000', PriceOld: '1250000', Images: '/BoHoa/12592_my-sky.jpg' },
        { Name: 'Bó cúc tana', Category: 'hoa-cuc', Price: '1000000', PriceOld: '', Images: '/BoHoa/12591_bo-cuc-tana.jpg' },
        { Name: 'Love forever', Category: 'hoa-cuc', Price: '850000', PriceOld: '', Images: '/BoHoaCuc/12602_love-forever.jpg' },
        { Name: 'Luxury vase 20', Category: 'hoa-cuc', Price: '4000000', PriceOld: '', Images: '/BoHoaCuc/12672_luxury-vase.jpg' },
        { Name: 'Luxury vase 19', Category: 'hoa-cuc', Price: '4500000', PriceOld: '', Images: '/BoHoaCuc/12671_luxury-vase.jpg' },
        { Name: 'Glass vase 4', Category: 'hoa-cuc', Price: '1100000', PriceOld: '1150000', Images: '/HoaChucMung/12608_glass-vase.jpg' },
        { Name: 'Lệ dâng', Price: '1800000', Category: 'hoa-cuc', PriceOld: '1935185', Images: '/HoaChiaBuon/12673_le-dang.jpg' },
        { Name: 'Hư không 2', Price: '2000000', Category: 'hoa-cuc', PriceOld: '2240741', Images: '/HoaChiaBuon/12629_hu-khong.jpg' },
        { Name: 'Kiếp nhân sinh 2', Price: '2000000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/12628_kiep-nhan-sinh.jpg' },
        { Name: 'Lời tạm biệt', Price: '650000', Category: 'hoa-cuc', PriceOld: '700000', Images: '/HoaChiaBuon/12722_loi-tam-biet.jpg' },
        { Name: 'Deep condolence 2', Price: '4000000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/12720_deep-condolence.jpg' },
        { Name: 'Ly Biệt', Price: '1500000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/13104_ly-biet.jpg' },
        { Name: 'Xa cách', Price: '1500000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/13102_xa-cach.jpg' },
        { Name: 'Vô Định', Price: '1600000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/13101_vo-dinh.jpg' },
        { Name: 'Tâm nguyện', Price: '1000000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/13099_tam-nguyen.jpg' },
        { Name: 'Kệ Chia Buồn 02', Price: '650000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/13144_ke-chia-buon.jpg' },
        { Name: 'Kệ Chia Buồn 03', Price: '600000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/13145_ke-chia-buon.jpg' },
        { Name: 'Kệ Chia Buồn 04', Price: '850000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/13146_ke-chia-buon.jpg' },
        { Name: 'Kệ Chúc Mừng 07', Price: '1000000', Category: 'hoa-cuc', PriceOld: '1140000', Images: '/BoHoaCuc/13155_ke-chuc-mung.jpg' },
        { Name: 'Ngày tươi sáng', Price: '1000000', Category: 'hoa-cuc', PriceOld: '1100000', Images: '/HopHoaTuoi/13180_ngay-tuoi-sang.jpg' },
        { Name: 'Trong xanh', Price: '1000000', Category: 'hoa-cuc', PriceOld: '1150000', Images: '/HopHoaTuoi/13221_trong-xanh.jpg' },
        { Name: 'Amour', Price: '1000000', Category: 'hoa-cuc', PriceOld: '1100000', Images: '/HopHoaTuoi/13222_amour.jpg' },
        { Name: 'Bên em ngày nắng', Price: '1200000', Category: 'hoa-cuc', PriceOld: '', Images: '/BoHoaCuc/13304_ben-em-ngay-nang.jpg' },
        { Name: 'Premium vase 1', Price: '2800000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChucMung/13306_premium-vase.jpg' },
        { Name: 'Premium vase 2', Price: '3500000', Category: 'hoa-cuc', PriceOld: '', Images: '/BoHoaCuc/13307_premium-vase.jpg' },
        { Name: 'Premium vase 3', Price: '3000000', Category: 'hoa-cuc', PriceOld: '', Images: '/BoHoaCuc/13308_premium-vase.jpg' },
        { Name: 'Premium vase 4', Price: '6000000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChucMung/13309_premium-vase.jpg' },
        { Name: 'Luxury vase 21', Price: '1800000', Category: 'hoa-cuc', PriceOld: '2000000', Images: '/BoHoaCuc/13311_luxury-vase.jpg' },
        { Name: 'Luxury vase 22', Category: 'hoa-cuc', Price: '1500000', PriceOld: '1700000', Images: '/HoaChucMung/13312_luxury-vase.jpg' },
        { Name: 'Condolence 1', Price: '2500000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/13319_condolence.png' },
        { Name: 'Condolence 2', Price: '1500000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/13322_condolence.jpg' },
        { Name: 'Condolence 3', Price: '2000000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/13318_condolence.jpg' },
        { Name: 'Condolence 4', Price: '1400000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/13316_condolence.jpg' },
        { Name: 'Condolence 5', Price: '1600000', Category: 'hoa-cuc', PriceOld: '', Images: '/HoaChiaBuon/13315_condolence.jpg' },
        { Name: 'Tuổi hồng', Price: '450000', Category: 'hoa-cuc', PriceOld: '500000', Images: '/GioHoaTuoi/13301_tuoi-hong.jpg' },
        { Name: 'My Tana', Category: 'hoa-cuc', Price: '600000', PriceOld: '', Images: '/BoHoa/13291_my-tana.jpg' },
        { Name: 'Hoa tặng sự kiện 14(+20 bó)', Price: '250000', Category: 'hoa-cuc', PriceOld: '', Images: '/BoHoaCuc/13288_hoa-tang-su-kien--bo.jpg' },
        { Name: 'Hoa tặng sự kiện 12(+20 bó)', Price: '200000', Category: 'hoa-cuc', PriceOld: '', Images: '/BoHoaCuc/13285_hoa-tang-su-kien--bo.jpg' },
        { Name: 'Hoa tặng sự kiện 10(+20 bó)', Price: '280000', Category: 'hoa-cuc', PriceOld: '', Images: '/BoHoaCuc/13283_hoa-tang-su-kien--bo.jpg' },
        { Name: 'Niềm hạnh phúc', Price: '600000', Category: 'hoa-cuc', PriceOld: '', Images: '/BoHoaCuc/13366_niem-hanh-phuc.png' },
        { Name: 'Congratulations 6', Price: '2900000', Category: 'hoa-cuc', PriceOld: '', Images: '/BoHoaCuc/13412_congratulations.jpg' },
        // Xong
        // Bó Hoa Hồng
        { Name: 'Everyday with you', Category: 'hoa-hong', Price: '200000', PriceOld: '250000', Images: '/BoHoa/13111_everyday-with-you.jpg' },
        { Name: 'Dating', Category: 'hoa-hong', Price: '250000', PriceOld: '', Images: '/BoHoa/14270_dating.jpg' },
        { Name: 'Nồng nàn', Category: 'hoa-hong', Price: '300000', PriceOld: '400000', Images: '/BoHoa/14470_nong-nan.jpg' },
        { Name: 'Điều thân thương', Category: 'hoa-hong', Price: '150000', PriceOld: '', Images: '/BoHoa/13234_dieu-than-thuong.jpg' },
        { Name: 'Say you do', Category: 'hoa-hong', Price: '300000', PriceOld: '400000', Images: '/BoHoa/14339_say-you-do.jpg' },
        { Name: 'Đắm say 2', Category: 'hoa-hong', Price: '250000', PriceOld: '', Images: '/BoHoa/14432_dam-say.jpg' },
        { Name: 'Adore', Category: 'hoa-hong', Price: '400000', PriceOld: '', Images: '/BoHoa/14645_adore.jpg' },
        { Name: 'Eternal Bloom', Category: 'hoa-hong', Price: '500000', PriceOld: '', Images: '/BoHoa/14649_eternal-bloom.jpg' },
        { Name: 'Proud of you', Category: 'hoa-hong', Price: '700000', PriceOld: '850000', Images: '/BoHoa/13246_proud-of-you.jpg' },
        { Name: 'Blink', Category: 'hoa-hong', Price: '400000', PriceOld: '', Images: '/BoHoa/14356_blink.jpg' },
        { Name: 'Thuần khiết', Category: 'hoa-hong', Price: '550000', PriceOld: '650000', Images: '/BoHoa/14689_thuan-khiet.jpg' },
        { Name: 'How much I love you', Category: 'hoa-hong', Price: '850000', PriceOld: '', Images: '/BoHoa/13112_how-much-i-love-you.jpg' },
        { Name: 'Tiamo', Category: 'hoa-hong', Price: '900000', PriceOld: '', Images: '/BoHoa/13112_how-much-i-love-you.jpg' },
        { Name: 'Only rose 15', Category: 'hoa-hong', Price: '550000', PriceOld: '750000', Images: '/BoHoa/8382_only-rose.jpg' },
        { Name: 'Red elegance', Category: 'hoa-hong', Price: '850000', PriceOld: '900000', Images: '/BoHoa/13898_red-elegance.jpg' },
        { Name: 'Yêu kiều', Category: 'hoa-hong', Price: '1100000', PriceOld: '1200000', Images: '/BoHoa/12624_yeu-kieu.jpg' },
        { Name: 'Mắt biếc', Category: 'hoa-hong', Price: '1200000', PriceOld: '', Images: '/BoHoa/12492_mat-biec.jpg' },
        { Name: 'Darling Blooms', Category: 'hoa-hong', Price: '400000', PriceOld: '', Images: '/BoHoa/14651_darling-blooms.jpg' },
        { Name: 'Cơn gió lạ', Category: 'hoa-hong', Price: '1000000', PriceOld: '1100000', Images: '/BoHoa/12525_con-gio-la.jpg' },
        { Name: 'My universe', Category: 'hoa-hong', Price: '2000000', PriceOld: '2250000', Images: '/BoHoa/13899_my-universe.jpg' },
        { Name: 'Bầu trời trong xanh', Category: 'hoa-hong', Price: '1500000', PriceOld: '', Images: '/BoHoa/14435_bau-troi-trong-xanh.jpg' },
        { Name: 'Hạnh phúc ngọt ngào', Category: 'hoa-hong', Price: '1500000', PriceOld: '1600000', Images: '/BoHoa/3205_hanh-phuc-ngot-ngao.jpg' },
        { Name: 'Pink Lady', Category: 'hoa-hong', Price: '2400000', PriceOld: '', Images: '/BoHoa/7240_pink-lady.png' },
        { Name: 'A Thousand roses', Category: 'hoa-hong', Price: '25000000', PriceOld: '28000000', Images: '/BoHoa/12994_a-thousand-roses.jpg' },
        { Name: 'Morning kiss', Category: 'hoa-hong', Price: '1500000', PriceOld: '', Images: '/BoHoa/14427_morning-kiss.jpg' },
        { Name: 'Yêu xa', Category: 'hoa-hong', Price: '200000', PriceOld: '250000', Images: '/BoHoa/3199_yeu-xa.jpg' },
        { Name: 'Mơ mộng', Category: 'hoa-hong', Price: '350000', PriceOld: '', Images: '/BoHoa/14501_mo-mong.jpg' },
        { Name: 'Gặp gỡ', Category: 'hoa-hong', Price: '300000', PriceOld: '', Images: '/BoHoa/5598_gap-go.jpg' },
        { Name: 'Người thương', Category: 'hoa-hong', Price: '350000', PriceOld: '400000', Images: '/BoHoa/13372_nguoi-thuong.jpg' },
        { Name: 'Ngọt ngào như hoa', Category: 'hoa-hong', Price: '350000', PriceOld: '450000', Images: '/BoHoa/14338_ngot-ngao-nhu-hoa.jpg' },
        { Name: 'Remember you', Category: 'hoa-hong', Price: '500000', PriceOld: '', Images: '/BoHoa/14271_remember-you.jpg' },
        { Name: 'Cầu hôn', Category: 'hoa-hong', Price: '650000', PriceOld: '750000', Images: '/BoHoa/13251_cau-hon.jpg' },
        { Name: 'Sắc cam rực rỡ', Category: 'hoa-hong', Price: '300000', PriceOld: '', Images: '/BoHoa/11041_sac-cam-ruc-ro.jpg' },
        { Name: 'Yêu em', Category: 'hoa-hong', Price: '500000', PriceOld: '', Images: '/BoHoa/8381_yeu-em.jpg' },
        { Name: 'Mong chờ', Category: 'hoa-hong', Price: '300000', PriceOld: '350000', Images: '/BoHoa/9445_mong-cho.jpg' },
        { Name: 'Nồng say', Category: 'hoa-hong', Price: '950000', PriceOld: '1100000', Images: '/BoHoa/12588_nong-say.jpg' },
        { Name: 'Rạng ngời 2', Category: 'hoa-hong', Price: '450000', PriceOld: '', Images: '/BoHoa/13083_rang-ngoi.jpg' },
        { Name: 'Only rose premium 1', Category: 'hoa-hong', Price: '650000', PriceOld: '850000', Images: '/BoHoa/13254_only-rose-premium.jpg' },
        { Name: 'Lady Queen', Category: 'hoa-hong', Price: '1000000', PriceOld: '', Images: '/BoHoa/13035_lady-queen.jpg' },
        { Name: 'Vẫn mãi yêu', Category: 'hoa-hong', Price: '1000000', PriceOld: '', Images: '/BoHoa/8384_van-mai-yeu.jpg' },
        { Name: 'Hẹn hò', Category: 'hoa-hong', Price: '750000', PriceOld: '', Images: '/BoHoa/11589_hen-ho.jpg' },
        { Name: 'Nắng ban mai', Category: 'hoa-hong', Price: '1500000', PriceOld: '1600000', Images: '/BoHoa/14353_nang-ban-mai.jpg' },
        { Name: 'Tình Yêu Vĩnh Cửu 2', Category: 'hoa-hong', Price: '2500000', PriceOld: '2700000', Images: '/BoHoa/6020_tinh-yeu-vinh-cuu.jpg' },
        { Name: 'Only rose 3', Category: 'hoa-hong', Price: '550000', PriceOld: '750000', Images: '/BoHoa/12985_only-rose.jpg' },
        { Name: 'Bó hoa cẩm chướng đỏ', Category: 'hoa-hong', Price: '500000', PriceOld: '', Images: '/BoHoa/8756_bo-hoa-cam-chuong-do.jpg' },
        { Name: 'Trọn đời có nhau', Category: 'hoa-hong', Price: '1350000', PriceOld: '1400000', Images: '/BoHoa/13383_tron-doi-co-nhau.jpg' },
        { Name: 'Romantic Date', Category: 'hoa-hong', Price: '200000', PriceOld: '', Images: '/BoHoa/11585_romantic-date.jpg' },
        { Name: 'Take My Hand', Category: 'hoa-hong', Price: '150000', PriceOld: '', Images: '/BoHoa/9440_take-my-hand.jpg' },
        { Name: 'Trong ánh nắng', Category: 'hoa-hong', Price: '300000', PriceOld: '', Images: '/BoHoa/14433_trong-anh-nang.jpg' },
        { Name: 'Romance 3', Category: 'hoa-hong', Price: '800000', PriceOld: '850000', Images: '/BoHoa/12490_romance.jpg' },
        { Name: 'Hẹn em', Category: 'hoa-hong', Price: '650000', PriceOld: '', Images: '/BoHoa/13257_hen-em.jpg' },
        { Name: 'Sasa roses', Category: 'hoa-hong', Price: '950000', PriceOld: '1400000', Images: '/BoHoa/8284_sasa-roses.png' },
        { Name: 'Trăm năm tình yêu', Category: 'hoa-hong', Price: '2800000', PriceOld: '', Images: '/BoHoa/13278_tram-nam-tinh-yeu.jpg' },
        { Name: 'Rung động', Category: 'hoa-hong', Price: '850000', PriceOld: '', Images: '/BoHoa/9540_rung-dong.png' },
        { Name: 'Freshness', Category: 'hoa-hong', Price: '800000', PriceOld: '', Images: '/BoHoa/10261_freshness.png' },
        { Name: 'Think about you', Category: 'hoa-hong', Price: '280000', PriceOld: '350000', Images: '/BoHoa/13085_think-about-you.jpg' },
        { Name: 'Điệu valse mùa xuân', Category: 'hoa-hong', Price: '550000', PriceOld: '', Images: '/BoHoa/13231_dieu-valse-mua-xuan.jpg' },
        { Name: 'Closer', Category: 'hoa-hong', Price: '650000', PriceOld: '', Images: '/BoHoa/8032_closer.png' },
        { Name: 'Tỏa sáng 2', Category: 'hoa-hong', Price: '550000', PriceOld: '600000', Images: '/BoHoa/13286_toa-sang.jpg' },
        { Name: 'Sự Quan Tâm 2', Category: 'hoa-hong', Price: '700000', PriceOld: '750000', Images: '/BoHoa/6032_su-quan-tam.png' },
        { Name: 'All of love', Category: 'hoa-hong', Price: '550000', PriceOld: '875000', Images: '/BoHoa/8524_all-of-love.jpg' },
        { Name: 'Điều bất ngờ', Category: 'hoa-hong', Price: '300000', PriceOld: '350000', Images: '/BoHoa/11740_dieu-bat-ngo.png' },
        { Name: 'Niềm vui nhỏ 2', Category: 'hoa-hong', Price: '550000', PriceOld: '', Images: '/BoHoa/12600_niem-vui-nho.jpg' },
        { Name: 'Me before you', Category: 'hoa-hong', Price: '550000', PriceOld: '750000', Images: '/BoHoa/4618_me-before-you.jpg' },
        { Name: 'Ngày mới ngọt ngào', Category: 'hoa-hong', Price: '550000', PriceOld: '', Images: '/BoHoa/13282_ngay-moi-ngot-ngao.jpg' },
        { Name: 'Khát Vọng', Category: 'hoa-hong', Price: '800000', PriceOld: '1000000', Images: '/BoHoa/2427_khat-vong.jpg' },
        { Name: 'Ohara', Category: 'hoa-hong', Price: '950000', PriceOld: '1045000', Images: '/BoHoa/11040_ohara.jpg' },
        { Name: 'Real Love', Category: 'hoa-hong', Price: '1000000', PriceOld: '1500000', Images: '/BoHoa/5045_real-love.png' },
        { Name: 'Only rose 8', Category: 'hoa-hong', Price: '1000000', PriceOld: '1250000', Images: '/BoHoa/8383_only-rose.jpg' },
        { Name: 'Remember', Category: 'hoa-hong', Price: '680000', PriceOld: '', Images: '/BoHoa/8116_remember.png' },
        { Name: 'Món quà hạnh phúc', Category: 'hoa-hong', Price: '570000', PriceOld: '', Images: '/BoHoa/13906_mon-qua-hanh-phuc.jpg' },
        { Name: 'Sự Quan Tâm', Category: 'hoa-hong', Price: '700000', PriceOld: '750000', Images: '/BoHoa/5423_su-quan-tam.png' },
        { Name: 'Tình', Category: 'hoa-hong', Price: '450000', PriceOld: '500000', Images: '/BoHoa/10704_tinh.jpg' },
        { Name: 'Only rose 20', Category: 'hoa-hong', Price: '550000', PriceOld: '750000', Images: '/BoHoa/12982_only-rose.jpg' },
        { Name: 'Ngày bên em', Category: 'hoa-hong', Price: '1000000', PriceOld: '', Images: '/BoHoa/12598_ngay-ben-em.jpg' },
        { Name: 'Simple', Category: 'hoa-hong', Price: '350000', PriceOld: '', Images: '/BoHoa/11443_simple.jpg' },
        { Name: 'Keep loving', Category: 'hoa-hong', Price: '1500000', PriceOld: '1600000', Images: '/BoHoa/14423_keep-loving.jpg' },
        { Name: 'Giản dị', Category: 'hoa-hong', Price: '300000', PriceOld: '350000', Images: '/BoHoa/9444_gian-di.jpg' },
        { Name: 'Only rose 12', Category: 'hoa-hong', Price: '1000000', PriceOld: '1250000', Images: '/BoHoa/8382_only-rose.jpg' },
        { Name: 'Hẹn hò 2', Category: 'hoa-hong', Price: '500000', PriceOld: '', Images: '/BoHoa/13284_hen-ho.jpg' },
        { Name: 'Only rose 14', Category: 'hoa-hong', Price: '1000000', PriceOld: '1250000', Images: '/BoHoa/12581_only-rose.jpg' },
        { Name: 'My chance', Category: 'hoa-hong', Price: '350000', PriceOld: '400000', Images: '/BoHoa/13381_my-chance.jpg' },
        { Name: 'Encounter', Category: 'hoa-hong', Price: '320000', PriceOld: '', Images: '/BoHoa/13374_encounter.jpg' },
        { Name: 'Sweet moment', Category: 'hoa-hong', Price: '500000', PriceOld: '', Images: '/BoHoa/13250_sweet-moment.jpg' },
        { Name: 'Rạng ngời', Category: 'hoa-hong', Price: '350000', PriceOld: '400000', Images: '/BoHoa/12584_rang-ngoi.jpg' },
        { Name: 'Only rose 19', Category: 'hoa-hong', Price: '550000', PriceOld: '750000', Images: '/BoHoa/12582_only-rose.jpg' },
        { Name: 'Nắng sớm ngọt ngào', Category: 'hoa-hong', Price: '600000', PriceOld: '', Images: '/BoHoa/12590_nang-som-ngot-ngao.jpg' },
        { Name: 'Back to love', Category: 'hoa-hong', Price: '300000', PriceOld: '400000', Images: '/BoHoa/12596_back-to-love.jpg' },
        { Name: 'My sky', Category: 'hoa-hong', Price: '700000', PriceOld: '1250000', Images: '/BoHoa/12592_my-sky.jpg' },
        { Name: 'Make me smile', Category: 'hoa-hong', Price: '750000', PriceOld: '1000000', Images: '/BoHoa/13255_make-me-smile.jpg' },
        { Name: 'Ngày có em', Category: 'hoa-hong', Price: '1000000', PriceOld: '1100000', Images: '/BoHoa/13233_ngay-co-em.jpg' },
        { Name: 'Only rose 22', Category: 'hoa-hong', Price: '550000', PriceOld: '750000', Images: '/BoHoa/12984_only-rose.jpg' },
        { Name: 'Only You', Category: 'hoa-hong', Price: '250000', PriceOld: '280000', Images: '/BoHoa/4840_only-you.jpg' },
        { Name: 'Kem hoa', Category: 'hoa-hong', Price: '280000', PriceOld: '', Images: '/BoHoa/11739_kem-hoa.png' },
        { Name: 'Sương mai', Category: 'hoa-hong', Price: '1100000', PriceOld: '1200000', Images: '/BoHoa/9372_suong-mai.jpg' },
        { Name: 'Keep you in my heart', Category: 'hoa-hong', Price: '2800000', PriceOld: '', Images: '/BoHoa/13388_keep-you-in-my-heart.jpg' },
        { Name: 'Hoa yêu thương', Category: 'hoa-hong', Price: '500000', PriceOld: '', Images: '/BoHoa/13110_hoa-yeu-thuong.jpg' },
        { Name: 'Candy roses', Category: 'hoa-hong', Price: '600000', PriceOld: '750000', Images: '/BoHoa/8287_candy-roses.jpg' },
        { Name: 'Vì em là nắng', Category: 'hoa-hong', Price: '1250000', PriceOld: '1300000', Images: '/BoHoa/11728_vi-em-la-nang.jpg' },
        { Name: 'Only rose premium 3', Category: 'hoa-hong', Price: '650000', PriceOld: '850000', Images: '/BoHoa/13277_only-rose-premium.jpg' },
        { Name: 'Congrats', Category: 'hoa-hong', Price: '500000', PriceOld: '', Images: '/BoHoa/13237_congrats.jpg' },
        { Name: 'Bó hồng shimmer', Category: 'hoa-hong', Price: '500000', PriceOld: '', Images: '/BoHoa/14355_bo-hong-shimmer.jpg' },
        { Name: 'Only rose premium 4', Category: 'hoa-hong', Price: '650000', PriceOld: '850000', Images: '/BoHoa/13279_only-rose-premium.jpg' },
        { Name: 'My Girl', Category: 'hoa-hong', Price: '550000', PriceOld: '600000', Images: '/BoHoa/12666_my-girl.jpg' },
        { Name: 'My muse', Category: 'hoa-hong', Price: '500000', PriceOld: '550000', Images: '/BoHoa/12983_my-muse.jpg' },
        { Name: 'Món quà tặng em', Category: 'hoa-hong', Price: '500000', PriceOld: '550000', Images: '/BoHoa/12523_mon-qua-tang-em.jpg' },
        { Name: 'Vì yêu là nhớ', Category: 'hoa-hong', Price: '2800000', PriceOld: '3000000', Images: '/BoHoa/13384_vi-yeu-la-nho.jpg' },
        { Name: 'Niềm tin 2', Category: 'hoa-hong', Price: '350000', PriceOld: '', Images: '/BoHoa/12589_niem-tin.jpg' },
        { Name: 'Thổn thức', Category: 'hoa-hong', Price: '650000', PriceOld: '', Images: '/BoHoa/13238_thon-thuc.jpg' },
        { Name: 'Shyly', Category: 'hoa-hong', Price: '750000', PriceOld: '', Images: '/BoHoa/13236_shyly.jpg' },
        { Name: 'Tango', Category: 'hoa-hong', Price: '600000', PriceOld: '', Images: '/BoHoa/13245_tango.jpg' },
        { Name: 'Hẹn ước', Category: 'hoa-hong', Price: '500000', PriceOld: '550000', Images: '/BoHoa/13139_hen-uoc.jpg' },
        { Name: 'Only rose premium 6', Category: 'hoa-hong', Price: '650000', PriceOld: '850000', Images: '/BoHoa/13281_only-rose-premium.jpg' },
        { Name: 'Ngày êm đềm', Category: 'hoa-hong', Price: '500000', PriceOld: '550000', Images: '/BoHoa/13382_ngay-em-dem.jpg' },
        { Name: 'Tươi tắn', Category: 'hoa-hong', Price: '250000', PriceOld: '', Images: '/BoHoa/12587_tuoi-tan.jpg' },
        { Name: 'Yêu thương 2', Category: 'hoa-hong', Price: '1000000', PriceOld: '1050000', Images: '/BoHoa/2450_yeu-thuong.jpg' },
        { Name: 'Only rose 27', Category: 'hoa-hong', Price: '1000000', PriceOld: '1750000', Images: '/BoHoa/12992_only-rose.jpg' },
        { Name: 'Beautiful you', Category: 'hoa-hong', Price: '1200000', PriceOld: '1475000', Images: '/BoHoa/11442_beautiful-you.jpg' },
        { Name: 'Sweetie', Category: 'hoa-hong', Price: '350000', PriceOld: '', Images: '/BoHoa/11444_sweetie.jpg' },
        { Name: 'Chuyện yêu', Category: 'hoa-hong', Price: '600000', PriceOld: '750000', Images: '/BoHoa/13376_chuyen-yeu.jpg' },
        { Name: 'Mùa hồng', Category: 'hoa-hong', Price: '1000000', PriceOld: '', Images: '/BoHoa/6830_mua-hong.png' },
        { Name: 'Only rose premium 2', Category: 'hoa-hong', Price: '650000', PriceOld: '1750000', Images: '/BoHoa/13276_only-rose-premium.jpg' },
        { Name: 'Destiny date', Category: 'hoa-hong', Price: '2800000', PriceOld: '3500000', Images: '/BoHoa/13375_destiny-date.jpg' },
        { Name: 'Dear My Love', Category: 'hoa-hong', Price: '450000', PriceOld: '500000', Images: '/BoHoa/12665_dear-my-love.jpg' },
        { Name: 'My Queen', Category: 'hoa-hong', Price: '600000', PriceOld: '650000', Images: '/BoHoa/11588_my-queen.png' },
        { Name: 'My life', Category: 'hoa-hong', Price: '360000', PriceOld: '', Images: '/BoHoa/13447_my-life.png' },
        { Name: 'Telling you', Category: 'hoa-hong', Price: '400000', PriceOld: '440000', Images: '/BoHoa/13248_telling-you.jpg' },
        { Name: 'Vui tươi', Category: 'hoa-hong', Price: '300000', PriceOld: '', Images: '/BoHoa/13445_vui-tuoi.jpg' },
        { Name: 'Only rose 11', Category: 'hoa-hong', Price: '1000000', PriceOld: '1250000', Images: '/BoHoa/8386_only-rose.jpg' },
        { Name: 'Tình hồng', Category: 'hoa-hong', Price: '300000', PriceOld: '', Images: '/BoHoa/13442_tinh-hong.jpg' },
        { Name: 'Mây hồng', Category: 'hoa-hong', Price: '450000', PriceOld: '', Images: '/BoHoa/9390_may-hong.jpg' },
        { Name: 'Yêu Xa 2', Category: 'hoa-hong', Price: '500000', PriceOld: '550000', Images: '/BoHoa/5256_yeu-xa.png' },
        { Name: 'Điều ước', Category: 'hoa-hong', Price: '360000', PriceOld: '', Images: '/BoHoa/13436_dieu-uoc.jpg' },
        { Name: 'Áng mây hồng', Category: 'hoa-hong', Price: '750000', PriceOld: '1000000', Images: '/BoHoa/13256_ang-may-hong.jpg' },
        { Name: 'Sớm mai 2', Category: 'hoa-hong', Price: '500000', PriceOld: '550000', Images: '/BoHoa/13380_som-mai.jpg' },
        { Name: 'Only rose 6', Category: 'hoa-hong', Price: '550000', PriceOld: '750000', Images: '/BoHoa/8387_only-rose.jpg' },
        { Name: 'My everything', Category: 'hoa-hong', Price: '750000', PriceOld: '1000000', Images: '/BoHoa/4734_my-everything.jpg' },
        { Name: 'Dynamic', Category: 'hoa-hong', Price: '550000', PriceOld: '875000', Images: '/BoHoa/13082_dynamic.jpg' },
        { Name: 'Phút giây tỏa sáng', Category: 'hoa-hong', Price: '200000', PriceOld: '', Images: '/BoHoa/13253_phut-giay-toa-sang.jpg' },
        { Name: 'Say nắng', Category: 'hoa-hong', Price: '335000', PriceOld: '', Images: '/BoHoa/13446_say-nang.png' },
        { Name: 'Chan hòa', Category: 'hoa-hong', Price: '500000', PriceOld: '', Images: '/BoHoa/13907_chan-hoa.jpg' },
        { Name: 'Hạnh phúc mới', Category: 'hoa-hong', Price: '1000000', PriceOld: '', Images: '/BoHoa/12597_hanh-phuc-moi.jpg' },
        { Name: 'For you 2', Category: 'hoa-hong', Price: '500000', PriceOld: '600000', Images: '/BoHoa/13972_for-you.jpg' },
        { Name: 'Bối rối', Category: 'hoa-hong', Price: '850000', PriceOld: '', Images: '/BoHoa/13230_boi-roi.jpg' },
        { Name: 'Purple Love', Category: 'hoa-hong', Price: '2800000', PriceOld: '3000000', Images: '/BoHoa/5384_purple-love.jpg' },
        { Name: 'Có em', Category: 'hoa-hong', Price: '300000', PriceOld: '330000', Images: '/BoHoa/13084_co-em.jpg' },
        { Name: 'White roses', Category: 'hoa-hong', Price: '350000', PriceOld: '450000', Images: '/BoHoa/10258_white-roses.jpg' },
        { Name: 'The greatest thing 2', Category: 'hoa-hong', Price: '1250000', PriceOld: '1350000', Images: '/BoHoa/13259_the-greatest-thing.jpg' },
        { Name: 'Điều dễ thương', Category: 'hoa-hong', Price: '400000', PriceOld: '', Images: '/BoHoa/13441_dieu-de-thuong.jpg' },
        { Name: 'Black pink', Category: 'hoa-hong', Price: '400000', PriceOld: '', Images: '/BoHoa/14386_black-pink.jpg' },
        { Name: 'Romantic heart', Category: 'hoa-hong', Price: '1500000', PriceOld: '1600000', Images: '/BoHoa/11593_romantic-heart.jpg' },
        { Name: 'Passionate Love', Category: 'hoa-hong', Price: '1500000', PriceOld: '1600000', Images: '/BoHoa/14424_passionate-love.jpg' },
        { Name: 'Sweet heart', Category: 'hoa-hong', Price: '1400000', PriceOld: '1500000', Images: '/BoHoa/13235_sweet-heart.jpg' },
        { Name: 'Dành cho em', Category: 'hoa-hong', Price: '1500000', PriceOld: '', Images: '/BoHoa/14422_danh-cho-em.jpg' },
        { Name: 'Bó cẩm chướng hồng', Category: 'hoa-hong', Price: '500000', PriceOld: '', Images: '/BoHoa/11586_bo-cam-chuong-hong.jpg' },
        { Name: 'Chỉ mình em', Category: 'hoa-hong', Price: '300000', PriceOld: '350000', Images: '/BoHoa/12585_chi-minh-em.jpg' },
        { Name: 'Nắng hồng 2', Category: 'hoa-hong', Price: '250000', PriceOld: '', Images: '/BoHoa/8283_nang-hong.jpg' },
        { Name: 'Sapphire Sky', Category: 'hoa-hong', Price: '450000', PriceOld: '', Images: '/BoHoa/14465_sapphire-sky.jpg' },
        { Name: 'Tím hồng dịu dàng', Category: 'hoa-hong', Price: '450000', PriceOld: '', Images: '/BoHoa/14466_tim-hong-diu-dang.jpg' },
        { Name: 'Roseberry', Category: 'hoa-hong', Price: '300000', PriceOld: '330000', Images: '/BoHoa/14467_roseberry.jpg' },
        { Name: 'Tình đầu thơ ngây', Category: 'hoa-hong', Price: '250000', PriceOld: '275000', Images: '/BoHoa/13262_tinh-dau-tho-ngay.jpg' },
        { Name: 'Hương sắc mộng mơ', Category: 'hoa-hong', Price: '650000', PriceOld: '', Images: '/BoHoa/14469_huong-sac-mong-mo.jpg' },
        { Name: 'Violet blush', Category: 'hoa-hong', Price: '300000', PriceOld: '330000', Images: '/BoHoa/14468_violet-blush.jpg' },
        { Name: 'Chuyện của nắng', Category: 'hoa-hong', Price: '750000', PriceOld: '800000', Images: '/BoHoa/13258_chuyen-cua-nang.jpg' },
        { Name: 'Congrats Blossom', Category: 'hoa-hong', Price: '600000', PriceOld: '', Images: '/BoHoa/14648_congrats-blossom.jpg' },
        { Name: 'Cheers', Category: 'hoa-hong', Price: '700000', PriceOld: '', Images: '/BoHoa/14647_cheers.jpg' },
        { Name: 'Thời khắc rực rỡ', Category: 'hoa-hong', Price: '550000', PriceOld: '600000', Images: '/BoHoa/14646_thoi-khac-ruc-ro.jpg' },
        { Name: 'Lovely Morning', Category: 'hoa-hong', Price: '700000', PriceOld: '', Images: '/BoHoa/11439_lovely-morning.jpg' },
        { Name: 'Tuổi 18', Category: 'hoa-hong', Price: '550000', PriceOld: '650000', Images: '/BoHoa/13109_tuoi.jpg' },
        { Name: 'Surround You', Category: 'hoa-hong', Price: '500000', PriceOld: '550000', Images: '/BoHoa/12721_surround-you.jpg' },
        { Name: 'Ước mơ xanh', Category: 'hoa-hong', Price: '700000', PriceOld: '', Images: '/BoHoa/13247_uoc-mo-xanh.jpg' },
        // Xong
        // Hoa Hướng dương
        { Name: 'Ánh Dương', Category: 'hoa-huong-duong', Price: '150000', PriceOld: '165000', Images: '/BoHoa/2065_anh-duong.jpg' },
        { Name: 'Ấm áp', Category: 'hoa-huong-duong', Price: '250000', PriceOld: '', Images: '/BoHoa/9443_am-ap.jpg' },
        { Name: 'Tươi vui', Category: 'hoa-huong-duong', Price: '500000', PriceOld: '', Images: '/BoHoa/11867_tuoi-vui.jpg' },
        { Name: 'Tỏa Nắng', Category: 'hoa-huong-duong', Price: '300000', PriceOld: '400000', Images: '/BoHoa/2507_toa-nang.jpg' },
        { Name: 'Nắng Bình Minh', Category: 'hoa-huong-duong', Price: '1000000', PriceOld: '1100000', Images: '/BoHoa/11592_nang-binh-minh.jpg' },
        { Name: 'Ao ước', Price: '400000', Category: 'hoa-huong-duong', PriceOld: '450000', Images: '/HopHoaTuoi/11614_ao-uoc.jpg' },
        { Name: 'Sun flower', Price: '600000', Category: 'hoa-huong-duong', PriceOld: '720000', Images: '/HopHoaTuoi/8040_sun-flower.png' },
        { Name: 'Hy Vọng', Price: '650000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/HopHoaTuoi/5129_hy-vong.jpg' },
        { Name: 'Niềm Tin', Price: '700000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/HopHoaTuoi/5201_niem-tin.jpg' },
        { Name: 'Ngàn ánh dương', Price: '500000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/GioHoaTuoi/11607_ngan-anh-duong.png' },
        { Name: 'Thành Đạt', Price: '1200000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/HoaChucMung/5263_thanh-dat.jpg' },
        { Name: 'Vững bền', Price: '1320000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/BoHoaHuongDuong/5774_vung-ben.jpg' },
        { Name: 'Success Flowers', Price: '1320000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/BoHoaHuongDuong/5136_success-flowers.jpg' },
        { Name: 'Nỗ lực tiến bước', Price: '4500000', Category: 'hoa-huong-duong', PriceOld: '5000000', Images: '/HoaChucMung/11570_no-luc-tien-buoc.png' },
        { Name: 'Khai Trương Lộc Phát', Price: '2000000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/BoHoaHuongDuong/5403_khai-truong-loc-phat.jpg' },
        { Name: 'Nắng Sớm Mai', Price: '900000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/HopHoaTuoi/5282_nang-som-mai.jpg' },
        { Name: 'Paradise', Price: '900000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/GioHoaTuoi/12345_paradise.jpg' },
        { Name: 'New energy', Category: 'hoa-huong-duong', Price: '300000', PriceOld: '350000', Images: '/BoHoa/12583_new-energy.jpg' },
        { Name: 'Niềm tin 2', Category: 'hoa-huong-duong', Price: '350000', PriceOld: '', Images: '/BoHoa/12589_niem-tin.jpg' },
        { Name: 'Giấc mơ trưa', Price: '800000', Category: 'hoa-huong-duong', PriceOld: '850000', Images: '/BoHoaHuongDuong/12606_giac-mo-trua.jpg' },
        { Name: 'Yêu đời', Price: '950000', Category: 'hoa-huong-duong', PriceOld: '1000000', Images: '/GioHoaTuoi/13135_yeu-doi.jpg' },
        { Name: 'Phát Đạt 2', Price: '2700000', Category: 'hoa-huong-duong', PriceOld: '2800000', Images: '/BoHoaHuongDuong/13114_phat-dat.jpg' },
        { Name: 'Big Step', Price: '3500000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/HoaChucMung/13105_big-step.jpg' },
        { Name: 'Chân trời mới', Category: 'hoa-huong-duong', Price: '200000', PriceOld: '', Images: '/BoHoa/13261_chan-troi-moi.jpg' },
        { Name: 'Thẹn thùng', Category: 'hoa-huong-duong', Price: '650000', PriceOld: '700000', Images: '/BoHoa/13232_then-thung.jpg' },
        { Name: 'Congrats', Category: 'hoa-huong-duong', Price: '500000', PriceOld: '', Images: '/BoHoa/13237_congrats.jpg' },
        { Name: 'Congratulations 5', Price: '2700000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/HoaChucMung/13321_congratulations.png' },
        { Name: 'Sunrise', Price: '800000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/GioHoaTuoi/13297_sunrise.jpg' },
        { Name: 'Hoa tặng sự kiện 12(+20 bó)', Price: '200000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/BoHoaCuc/13285_hoa-tang-su-kien--bo.jpg' },
        { Name: 'Congrats mini size 5', Price: '1000000', Category: 'hoa-huong-duong', PriceOld: '1200000', Images: '/BoHoaHuongDuong/13401_congrats-mini-size.jpg' },
        { Name: 'Vui cười', Category: 'hoa-huong-duong', Price: '200000', PriceOld: '', Images: '/BoHoa/13443_vui-cuoi.jpg' },
        { Name: 'Freedom', Price: '550000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/GioHoaTuoi/13592_freedom.jpg' },
        { Name: 'Light in the morning', Price: '800000', Category: 'hoa-huong-duong', PriceOld: '950000', Images: '/HopHoaTuoi/13212_light-in-the-morning.jpg' },
        { Name: 'Bùng cháy 4', Price: '1100000', Category: 'hoa-huong-duong', PriceOld: '1150000', Images: '/HopHoaTuoi/13211_bung-chay.jpg' },
        { Name: 'Cutie bear and rose 7', Price: '1000000', Category: 'hoa-huong-duong', PriceOld: '1100000', Images: '/HopHoaTuoi/14183_cutie-bear-and-rose.jpg' },
        { Name: 'Tango', Category: 'hoa-huong-duong', Price: '600000', PriceOld: '', Images: '/BoHoa/13245_tango.jpg' },
        { Name: 'Sáng tươi', Category: 'hoa-huong-duong', Price: '550000', PriceOld: '', Images: '/BoHoa/13438_sang-tuoi.jpg' },
        { Name: 'Sunny hill', Price: '1300000', Category: 'hoa-huong-duong', PriceOld: '1500000', Images: '/GioHoaTuoi/13216_sunny-hill.jpg' },
        { Name: 'Gió mùa hạ', Price: '500000', Category: 'hoa-huong-duong', PriceOld: '650000', Images: '/GioHoaTuoi/13217_gio-mua-ha.jpg' },
        { Name: 'Tháng năm rực rỡ', Category: 'hoa-huong-duong', Price: '1800000', PriceOld: '2000000', Images: '/GioHoaTuoi/13063_thang-nam-ruc-ro.jpg' },
        { Name: 'My sun', Category: 'hoa-huong-duong', Price: '150000', PriceOld: '800000', Images: '/BoHoa/13385_my-sun.jpg' },
        { Name: 'Lovely Morning', Category: 'hoa-huong-duong', Price: '700000', PriceOld: '', Images: '/BoHoa/11439_lovely-morning.jpg' },
        { Name: 'Congrats mini size 7', Price: '800000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/BoHoaHuongDuong/14351_congrats-mini-size.jpg' },
        { Name: 'Tỏa nắng 3', Category: 'hoa-huong-duong', Price: '1000000', PriceOld: '', Images: '/BoHoa/14426_toa-nang.jpg' },
        { Name: 'Điều khác biệt', Category: 'hoa-huong-duong', Price: '2200000', PriceOld: '', Images: '/GioHoaTuoi/14344_dieu-khac-biet.jpg' },
        { Name: 'Vươn xa 2', Category: 'hoa-huong-duong', Price: '2000000', PriceOld: '2100000', Images: '/GioHoaTuoi/14425_vuon-xa.jpg' },
        { Name: 'Cát lộc', Price: '2400000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/BoHoaHuongDuong/14371_cat-loc.jpg' },
        { Name: 'Trong ánh nắng', Category: 'hoa-huong-duong', Price: '300000', PriceOld: '', Images: '/BoHoa/14433_trong-anh-nang.jpg' },
        { Name: 'Ngày có nắng', Category: 'hoa-huong-duong', Price: '1500000', PriceOld: '1650000', Images: '/BoHoa/14434_ngay-co-nang.jpg' },
        { Name: 'Feel good', Price: '550000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/GioHoaTuoi/14446_feel-good.jpg' },
        { Name: 'Đỏ nhiệt huyết', Price: '1600000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/HopHoaTuoi/14476_do-nhiet-huyet.jpg' },
        { Name: 'Mừng thành công', Category: 'hoa-huong-duong', Price: '300000', PriceOld: '', Images: '/BoHoa/14474_mung-thanh-cong.jpg' },
        { Name: 'Vút cao', Price: '500000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/GioHoaTuoi/14503_vut-cao.jpg' },
        { Name: 'Good vibes', Price: '750000', Category: 'hoa-huong-duong', PriceOld: '800000', Images: '/HopHoaTuoi/14502_good-vibes.jpg' },
        { Name: 'Thời khắc rực rỡ', Category: 'hoa-huong-duong', Price: '550000', PriceOld: '600000', Images: '/BoHoa/14646_thoi-khac-ruc-ro.jpg' },
        { Name: 'Bình vàng tươi 4', Price: '8500000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/BoHoaHuongDuong/14659_binh-vang-tuoi.jpg' },
        { Name: 'Victory', Price: '9000000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/BoHoaHuongDuong/14594_victory.jpg' },
        { Name: 'Cutie bear and rose 10', Price: '1100000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/HopHoaTuoi/14550_cutie-bear-and-rose.jpg' },
        { Name: 'Tấm lòng biết ơn', Price: '900000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/HopHoaTuoi/14552_tam-long-biet-on.jpg' },
        { Name: 'Hành trình rực rỡ', Price: '700000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/HopHoaTuoi/14601_hanh-trinh-ruc-ro.jpg' },
        { Name: 'Ngày tôn vinh', Price: '2000000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/HopHoaTuoi/14687_ngay-ton-vinh.jpg' },
        { Name: 'Bước ngoặt', Price: '1800000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/HopHoaTuoi/14681_buoc-ngoat.jpg' },
        { Name: 'Khúc hát thành công', Price: '750000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/HopHoaTuoi/14676_khuc-hat-thanh-cong.jpg' },
        { Name: 'Congratulations 7', Price: '23000000', Category: 'hoa-huong-duong', PriceOld: '', Images: '/BoHoaHuongDuong/14715_congratulations.jpg' },
        { Name: 'The best thing you are', Category: 'hoa-huong-duong', Price: '600000', PriceOld: '', Images: '/GioHoaTuoi/14834_the-best-thing-you-are.jpg' },
        { Name: 'Lan tỏa yêu thương', Category: 'hoa-huong-duong', Price: '550000', PriceOld: '', Images: '/GioHoaTuoi/14843_lan-toa-yeu-thuong.jpg' },
        // Xong
        // 
        // Lan hồ điệp
        { Name: 'Chậu lan trắng 1 cành 01', Price: '300000', Category: 'lan-ho-diep', PriceOld: '400000', Images: '/LanHoDiep/11124_chau-lan-trang--canh.png' },
        { Name: 'Hồ điệp vàng chậu sứ 05', Price: '550000', Category: 'lan-ho-diep', PriceOld: '700000', Images: '/LanHoDiep/14065_ho-diep-vang-chau-su.jpg' },
        { Name: 'Nghinh xuân', Price: '825000', Category: 'lan-ho-diep', PriceOld: '', Images: '/LanHoDiep/11117_nghinh-xuan.jpg' },
        { Name: 'Hồ điệp mix màu 03', Price: '925000', Category: 'lan-ho-diep', PriceOld: '1050000', Images: '/LanHoDiep/14061_ho-diep-mix-mau.jpg' },
        { Name: 'Hồ điệp vàng 3 cành', Price: '925000', Category: 'lan-ho-diep', PriceOld: '', Images: '/LanHoDiep/7180_ho-diep-vang--canh.jpg' },
        { Name: 'Phát tài', Price: '1200000', Category: 'lan-ho-diep', PriceOld: '', Images: '/LanHoDiep/7779_phat-tai.jpg' },
        { Name: 'Lan Hồ Điệp xuân mẫu 12', Price: '1575000', Category: 'lan-ho-diep', PriceOld: '1050000', Images: '/LanHoDiep/12707_lan-ho-diep-xuan-mau.jpg' },
        { Name: 'Hồ điệp tím chậu sứ 04', Price: '1950000', Category: 'lan-ho-diep', PriceOld: '2100000', Images: '/LanHoDiep/14055_ho-diep-tim-chau-su.jpg' },
        { Name: 'Hồ điệp hồng 5', Price: '1675000', Category: 'lan-ho-diep', PriceOld: '1750000', Images: '/LanHoDiep/14054_ho-diep-hong.jpg' },
        { Name: 'Hồ điệp vàng chậu sứ 02', Price: '2200000', Category: 'lan-ho-diep', PriceOld: '2800000', Images: '/LanHoDiep/13644_ho-diep-vang-chau-su.jpg' },
        { Name: 'Hồ điệp mix màu 01', Price: '3300000', Category: 'lan-ho-diep', PriceOld: '4200000', Images: '/LanHoDiep/14053_ho-diep-mix-mau.jpg' },
        { Name: 'Hồ điệp vàng chậu sứ 06', Price: '2400000', Category: 'lan-ho-diep', PriceOld: '1050000', Images: '/LanHoDiep/11116_ho-diep-vang-chau-su.jpg' },
        { Name: 'Lan hồ điệp mẫu 8', Price: '2200000', Category: 'lan-ho-diep', PriceOld: '2800000', Images: '/LanHoDiep/13594_lan-ho-diep-mau.jpg' },
        { Name: 'Hồ điệp vàng chậu sứ 04', Price: '2500000', Category: 'lan-ho-diep', PriceOld: '2800000', Images: '/LanHoDiep/14059_ho-diep-vang-chau-su.jpg' },
        { Name: 'Chậu hồ điệp tím 9 cành', Price: '2475000', Category: 'lan-ho-diep', PriceOld: '', Images: '/LanHoDiep/14792_chau-ho-diep-tim--canh.jpg' },
        { Name: 'Chậu hồ điệp trắng 10 cành', Price: '2750000', Category: 'lan-ho-diep', PriceOld: '', Images: '/LanHoDiep/14791_chau-ho-diep-trang--canh.jpg' },
        { Name: 'Chậu hồ điệp mix 7 cành', Price: '2125000', Category: 'lan-ho-diep', PriceOld: '2450000', Images: '/LanHoDiep/13976_chau-ho-diep-mix--canh.jpg' },
        { Name: 'Hồ điệp trắng chậu sứ 04', Price: '2200000', Category: 'lan-ho-diep', PriceOld: '2800000', Images: '/LanHoDiep/14051_ho-diep-trang-chau-su.jpg' },
        { Name: 'Hồ điệp vàng chậu sứ 01', Price: '3400000', Category: 'lan-ho-diep', PriceOld: '4200000', Images: '/LanHoDiep/13643_ho-diep-vang-chau-su.jpg' },
        { Name: 'Hồ điệp trắng chậu sứ 01', Price: '4200000', Category: 'lan-ho-diep', PriceOld: '4200000', Images: '/LanHoDiep/13636_ho-diep-trang-chau-su.jpg' },
        { Name: 'Hồ điệp mix màu 02', Price: '3050000', Category: 'lan-ho-diep', PriceOld: '3500000', Images: '/LanHoDiep/14056_ho-diep-mix-mau.jpg' },
        { Name: 'Lan hồ điệp mẫu 7', Price: '3300000', Category: 'lan-ho-diep', PriceOld: '4620000', Images: '/LanHoDiep/13593_lan-ho-diep-mau.jpg' },
        { Name: 'Lan Hồ Điệp Mẫu 6', Price: '3500000', Category: 'lan-ho-diep', PriceOld: '4200000', Images: '/LanHoDiep/12320_lan-ho-diep-mau.jpg' },
        { Name: 'Chậu Hồ điệp cao cấp 1', Price: '4500000', Category: 'lan-ho-diep', PriceOld: '4554000', Images: '/LanHoDiep/13631_chau-ho-diep-cao-cap.jpg' },
        { Name: 'Lan hồ điệp mẫu 9', Price: '2200000', Category: 'lan-ho-diep', PriceOld: '2800000', Images: '/LanHoDiep/13595_lan-ho-diep-mau.jpg' },
        { Name: 'Elegance orchid', Price: '2850000', Category: 'lan-ho-diep', PriceOld: '3500000', Images: '/LanHoDiep/13397_elegance-orchid.jpg' },
        { Name: 'Hồ điệp tím 2', Price: '2475000', Category: 'lan-ho-diep', PriceOld: '2450000', Images: '/LanHoDiep/13634_ho-diep-tim.jpg' },
        { Name: 'Chậu hồ điệp trắng 15 cành', Price: '46250000', Category: 'lan-ho-diep', PriceOld: '4679000', Images: '/LanHoDiep/14609_chau-ho-diep-trang--canh.jpg' },
        { Name: 'Chậu hồ điệp tím 20 cành', Price: '6000000', Category: 'lan-ho-diep', PriceOld: '7000000', Images: '/LanHoDiep/13973_chau-ho-diep-tim--canh.jpg' },
        { Name: 'Hồ điệp chậu 40 cành', Price: '15000000', Category: 'lan-ho-diep', PriceOld: '15054000', Images: '/LanHoDiep/13659_ho-diep-chau--canh.jpg' },
        { Name: 'Lan hồ điệp chậu 70 cành', Price: '25500000', Category: 'lan-ho-diep', PriceOld: '25554000', Images: '/LanHoDiep/13660_lan-ho-diep-chau--canh.jpg' },
        { Name: 'Hồ điệp chậu 100 cành', Price: '28500000', Category: 'lan-ho-diep', PriceOld: '28554000', Images: '/LanHoDiep/12693_ho-diep-chau--canh.jpg' },
        { Name: 'Hồ điệp trắng chậu sứ 05', Price: '1025000', Category: 'lan-ho-diep', PriceOld: '1079000', Images: '/LanHoDiep/14057_ho-diep-trang-chau-su.jpg' },
        { Name: 'Hồ điệp trắng 8 cành', Price: '2200000', Category: 'lan-ho-diep', PriceOld: '2254000', Images: '/LanHoDiep/7793_ho-diep-trang--canh.jpg' },
        { Name: 'Hồ điệp tím 1', Price: '1375000', Category: 'lan-ho-diep', PriceOld: '1385000', Images: '/LanHoDiep/13633_ho-diep-tim.jpg' },
        { Name: 'Hồ điệp trắng 1', Price: '1375000', Category: 'lan-ho-diep', PriceOld: '1805000', Images: '/LanHoDiep/13638_ho-diep-trang.jpg' },
        { Name: 'Hồ điệp tím 4', Price: '1375000', Category: 'lan-ho-diep', PriceOld: '1750000', Images: '/LanHoDiep/13637_ho-diep-tim.jpg' },
        { Name: 'Chậu lan hồng 5 cành 01', Price: '1375000', Category: 'lan-ho-diep', PriceOld: '1527778', Images: '/LanHoDiep/12319_chau-lan-hong--canh.jpg' },
        { Name: 'Chậu lan vàng 3 cành 02', Price: '825000', Category: 'lan-ho-diep', PriceOld: '1050000', Images: '/LanHoDiep/12704_chau-lan-vang--canh.jpg' },
        { Name: 'Hồ điệp hồng 10 cành', Price: '2900000', Category: 'lan-ho-diep', PriceOld: '2954000', Images: '/LanHoDiep/7777_ho-diep-hong--canh.jpg' },
        { Name: 'Chậu hồ điệp vàng 10 cành', Price: '2750000', Category: 'lan-ho-diep', PriceOld: '3555000', Images: '/LanHoDiep/12705_chau-ho-diep-vang--canh.jpg' },
        { Name: 'Lan Hồ Điệp Mẫu 8', Price: '2475000', Category: 'lan-ho-diep', PriceOld: '3150000', Images: '/LanHoDiep/12322_lan-ho-diep-mau.jpg' },
        { Name: 'Lan Hồ Điệp xuân mẫu 11', Price: '825000', Category: 'lan-ho-diep', PriceOld: '1050000', Images: '/LanHoDiep/12706_lan-ho-diep-xuan-mau.jpg' },
        { Name: 'Chậu lan vàng 5 cành 01', Price: '1375000', Category: 'lan-ho-diep', PriceOld: '1750000', Images: '/LanHoDiep/13596_chau-lan-vang--canh.jpg' },
        { Name: 'Sum vầy', Price: '2200000', Category: 'lan-ho-diep', PriceOld: '2855000', Images: '/LanHoDiep/11127_sum-vay.png' },
        { Name: 'Chậu hồ điệp tím 18 cành', Price: '5450000', Category: 'lan-ho-diep', PriceOld: '5504000', Images: '/LanHoDiep/13974_chau-ho-diep-tim--canh.jpg' },
        { Name: 'Đoàn viên', Price: '1100000', Category: 'lan-ho-diep', PriceOld: '1200000', Images: '/LanHoDiep/4008_doan-vien.jpg' },
        { Name: 'Hồ điệp mix màu 8 cành', Price: '2200000', Category: 'lan-ho-diep', PriceOld: '2800000', Images: '/LanHoDiep/11134_ho-diep-mix-mau--canh.png' },
        { Name: 'Lan Hồ Điệp xuân mẫu 14', Price: '2850000', Category: 'lan-ho-diep', PriceOld: '3500000', Images: '/LanHoDiep/14052_ho-diep-hong-chau-su.jpg' },
        { Name: 'Hồ điệp hồng chậu sứ 04', Price: '4925000', Category: 'lan-ho-diep', PriceOld: '5250000', Images: '/LanHoDiep/14052_ho-diep-hong-chau-su.jpg' },
        { Name: 'May mắn', Price: '300000', Category: 'lan-ho-diep', PriceOld: '350000', Images: '/LanHoDiep/3698_may-man.jpg' },
        { Name: 'Lan Hồ Điệp Mẫu 2', Price: '1100000', Category: 'lan-ho-diep', PriceOld: '1154000', Images: '/LanHoDiep/12291_lan-ho-diep-mau.jpg' },
        { Name: 'Chậu lan tím 1 cành 01', Price: '300000', Category: 'lan-ho-diep', PriceOld: '350000', Images: '/LanHoDiep/11687_chau-lan-tim--canh.jpg' },
        { Name: 'Vạn hạnh', Price: '825000', Category: 'lan-ho-diep', PriceOld: '1050000', Images: '/LanHoDiep/11130_van-hanh.png' },
        { Name: 'Vạn Sự Như Ý', Price: '300000', Category: 'lan-ho-diep', PriceOld: '350000', Images: '/LanHoDiep/5217_van-su-nhu-y.jpg' },
        { Name: 'Khu vườn xuân', Price: '925000', Category: 'lan-ho-diep', PriceOld: '1050000', Images: '/LanHoDiep/11126_khu-vuon-xuan.png' },
        { Name: 'Chậu lan trắng 3 cành 01', Price: '825000', Category: 'lan-ho-diep', PriceOld: '1050000', Images: '/LanHoDiep/11123_chau-lan-trang--canh.png' },
        { Name: 'May mắn tràn đầy', Price: '1375000', Category: 'lan-ho-diep', PriceOld: '1750000', Images: '/LanHoDiep/7775_may-man-tran-day.png' },
        { Name: 'Khúc hoan ca', Price: '1475000', Category: 'lan-ho-diep', PriceOld: '1750000', Images: '/LanHoDiep/11133_khuc-hoan-ca.png' },
        { Name: 'Phúc An Khang', Price: '1650000', Category: 'lan-ho-diep', PriceOld: '2100000', Images: '/LanHoDiep/5595_phuc-an-khang.jpg' },
        { Name: 'Chậu lan vàng 3 cành 01', Price: '825000', Category: 'lan-ho-diep', PriceOld: '916666', Images: '/LanHoDiep/11137_chau-lan-vang--canh.jpg' },
        { Name: 'Chậu lan 3 cành trắng hồng', Price: '925000', Category: 'lan-ho-diep', PriceOld: '1050000', Images: '/LanHoDiep/11128_chau-lan--canh-trang-hong.png' },
        { Name: 'Chậu lan tím 3 cành 01', Price: '825000', Category: 'lan-ho-diep', PriceOld: '1050000', Images: '/LanHoDiep/11131_chau-lan-tim--canh.png' },
        { Name: 'Cát tường 2', Price: '2850000', Category: 'lan-ho-diep', PriceOld: '3500000', Images: '/LanHoDiep/13398_cat-tuong.jpg' },
        { Name: 'Tâm an', Price: '1050000', Category: 'lan-ho-diep', PriceOld: '1105000', Images: '/LanHoDiep/11129_tam-an.png' },
        { Name: 'Lan Hồ Điệp xuân mẫu 13', Price: '3300000', Category: 'lan-ho-diep', PriceOld: '3700000', Images: '/LanHoDiep/12708_lan-ho-diep-xuan-mau.jpg' },
        { Name: 'Lan Hồ Điệp Mẫu 4', Price: '1375000', Category: 'lan-ho-diep', PriceOld: '1805000', Images: '/LanHoDiep/12293_lan-ho-diep-mau.jpg' },
        { Name: 'Lan Hồ Điệp Mẫu 3', Price: '925000', Category: 'lan-ho-diep', PriceOld: '1105000', Images: '/LanHoDiep/12292_lan-ho-diep-mau.jpg' },
        { Name: 'Lan Hồ Điệp Xuân', Price: '925000', Category: 'lan-ho-diep', PriceOld: '1105000', Images: '/LanHoDiep/11125_lan-ho-diep-xuan.png' },
        { Name: 'Khoe sắc', Price: '1375000', Category: 'lan-ho-diep', PriceOld: '1805000', Images: '/LanHoDiep/11132_khoe-sac.png' },
        // Xong
        // Hoa lanvender
        { Name: 'Bó hoa lavender 26', Price: '4500000', Category: 'hoa-lavender', PriceOld: '', Images: '/HoaLavender/bo-hoa-lavender-26.jpg' },
        { Name: 'Bó hoa lavender 30', Price: '3000000', Category: 'hoa-lavender', PriceOld: '', Images: '/HoaLavender/bo-hoa-lavender-30.jpg' },
        { Name: 'Bó hoa lavender 28', Price: '1800000', Category: 'hoa-lavender', PriceOld: '', Images: '/HoaLavender/bo-hoa-lavender-28.jpg' },
        { Name: 'Bó hoa lavender 20', Price: '1650000', Category: 'hoa-lavender', PriceOld: '', Images: '/HoaLavender/bo-hoa-lavender-20.jpg' },
        { Name: 'Bó hoa lavender 18', Price: '1500000', Category: 'hoa-lavender', PriceOld: '1600000', Images: '/HoaLavender/bo-hoa-lavender-18.jpg' },
        { Name: 'Bó hoa lavender 11', Price: '1350000', Category: 'hoa-lavender', PriceOld: '1400000', Images: '/HoaLavender/bo-hoa-lavender-11.jpg' },
        { Name: 'Bó hoa lavender 16', Price: '1200000', Category: 'hoa-lavender', PriceOld: '', Images: '/HoaLavender/bo-hoa-lavender-16.jpg' },
        { Name: 'Bó hoa lavender 09', Price: '1200000', Category: 'hoa-lavender', PriceOld: '', Images: '/HoaLavender/bo-hoa-lavender-09.jpg' },
        { Name: 'Bó hoa lavender 27', Price: '1130000', Category: 'hoa-lavender', PriceOld: '', Images: '/HoaLavender/bo-hoa-lavender-27.jpg' },
        { Name: 'Bó hoa lavender 29', Price: '1100000', Category: 'hoa-lavender', PriceOld: '', Images: '/HoaLavender/bo-hoa-lavender-29.jpg' },
        { Name: 'Bó hoa lavender 01', Price: '1070000', Category: 'hoa-lavender', PriceOld: '', Images: '/HoaLavender/bo-hoa-lavender-01.jpg' },
        { Name: 'Bó hoa lavender 21', Price: '960000', Category: 'hoa-lavender', PriceOld: '', Images: '/HoaLavender/bo-hoa-lavender-21.jpg' },
        { Name: 'Bó hoa lavender 14', Price: '820000', Category: 'hoa-lavender', PriceOld: '', Images: '/HoaLavender/bo-hoa-lavender-14.jpg' },
        { Name: 'Bó hoa lavender 13', Price: '820000', Category: 'hoa-lavender', PriceOld: '', Images: '/HoaLavender/bo-hoa-lavender-13.jpg' },
        { Name: 'Bó hoa lavender 12', Price: '820000', Category: 'hoa-lavender', PriceOld: '', Images: '/HoaLavender/bo-hoa-lavender-12.jpg' },
        { Name: 'Bó hoa lavender 17', Price: '790000', Category: 'hoa-lavender', PriceOld: '800000', Images: '/HoaLavender/bo-hoa-lavender-17.jpg' },
        { Name: 'Bó hoa lavender 15', Price: '730000', Category: 'hoa-lavender', PriceOld: '', Images: '/HoaLavender/bo-hoa-lavender-15.jpg' },
        { Name: 'Bó hoa lavender 25', Price: '700000', Category: 'hoa-lavender', PriceOld: '800000', Images: '/HoaLavender/bo-hoa-lavender-25.jpg' },
        { Name: 'Bó hoa lavender 24', Price: '620000', Category: 'hoa-lavender', PriceOld: '', Images: '/HoaLavender/bo-hoa-lavender-24.jpg' },
        { Name: 'Bó hoa lavender 08', Price: '620000', Category: 'hoa-lavender', PriceOld: '', Images: '/HoaLavender/bo-hoa-lavender-08.jpg' },
        { Name: 'Bó hoa lavender 04', Price: '620000', Category: 'hoa-lavender', PriceOld: '670000', Images: '/HoaLavender/bo-hoa-lavender-04.jpg' },
        { Name: 'Bó hoa lavender 05', Price: '600000', Category: 'hoa-lavender', PriceOld: '', Images: '/HoaLavender/bo-hoa-lavender-05.jpg' },
        { Name: 'Bó hoa lavender 22', Price: '540000', Category: 'hoa-lavender', PriceOld: '620000', Images: '/HoaLavender/bo-hoa-lavender-22.jpg' },
        { Name: 'Bó hoa lavender 02', Price: '520000', Category: 'hoa-lavender', PriceOld: '', Images: '/HoaLavender/bo-hoa-lavender-02.jpg' },
        { Name: 'Bó hoa lavender 10', Price: '500000', Category: 'hoa-lavender', PriceOld: '', Images: '/HoaLavender/bo-hoa-lavender-10.jpg' },
        { Name: 'Bó hoa lavender 07', Price: '480000', Category: 'hoa-lavender', PriceOld: '', Images: '/HoaLavender/bo-hoa-lavender-07.jpg' },
        { Name: 'Bó hoa lavender 23', Price: '440000', Category: 'hoa-lavender', PriceOld: '', Images: '/HoaLavender/bo-hoa-lavender-23.jpg' },
        { Name: 'Bó hoa lavender 06', Price: '440000', Category: 'hoa-lavender', PriceOld: '500000', Images: '/HoaLavender/bo-hoa-lavender-06.jpg' },
        { Name: 'Bó hoa lavender 19', Price: '380000', Category: 'hoa-lavender', PriceOld: '450000', Images: '/HoaLavender/bo-hoa-lavender-19.jpg' },
        { Name: 'Bó hoa lavender 03', Price: '380000', Category: 'hoa-lavender', PriceOld: '400000', Images: '/HoaLavender/bo-hoa-lavender-03.jpg' },
        { Name: 'Bó hoa lavender 18', Price: '1500000', Category: 'hoa-lavender', PriceOld: '', Images: '/HoaLavender/bo-hoa-lavender-18.jpg' },
        // Hoa Ly
        { Name: 'Hoa tặng sự kiện 5(+20 bó)', Price: '360000', Category: 'hoa-ly', PriceOld: '', Images: '/BoHoaLy/10901_hoa-tang-su-kien--bo.jpg' },
        { Name: 'Sương mai', Category: 'hoa-ly', Price: '1100000', PriceOld: '1200000', Images: '/BoHoa/9372_suong-mai.jpg' },
        { Name: 'Phát lộc', Price: '1300000', Category: 'hoa-ly', PriceOld: '', Images: '/HoaChucMung/4369_phat-loc.jpg' },
        { Name: 'Tương Lai Rực Rỡ', Price: '1800000', Category: 'hoa-ly', PriceOld: '2000000', Images: '/BoHoaLy/5436_tuong-lai-ruc-ro.jpg' },
        { Name: 'Khúc Ca Khải Hoàn', Price: '2300000', Category: 'hoa-ly', PriceOld: '', Images: '/BoHoaLy/5644_khuc-ca-khai-hoan.jpg' },
        { Name: 'Phát Đạt', Price: '1500000', Category: 'hoa-ly', PriceOld: '', Images: '/BoHoaLy/5187_phat-dat.jpg' },
        { Name: 'Mạnh Mẽ Tiến Bước', Price: '2000000', Category: 'hoa-ly', PriceOld: '', Images: '/BoHoaLy/5277_manh-me-tien-buoc.jpg' },
        { Name: 'Vạn Sự Hanh Thông 3', Price: '2500000', Category: 'hoa-ly', PriceOld: '2700000', Images: '/HoaChucMung/2091_van-su-hanh-thong.jpg' },
        { Name: 'Tương Lai Tươi Sáng', Price: '2000000', Category: 'hoa-ly', PriceOld: '', Images: '/HoaChucMung/6910_tuong-lai-tuoi-sang.png' },
        { Name: 'Thành Công Viên Mãn', Price: '2900000', Category: 'hoa-ly', PriceOld: '', Images: '/BoHoaLy/5442_thanh-cong-vien-man.png' },
        { Name: 'Vững tin', Price: '800000', Category: 'hoa-ly', PriceOld: '', Images: '/BoHoaLy/4361_vung-tin.jpg' },
        { Name: 'Thành Đạt', Price: '1200000', Category: 'hoa-ly', PriceOld: '', Images: '/HoaChucMung/5263_thanh-dat.jpg' },
        { Name: 'Đại Cát', Price: '1800000', Category: 'hoa-ly', PriceOld: '', Images: '/BoHoaLy/9408_dai-cat.png' },
        { Name: 'Vạn Đạt', Price: '1500000', Category: 'hoa-ly', PriceOld: '5060000', Images: '/HoaChucMung/6905_van-dat.png' },
        { Name: 'Vinh quang 3', Price: '2500000', Category: 'hoa-ly', PriceOld: '', Images: '/BoHoaLy/4343_vinh-quang.jpg' },
        { Name: 'Lộc Phát', Price: '1800000', Category: 'hoa-ly', PriceOld: '', Images: '/HoaChucMung/4940_loc-phat.jpg' },
        { Name: 'Be All You Can Be', Price: '2300000', Category: 'hoa-ly', PriceOld: '', Images: '/HoaChucMung/2994_be-all-you-can-be.jpg' },
        { Name: 'Đại Cát Đại Lợi 2', Price: '2200000', Category: 'hoa-ly', PriceOld: '', Images: '/BoHoaLy/2489_dai-cat-dai-loi.jpg' },
        { Name: 'Khai Trương Lộc Phát', Price: '2000000', Category: 'hoa-ly', PriceOld: '', Images: '/BoHoaHuongDuong/5403_khai-truong-loc-phat.jpg' },
        { Name: 'Công Danh Phú Quý', Price: '1500000', Category: 'hoa-ly', PriceOld: '', Images: '/BoHoaCuc/5401_cong-danh-phu-quy.jpg' },
        { Name: 'Thịnh Phát', Price: '1200000', Category: 'hoa-ly', PriceOld: '', Images: '/BoHoaLy/5526_thinh-phat.jpg' },
        { Name: 'Giã Biệt 1', Price: '2500000', Category: 'hoa-ly', PriceOld: '', Images: '/HoaChiaBuon/3013_gia-biet.jpg' },
        { Name: 'Mãi xa', Price: '1500000', Category: 'hoa-ly', PriceOld: '', Images: '/HoaChiaBuon/10966_mai-xa.png' },
        { Name: 'Rest in peace', Price: '1350000', Category: 'hoa-ly', PriceOld: '', Images: '/HoaChiaBuon/8388_rest-in-peace.png' },
        { Name: 'Luyến Tiếc 1', Price: '1650000', Category: 'hoa-ly', PriceOld: '', Images: '/HoaChiaBuon/3087_luyen-tiec.jpg' },
        { Name: 'Paradise', Price: '900000', Category: 'hoa-ly', PriceOld: '', Images: '/GioHoaTuoi/12345_paradise.jpg' },
        { Name: 'Thiên thu 2', Price: '1500000', Category: 'hoa-ly', PriceOld: '', Images: '/HoaChiaBuon/8778_thien-thu.png' },
        { Name: 'Về Nơi Xa', Price: '1550000', Category: 'hoa-ly', PriceOld: '', Images: '/HoaChiaBuon/5125_ve-noi-xa.jpg' },
        { Name: 'Ký Ức', Price: '2300000', Category: 'hoa-ly', PriceOld: '', Images: '/HoaChiaBuon/5218_ky-uc.jpg' },
        { Name: 'Phút Chia Ly', Price: '1000000', Category: 'hoa-ly', PriceOld: '', Images: '/HoaChiaBuon/5239_phut-chia-ly.jpg' },
        { Name: 'Ngày Chia Xa', Price: '1800000', Category: 'hoa-ly', PriceOld: '', Images: '/HoaChiaBuon/5405_ngay-chia-xa.jpg' },
        { Name: 'Vô Thường', Price: '1300000', Category: 'hoa-ly', PriceOld: '', Images: '/HoaChiaBuon/5203_vo-thuong.jpg' },
        { Name: 'Giọt mưa sa', Price: '2500000', Category: 'hoa-ly', PriceOld: '3055556', Images: '/HoaChiaBuon/4409_giot-mua-sa.jpg' },
        { Name: 'Luyến Tiếc 4', Price: '1500000', Category: 'hoa-ly', PriceOld: '', Images: '/HoaChiaBuon/2485_luyen-tiec.jpg' },
        { Name: 'Mãi nhớ', Price: '2000000', Category: 'hoa-ly', PriceOld: '', Images: '/HoaChiaBuon/4353_mai-nho.jpg' },
        { Name: 'Hạc Trắng 3', Price: '1450000', Category: 'hoa-ly', PriceOld: '', Images: '/HoaChiaBuon/2663_hac-trang.jpg' },
        { Name: 'Ngày hồng tươi', Price: '1500000', Category: 'hoa-ly', PriceOld: '', Images: '/BoHoaLy/10418_ngay-hong-tuoi.jpg' },
        { Name: 'Kệ Chia Buồn 03', Price: '600000', Category: 'hoa-ly', PriceOld: '', Images: '/HoaChiaBuon/13145_ke-chia-buon.jpg' },
        { Name: 'Kệ Chia Buồn 05', Price: '700000', Category: 'hoa-ly', PriceOld: '', Images: '/HoaChiaBuon/13147_ke-chia-buon.jpg' },
        { Name: 'Peaceful moment', Price: '700000', Category: 'hoa-ly', PriceOld: '', Images: '/BoHoaLy/13367_peaceful-moment.png' },
        { Name: 'Thành công rực rỡ', Price: '2000000', Category: 'hoa-ly', PriceOld: '', Images: '/BoHoaLy/14379_thanh-cong-ruc-ro.jpg' },
        { Name: 'Con đường thành công', Price: '2800000', Category: 'hoa-ly', PriceOld: '', Images: '/BoHoaLy/14380_con-duong-thanh-cong.jpg' },
        { Name: 'Hân hoan', Price: '1500000', Category: 'hoa-ly', PriceOld: '', Images: '/BoHoaLy/14377_han-hoan.jpg' },
        { Name: 'Đại lộc', Price: '1300000', Category: 'hoa-ly', PriceOld: '', Images: '/BoHoaLy/14373_dai-loc.jpg' },
        { Name: 'Congrats mini size 7', Price: '800000', Category: 'hoa-ly', PriceOld: '', Images: '/BoHoaHuongDuong/14351_congrats-mini-size.jpg' },
        { Name: 'Vươn mình bay xa', Price: '1350000', Category: 'hoa-ly', PriceOld: '', Images: '/BoHoaLy/14350_vuon-minh-bay-xa.jpg' },
        { Name: 'New Start', Price: '1000000', Category: 'hoa-ly', PriceOld: '', Images: '/BoHoaLy/14349_new-start.jpg' },
        { Name: 'Sự khởi đầu', Price: '1000000', Category: 'hoa-ly', PriceOld: '', Images: '/BoHoaLy/14347_su-khoi-dau.jpg' },
        { Name: 'Lời chúc thành công', Price: '1200000', Category: 'hoa-ly', PriceOld: '', Images: '/BoHoaLy/14346_loi-chuc-thanh-cong.jpg' },
        { Name: 'Trường phát', Price: '1200000', Category: 'hoa-ly', PriceOld: '', Images: '/BoHoaLy/14345_truong-phat.jpg' },
        { Name: 'Cát lộc', Price: '2400000', Category: 'hoa-ly', PriceOld: '', Images: '/BoHoaHuongDuong/14371_cat-loc.jpg' },
        // Xong
        // Hoa Đồng tiền
        { Name: 'Hoa tặng sự kiện 2(+20 bó)', Price: '2500000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaCatTuong/10898_hoa-tang-su-kien--bo.jpg' },
        { Name: 'Hoa may mắn', Category: 'hoa-dong-tien', Price: '600000', PriceOld: '660000', Images: '/BoHoa/9442_hoa-may-man.jpg' },
        { Name: 'Mãi yêu', Price: '450000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/GioHoaTuoi/11612_mai-yeu.jpg' },
        { Name: 'Good Luck 3', Price: '700000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaDongTien/4047_good-luck.jpg' },
        { Name: 'Hưng Thịnh 4', Price: '1000000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaDongTien/3967_hung-thinh.jpg' },
        { Name: 'Good Luck 1', Price: '800000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaDongTien/3965_good-luck.jpg' },
        { Name: 'Congratulations 2', Price: '1000000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaDongTien/7246_congratulations.png' },
        { Name: 'Phát lộc', Price: '1300000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/HoaChucMung/4369_phat-loc.jpg' },
        { Name: 'Toả sáng', Price: '1200000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaDongTien/11032_toa-sang.jpg' },
        { Name: 'Khởi đầu thuận lợi', Price: '4000000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/HoaChucMung/11571_khoi-dau-thuan-loi.png' },
        { Name: 'Đại hồng phát', Price: '4500000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaDongTien/11030_dai-hong-phat.png' },
        { Name: 'Vững tin', Price: '800000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaLy/4361_vung-tin.jpg' },
        { Name: 'Tầm Cao Mới', Price: '1500000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaDongTien/7174_tam-cao-moi.png' },
        { Name: 'Lucky Flower', Price: '1500000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaDongTien/7229_lucky-flower.png' },
        { Name: 'Vững bền', Price: '1320000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaHuongDuong/5774_vung-ben.jpg' },
        { Name: 'Success Flowers', Price: '1320000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaHuongDuong/5136_success-flowers.jpg' },
        { Name: 'Nỗ lực tiến bước', Price: '4500000', Category: 'hoa-dong-tien', PriceOld: '5000000', Images: '/HoaChucMung/11570_no-luc-tien-buoc.png' },
        { Name: 'Good Luck 2', Price: '850000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/HoaChucMung/3966_good-luck.jpg' },
        { Name: 'Vinh quang 3', Price: '2500000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaLy/4343_vinh-quang.jpg' },
        { Name: 'Thịnh Phát', Price: '1200000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaLy/5526_thinh-phat.jpg' },
        { Name: 'Đỉnh Danh Vọng', Price: '2300000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaDongTien/7230_dinh-danh-vong.png' },
        { Name: 'Hai thế giới 2', Price: '1400000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/HoaChiaBuon/8397_hai-the-gioi.png' },
        { Name: 'Vĩnh biệt 2', Price: '1200000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/HoaChiaBuon/3064_vinh-biet.jpg' },
        { Name: 'Kiếp Vô Thường', Price: '3300000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/HoaChiaBuon/5596_kiep-vo-thuong.jpg' },
        { Name: 'Yên Nghỉ', Price: '2700000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/HoaChiaBuon/5260_yen-nghi.jpg' },
        { Name: 'The end', Price: '1300000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/HoaChiaBuon/4589_the-end.jpg' },
        { Name: 'Giọt mưa sa', Price: '2500000', Category: 'hoa-dong-tien', PriceOld: '3055556', Images: '/HoaChiaBuon/4409_giot-mua-sa.jpg' },
        { Name: 'Luyến Tiếc 4', Price: '1500000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/HoaChiaBuon/2485_luyen-tiec.jpg' },
        { Name: 'Thiên thu', Price: '1550000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/HoaChiaBuon/4412_thien-thu.jpg' },
        { Name: 'Lãng quên', Price: '1250000', Category: 'hoachiabuon', PriceOld: '', Images: '/HoaChiaBuon/4681_lang-quen.jpg' },
        { Name: 'Tin Yêu', Price: '750000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaDongTien/10264_tin-yeu.png' },
        { Name: 'Ngày hồng tươi', Price: '1500000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaLy/10418_ngay-hong-tuoi.jpg' },
        { Name: 'Phú Quý 2', Price: '1600000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaDongTien/12505_phu-quy.jpg' },
        { Name: 'Thênh Thang', Price: '1200000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/HoaChiaBuon/12488_thenh-thang.jpg' },
        { Name: 'Kiếp nhân sinh 2', Price: '2000000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/HoaChiaBuon/12628_kiep-nhan-sinh.jpg' },
        { Name: 'Big Step', Price: '3500000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/HoaChucMung/13105_big-step.jpg' },
        { Name: 'Tâm nguyện', Price: '1000000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/HoaChiaBuon/13099_tam-nguyen.jpg' },
        { Name: 'Kệ Chúc Mừng 02', Category: 'hoa-dong-tien', Price: '950000', PriceOld: '1100000', Images: '/HoaChucMung/13150_ke-chuc-mung.jpg' },
        { Name: 'Kệ Chúc Mừng 03', Price: '1500000', Category: 'hoa-dong-tien', PriceOld: '2003000', Images: '/BoHoaDongTien/13151_ke-chuc-mung.jpg' },
        { Name: 'Kệ Chúc Mừng 04', Price: '1200000', Category: 'hoa-dong-tien', PriceOld: '1470000', Images: '/BoHoaDongTien/13152_ke-chuc-mung.jpg' },
        { Name: 'Kệ Chúc Mừng 06', Price: '1200000', Category: 'hoa-dong-tien', PriceOld: '1237000', Images: '/BoHoaDongTien/13154_ke-chuc-mung.jpg' },
        { Name: 'Kệ Chúc Mừng 07', Price: '1000000', Category: 'hoa-dong-tien', PriceOld: '1140000', Images: '/BoHoaCuc/13155_ke-chuc-mung.jpg' },
        { Name: 'Perfection 02', Category: 'hoa-dong-tien', Price: '1400000', PriceOld: '', Images: '/HoaChucMung/13166_perfection.jpg' },
        { Name: 'Heaven 02', Price: '1000000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/HoaChiaBuon/13200_heaven.jpg' },
        { Name: 'Tuổi hồng', Price: '450000', Category: 'hoa-dong-tien', PriceOld: '500000', Images: '/GioHoaTuoi/13301_tuoi-hong.jpg' },
        { Name: 'Good luck 4', Price: '450000', Category: 'hoa-dong-tien', PriceOld: '500000', Images: '/GioHoaTuoi/13300_good-luck.jpg' },
        { Name: 'Lucky', Price: '450000', Category: 'hoa-dong-tien', PriceOld: '500000', Images: '/GioHoaTuoi/13299_lucky.jpg' },
        { Name: 'Ngày cuối tuần 2', Price: '700000', Category: 'hoa-dong-tien', PriceOld: '800000', Images: '/BoHoaDongTien/13365_ngay-cuoi-tuan.png' },
        { Name: 'Bình minh', Category: 'hoa-dong-tien', Price: '250000', PriceOld: '', Images: '/BoHoa/13437_binh-minh.jpg' },
        { Name: 'Sweet heart', Category: 'hoa-dong-tien', Price: '1400000', PriceOld: '1500000', Images: '/BoHoa/13235_sweet-heart.jpg' },
        { Name: 'Say you do', Category: 'hoa-dong-tien', Price: '300000', PriceOld: '400000', Images: '/BoHoa/14339_say-you-do.jpg' },
        { Name: 'Ngọt ngào như hoa', Category: 'hoa-dong-tien', Price: '350000', PriceOld: '450000', Images: '/BoHoa/14338_ngot-ngao-nhu-hoa.jpg' },
        { Name: 'Thành công rực rỡ', Price: '2000000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaLy/14379_thanh-cong-ruc-ro.jpg' },
        { Name: 'Con đường thành công', Price: '2800000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaLy/14380_con-duong-thanh-cong.jpg' },
        { Name: 'Dấu ấn', Price: '1500000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaDongTien/14378_dau-an.jpg' },
        { Name: 'Hân hoan', Price: '1500000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaLy/14377_han-hoan.jpg' },
        { Name: 'Lộc tài', Price: '2000000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaDongTien/14376_loc-tai.jpg' },
        { Name: 'Hồng phát 2', Price: '2500000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaDongTien/14375_hong-phat.jpg' },
        { Name: 'Tấn lộc', Price: '1200000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaDongTien/14374_tan-loc.jpg' },
        { Name: 'Đại lộc', Price: '1300000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaLy/14373_dai-loc.jpg' },
        { Name: 'Cát tài', Price: '1000000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaDongTien/14372_cat-tai.jpg' },
        { Name: 'New Start', Price: '1000000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaLy/14349_new-start.jpg' },
        { Name: 'Trường phát', Price: '1200000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/BoHoaLy/14345_truong-phat.jpg' },
        { Name: 'Thành công mỹ mãn', Price: '2500000', Category: 'hoa-dong-tien', PriceOld: '2750000', Images: '/BoHoaDongTien/14419_thanh-cong-my-man.jpg' },
        { Name: 'Pink Delight', Price: '350000', Category: 'hoa-dong-tien', PriceOld: '400000', Images: '/GioHoaTuoi/14453_pink-delight.jpg' },
        { Name: 'Thắp sáng', Price: '800000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/HopHoaTuoi/14603_thap-sang.jpg' },
        { Name: 'Cuộc sống tươi mới', Price: '550000', Category: 'hoa-dong-tien', PriceOld: '', Images: '/GioHoaTuoi/14836_cuoc-song-tuoi-moi.jpg' },
        { Name: 'Bất ngờ nhỏ', Category: 'hoa-dong-tien', Price: '450000', PriceOld: '', Images: '/GioHoaTuoi/14842_bat-ngo-nho.jpg' },
        // xong
        // Hoa TuLip
        { Name: 'Bó hoa sáp Tulip 06', Category: 'hoa-tu-lip', Price: '450000', PriceOld: '', Images: '/HoaTulip/bo-hoa-sap-tulip-06.png' },
        { Name: 'Bó hoa sáp Tulip 08', Category: 'hoa-tu-lip', Price: '450000', PriceOld: '', Images: '/HoaTulip/bo-hoa-sap-tulip-08.png' },
        { Name: 'Bó hoa sáp Tulip 60', Category: 'hoa-tu-lip', Price: '450000', PriceOld: '', Images: '/HoaTulip/bo-hoa-sap-tulip-60.png' },
        { Name: 'Bó hoa sáp Tulip 04', Category: 'hoa-tu-lip', Price: '480000', PriceOld: '580000', Images: '/HoaTulip/bo-hoa-sap-tulip-04.png' },
        { Name: 'Bó hoa sáp Tulip 07', Category: 'hoa-tu-lip', Price: '480000', PriceOld: '', Images: '/HoaTulip/bo-hoa-sap-tulip-07.png' },
        { Name: 'Bó hoa sáp Tulip 05', Category: 'hoa-tu-lip', Price: '500000', PriceOld: '', Images: '/HoaTulip/bo-hoa-sap-tulip-05.png' },
        { Name: 'Bó hoa Tulip 55', Category: 'hoa-tu-lip', Price: '550000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-55.png' },
        { Name: 'Bó hoa Tulip 56', Category: 'hoa-tu-lip', Price: '550000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-56.png' },
        { Name: 'Hoa sáp Tulip 12', Category: 'hoa-tu-lip', Price: '600000', PriceOld: '', Images: '/HoaTulip/hoa-sap-tulip-12.png' },
        { Name: 'Hoa sáp Tulip 15', Category: 'hoa-tu-lip', Price: '650000', PriceOld: '', Images: '/HoaTulip/hoa-sap-tulip-15.png' },
        { Name: 'Bó hoa Tulip 46', Category: 'hoa-tu-lip', Price: '650000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-46.png' },
        { Name: 'Hoa sáp Tulip 19', Category: 'hoa-tu-lip', Price: '700000', PriceOld: '', Images: '/HoaTulip/hoa-sap-tulip-19.png' },
        { Name: 'Bó hoa Tulip 22', Category: 'hoa-tu-lip', Price: '700000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-22.png' },
        { Name: 'Bó hoa Tulip 29', Category: 'hoa-tu-lip', Price: '700000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-29.png' },
        { Name: 'Bó hoa sáp Tulip 09', Category: 'hoa-tu-lip', Price: '150000', PriceOld: '', Images: '/HoaTulip/bo-hoa-sap-tulip-09.png' },
        { Name: 'Hoa sáp Tulip 20', Category: 'hoa-tu-lip', Price: '180000', PriceOld: '', Images: '/HoaTulip/hoa-sap-tulip-20.png' },
        { Name: 'Hoa sáp Tulip 17', Category: 'hoa-tu-lip', Price: '190000', PriceOld: '', Images: '/HoaTulip/hoa-sap-tulip-17.png' },
        { Name: 'Hoa sáp Tulip 13', Category: 'hoa-tu-lip', Price: '250000', PriceOld: '', Images: '/HoaTulip/hoa-sap-tulip-13.png' },
        { Name: 'Hoa sáp Tulip 14', Category: 'hoa-tu-lip', Price: '250000', PriceOld: '', Images: '/HoaTulip/hoa-sap-tulip-14.png' },
        { Name: 'Bó hoa sáp Tulip 03', Category: 'hoa-tu-lip', Price: '400000', PriceOld: '500000', Images: '/HoaTulip/bo-hoa-sap-tulip-03.png' },
        { Name: 'Hoa sáp Tulip 18', Category: 'hoa-tu-lip', Price: '400000', PriceOld: '', Images: '/HoaTulip/hoa-sap-tulip-18.png' },
        { Name: 'Bó hoa sáp Tulip 01', Category: 'hoa-tu-lip', Price: '420000', PriceOld: '520000', Images: '/HoaTulip/bo-hoa-sap-tulip-01.png' },
        { Name: 'Bó hoa sáp Tulip 02', Category: 'hoa-tu-lip', Price: '420000', PriceOld: '520000', Images: '/HoaTulip/bo-hoa-sap-tulip-02.png' },
        { Name: 'Bó hoa Tulip 42', Category: 'hoa-tu-lip', Price: '750000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-42.png' },
        { Name: 'Bó hoa Tulip 43', Category: 'hoa-tu-lip', Price: '750000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-43.jpg' },
        { Name: 'Bó hoa Tulip 58', Category: 'hoa-tu-lip', Price: '780000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-58.png' },
        { Name: 'Bó hoa Tulip 50', Category: 'hoa-tu-lip', Price: '790000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-50.png' },
        { Name: 'Hoa sáp Tulip 10', Category: 'hoa-tu-lip', Price: '800000', PriceOld: '', Images: '/HoaTulip/hoa-sap-tulip-10.png' },
        { Name: 'Hoa sáp Tulip 11', Category: 'hoa-tu-lip', Price: '800000', PriceOld: '', Images: '/HoaTulip/hoa-sap-tulip-11.png' },
        { Name: 'Bó hoa Tulip 25', Category: 'hoa-tu-lip', Price: '800000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-25.png' },
        { Name: 'Bó hoa Tulip 27', Category: 'hoa-tu-lip', Price: '800000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-27.png' },
        { Name: 'Bó hoa Tulip 21', Category: 'hoa-tu-lip', Price: '850000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-21.png' },
        { Name: 'Bó hoa Tulip 24', Category: 'hoa-tu-lip', Price: '850000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-24.png' },
        { Name: 'Bó hoa Tulip 59', Category: 'hoa-tu-lip', Price: '850000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-59.png' },
        { Name: 'Bó hoa Tulip 51', Category: 'hoa-tu-lip', Price: '890000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-51.png' },
        { Name: 'Bó hoa Tulip 28', Category: 'hoa-tu-lip', Price: '950000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-28.png' },
        { Name: 'Bó hoa Tulip 30', Category: 'hoa-tu-lip', Price: '950000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-30.png' },
        { Name: 'Bó hoa Tulip 44', Category: 'hoa-tu-lip', Price: '950000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-44.png' },
        { Name: 'Bó hoa Tulip 45', Category: 'hoa-tu-lip', Price: '950000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-45.png' },
        { Name: 'Bó hoa Tulip 26', Category: 'hoa-tu-lip', Price: '1050000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-26.png' },
        { Name: 'Bó hoa Tulip 31', Category: 'hoa-tu-lip', Price: '1050000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-31.png' },
        { Name: 'Bó hoa Tulip 34', Category: 'hoa-tu-lip', Price: '1250000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-34.png' },
        { Name: 'Bó hoa Tulip 36', Category: 'hoa-tu-lip', Price: '1250000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-36.png' },
        { Name: 'Bó hoa Tulip 47', Category: 'hoa-tu-lip', Price: '1250000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-47.png' },
        { Name: 'Bó hoa Tulip 54', Category: 'hoa-tu-lip', Price: '1250000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-54.png' },
        { Name: 'Bó hoa Tulip 23', Category: 'hoa-tu-lip', Price: '1450000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-23.png' },
        { Name: 'Bó hoa Tulip 32', Category: 'hoa-tu-lip', Price: '1450000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-32.png' },
        { Name: 'Bó hoa Tulip 52', Category: 'hoa-tu-lip', Price: '1450000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-52.png' },
        { Name: 'Bó hoa Tulip 53', Category: 'hoa-tu-lip', Price: '1450000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-53.png' },
        { Name: 'Bó hoa Tulip 33', Category: 'hoa-tu-lip', Price: '1550000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-33.png' },
        { Name: 'Bó hoa Tulip 35', Category: 'hoa-tu-lip', Price: '1550000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-35.png' },
        { Name: 'Bó hoa Tulip 37', Category: 'hoa-tu-lip', Price: '1550000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-37.png' },
        { Name: 'Bó hoa Tulip 48', Category: 'hoa-tu-lip', Price: '2150000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-48.png' },
        { Name: 'Bó hoa Tulip 49', Category: 'hoa-tu-lip', Price: '2500000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-49.png' },
        { Name: 'Bó hoa Tulip 41', Category: 'hoa-tu-lip', Price: '3000000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-41.png' },
        { Name: 'Bó hoa Tulip 38', Category: 'hoa-tu-lip', Price: '4500000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-38.png' },
        { Name: 'Hoa Tulip 40', Category: 'hoa-tu-lip', Price: '4500000', PriceOld: '', Images: '/HoaTulip/hoa-tulip-40.png' },
        { Name: 'Bó hoa Tulip 39', Category: 'hoa-tu-lip', Price: '5500000', PriceOld: '', Images: '/HoaTulip/bo-hoa-tulip-39.png' },
        // Xong
        // Hoa Lan
        { Name: 'Tình Yêu Màu Nắng', Price: '850000', Category: 'hoa-lan', PriceOld: '916000', Images: '/HopHoaTuoi/8819_tinh-yeu-mau-nang.jpg' },
        { Name: 'Năng lượng tràn đầy', Price: '1500000', Category: 'hoa-lan', PriceOld: '1550000', Images: '/HopHoaTuoi/10906_nang-luong-tran-day.jpg' },
        { Name: 'My dear', Price: '900000', Category: 'hoa-lan', PriceOld: '', Images: '/HopHoaTuoi/8369_my-dear.jpg' },
        { Name: 'Hoa tặng sự kiện 8 (+20 bó)', Price: '360000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaLan/10904_hoa-tang-su-kien---bo.png' },
        { Name: 'Sắc cam rực rỡ', Category: 'hoa-lan', Price: '300000', PriceOld: '', Images: '/BoHoa/11041_sac-cam-ruc-ro.jpg' },
        { Name: 'Sun flower', Price: '600000', Category: 'hoa-lan', PriceOld: '720000', Images: '/HopHoaTuoi/8040_sun-flower.png' },
        { Name: 'Tự tình', Price: '800000', Category: 'hoa-lan', PriceOld: '', Images: '/HopHoaTuoi/11584_tu-tinh.jpg' },
        { Name: 'La vie en rose', Price: '1100000', Category: 'hoa-lan', PriceOld: '', Images: '/HopHoaTuoi/10686_la-vie-en-rose.png' },
        { Name: 'Tiếp bước', Price: '2000000', Category: 'hoa-lan', PriceOld: '1700000', Images: '/HopHoaTuoi/11567_tiep-buoc.png' },
        { Name: 'Bright sky', Price: '850000', Category: 'hoa-lan', PriceOld: '', Images: '/HopHoaTuoi/8042_bright-sky.jpg' },
        { Name: 'Sự sống', Price: '1500000', Category: 'hoa-lan', PriceOld: '1700000', Images: '/HopHoaTuoi/4695_su-song.jpg' },
        { Name: 'Scarlet', Price: '1650000', Category: 'hoa-lan', PriceOld: '1731481', Images: '/HoaChucMung/8141_scarlet.png' },
        { Name: 'Thắng lợi', Price: '750000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaLan/4049_thang-loi.jpg' },
        { Name: 'Gần bên em', Price: '550000', Category: 'hoa-lan', PriceOld: '', Images: '/GioHoaTuoi/8832_gan-ben-em.jpg' },
        { Name: 'Vươn cao', Price: '1500000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaLan/11031_vuon-cao.png' },
        { Name: 'Khúc Ca Khải Hoàn', Price: '2300000', Category: 'hoa-lan', PriceOld: '', Images: '/BoHoaLy/5644_khuc-ca-khai-hoan.jpg' },
        { Name: 'Phát Đạt', Price: '1500000', Category: 'hoa-lan', PriceOld: '', Images: '/BoHoaLy/5187_phat-dat.jpg' },
        { Name: 'Mạnh Mẽ Tiến Bước', Price: '2000000', Category: 'hoa-lan', PriceOld: '', Images: '/BoHoaLy/5277_manh-me-tien-buoc.jpg' },
        { Name: 'Vạn Sự Hanh Thông 3', Price: '2500000', Category: 'hoa-lan', PriceOld: '2700000', Images: '/HoaChucMung/2091_van-su-hanh-thong.jpg' },
        { Name: 'Khởi đầu thuận lợi', Price: '4000000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChucMung/11571_khoi-dau-thuan-loi.png' },
        { Name: 'Tương Lai Tươi Sáng', Price: '2000000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChucMung/6910_tuong-lai-tuoi-sang.png' },
        { Name: 'Thành Công Viên Mãn', Price: '2900000', Category: 'hoa-lan', PriceOld: '', Images: '/BoHoaLy/5442_thanh-cong-vien-man.png' },
        { Name: 'Đại hồng phát', Price: '4500000', Category: 'hoa-lan', PriceOld: '', Images: '/BoHoaDongTien/11030_dai-hong-phat.png' },
        { Name: 'Vạn sự tốt đẹp', Price: '4600000', Category: 'hoa-lan', PriceOld: '5060000', Images: '/HoaChucMung/11573_van-su-tot-dep.jpg' },
        { Name: 'Tầm Cao Mới', Price: '1500000', Category: 'hoa-lan', PriceOld: '', Images: '/BoHoaDongTien/7174_tam-cao-moi.png' },
        { Name: 'Thành Đạt', Price: '1200000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChucMung/5263_thanh-dat.jpg' },
        { Name: 'Lucky Flower', Price: '1500000', Category: 'hoa-lan', PriceOld: '', Images: '/BoHoaDongTien/7229_lucky-flower.png' },
        { Name: 'Khởi sắc thành công', Price: '4500000', Category: 'hoa-lan', PriceOld: '4950000', Images: '/HoaChucMung/11033_khoi-sac-thanh-cong.png' },
        { Name: 'Nỗ lực tiến bước', Price: '4500000', Category: 'hoa-lan', PriceOld: '5000000', Images: '/HoaChucMung/11570_no-luc-tien-buoc.png' },
        { Name: 'Đại Cát', Price: '1800000', Category: 'hoa-lan', PriceOld: '', Images: '/BoHoaLy/9408_dai-cat.png' },
        { Name: 'Vinh quang 3', Price: '2500000', Category: 'hoa-lan', PriceOld: '', Images: '/BoHoaLy/4343_vinh-quang.jpg' },
        { Name: 'Thành công 4', Price: '2500000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChucMung/3856_thanh-cong.jpg' },
        { Name: 'Lộc Phát', Price: '1800000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChucMung/4940_loc-phat.jpg' },
        { Name: 'Sự Nghiệp Tấn Tới', Price: '4500000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChucMung/5303_su-nghiep-tan-toi.jpg' },
        { Name: 'Đại Cát Đại Lợi 2', Price: '2200000', Category: 'hoa-lan', PriceOld: '', Images: '/BoHoaLy/2489_dai-cat-dai-loi.jpg' },
        { Name: 'Road To Success', Price: '2500000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaLan/5192_road-to-success.jpg' },
        { Name: 'Khai Trương Lộc Phát', Price: '2000000', Category: 'hoa-lan', PriceOld: '', Images: '/BoHoaHuongDuong/5403_khai-truong-loc-phat.jpg' },
        { Name: 'Dẫn Lối Thành Công', Price: '4000000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaLan/6912_dan-loi-thanh-cong.png' },
        { Name: 'Công Danh Phú Quý', Price: '1500000', Category: 'hoa-lan', PriceOld: '', Images: '/BoHoaCuc/5401_cong-danh-phu-quy.jpg' },
        { Name: 'Giã Biệt 1', Price: '2500000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/3013_gia-biet.jpg' },
        { Name: 'Tiễn Biệt 2', Price: '1100000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/3113_tien-biet.jpg' },
        { Name: 'Tiếc thương 2', Price: '4000000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/8399_tiec-thuong.png' },
        { Name: 'Kí ức xưa', Price: '1000000', Category: 'hoa-lan', PriceOld: '1100000', Images: '/HoaChiaBuon/8390_ki-uc-xua.png' },
        { Name: 'Lặng Lẽ', Price: '1600000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/9452_lang-le.png' },
        { Name: 'Purple blooming', Price: '850000', Category: 'hoa-lan', PriceOld: '900000', Images: '/GioHoaTuoi/12347_purple-blooming.jpg' },
        { Name: 'An lạc', Price: '1800000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/10965_an-lac.png' },
        { Name: 'Vĩnh biệt 2', Price: '1200000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/3064_vinh-biet.jpg' },
        { Name: 'Cõi an yên', Price: '2000000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/11616_coi-an-yen.jpg' },
        { Name: 'Heaven', Price: '3300000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/5127_heaven.jpg' },
        { Name: 'Ký Ức', Price: '2300000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/5218_ky-uc.jpg' },
        { Name: 'Sương Trắng', Price: '2200000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/6950_suong-trang.png' },
        { Name: 'Miền Cực Lạc', Price: '1250000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/5051_mien-cuc-lac.jpg' },
        { Name: 'Vô Thường', Price: '1300000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/5203_vo-thuong.jpg' },
        { Name: 'Hạ trắng', Price: '1200000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/4507_ha-trang.jpg' },
        { Name: 'Yên Nghỉ', Price: '2700000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/5260_yen-nghi.jpg' },
        { Name: 'Giọt mưa sa', Price: '2500000', Category: 'hoa-lan', PriceOld: '3055556', Images: '/HoaChiaBuon/4409_giot-mua-sa.jpg' },
        { Name: 'Luyến Tiếc 4', Price: '1500000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/2485_luyen-tiec.jpg' },
        { Name: 'Mãi nhớ', Price: '2000000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/4353_mai-nho.jpg' },
        { Name: 'Hạc Trắng 3', Price: '1450000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/2663_hac-trang.jpg' },
        { Name: 'Cát bụi', Price: '1200000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/4344_cat-bui.jpg' },
        { Name: 'Thiên thu', Price: '1550000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/4412_thien-thu.jpg' },
        { Name: 'Phù du', Price: '1500000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/4672_phu-du.jpg' },
        { Name: 'Great Achievement', Category: 'hoa-lan', Price: '1200000', PriceOld: '', Images: '/HoaChucMung/12504_great-achievement.jpg' },
        { Name: 'Yellow mokara vase', Category: 'hoa-lan', Price: '1800000', PriceOld: '', Images: '/HoaLan/12495_yellow-mokara-vase.jpg' },
        { Name: 'Red mokara vase', Category: 'hoa-lan', Price: '1800000', PriceOld: '', Images: '/HoaLan/12494_red-mokara-vase.jpg' },
        { Name: 'Luxury vase 9', Price: '2200000', Category: 'hoa-lan', PriceOld: '', Images: '/BoHoaCuc/12498_luxury-vase.jpg' },
        { Name: 'Hợp tan', Price: '1900000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/12487_hop-tan.jpg' },
        { Name: 'Nguyện cầu', Price: '1500000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/12489_nguyen-cau.jpg' },
        { Name: 'Tâm Thành', Price: '1500000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/12500_tam-thanh.jpg' },
        { Name: 'Ước Nguyện', Price: '1500000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/12501_uoc-nguyen.jpg' },
        { Name: 'Như Mây Trời', Price: '1900000', Category: 'hoa-lan', PriceOld: '2037037', Images: '/HoaChiaBuon/12502_nhu-may-troi.jpg' },
        { Name: 'Nhân sinh', Price: '1700000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/12503_nhan-sinh.jpg' },
        { Name: 'Dancing lady', Price: '4000000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaLan/12668_dancing-lady.jpg' },
        { Name: 'Luxury vase 19', Category: 'hoa-lan', Price: '4500000', PriceOld: '', Images: '/BoHoaCuc/12671_luxury-vase.jpg' },
        { Name: 'Hư không 2', Price: '2000000', Category: 'hoa-lan', PriceOld: '2240741', Images: '/HoaChiaBuon/12629_hu-khong.jpg' },
        { Name: 'With deepest sympathy', Price: '11000000', Category: 'hoa-lan', PriceOld: '11712000', Images: '/HoaLan/12996_with-deepest-sympathy.jpg' },
        { Name: 'Deepest Condolences', Price: '13000000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaLan/12995_deepest-condolences.jpg' },
        { Name: 'Kệ Chia Buồn 01', Price: '600000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/13143_ke-chia-buon.jpg' },
        { Name: 'Kệ Chia Buồn 05', Price: '700000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/13147_ke-chia-buon.jpg' },
        { Name: 'Kệ Chúc Mừng 02', Category: 'hoa-lan', Price: '950000', PriceOld: '1100000', Images: '/HoaChucMung/13150_ke-chuc-mung.jpg' },
        { Name: 'Kệ Chúc Mừng 04', Price: '1200000', Category: 'hoa-lan', PriceOld: '1470000', Images: '/BoHoaDongTien/13152_ke-chuc-mung.jpg' },
        { Name: 'Em về tinh khôi', Price: '1100000', Category: 'hoa-lan', PriceOld: '', Images: '/HopHoaTuoi/13191_em-ve-tinh-khoi.jpg' },
        { Name: 'Thương ngày nắng về', Price: '1500000', Category: 'hoa-lan', PriceOld: '1629000', Images: '/HoaLan/13303_thuong-ngay-nang-ve.jpg' },
        { Name: 'Condolence 1', Price: '2500000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/13319_condolence.png' },
        { Name: 'Condolence 2', Price: '1500000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/13322_condolence.jpg' },
        { Name: 'Condolence 3', Price: '2000000', Category: 'hoa-lan', PriceOld: '', Images: '/HoaChiaBuon/13318_condolence.jpg' },
        { Name: 'Ngày cuối tuần 2', Price: '700000', Category: 'hoa-lan', PriceOld: '800000', Images: '/BoHoaDongTien/13365_ngay-cuoi-tuan.png' },
        { Name: 'Niềm hạnh phúc', Price: '600000', Category: 'hoa-lan', PriceOld: '', Images: '/BoHoaCuc/13366_niem-hanh-phuc.png' },
        { Name: 'An yên', Price: '9500000', Category: 'hoa-lan', PriceOld: '10185185', Images: '/HoaChiaBuon/13402_an-yen.jpg' },
        { Name: 'Congratulations 6', Price: '2900000', Category: 'hoa-lan', PriceOld: '', Images: '/BoHoaCuc/13412_congratulations.jpg' },
        { Name: 'Tỏa sáng 2', Category: 'hoa-lan', Price: '550000', PriceOld: '600000', Images: '/BoHoa/13286_toa-sang.jpg' },
        { Name: 'Color Life 2', Category: 'hoa-lan', Price: '1800000', PriceOld: '2000000', Images: '/GioHoaTuoi/8047_color-life.jpg' },
        { Name: 'Dancing Lady Orchid', Category: 'hoa-lan', Price: '1000000', PriceOld: '', Images: '/BoHoa/13038_dancing-lady-orchid.jpg' },
        { Name: 'Cơn gió lạ', Category: 'hoa-lan', Price: '1000000', PriceOld: '1100000', Images: '/BoHoa/12525_con-gio-la.jpg' },
        { Name: 'Tiền tài như nước', Price: '1500000', Category: 'hoa-lan', PriceOld: '', Images: '/HopHoaTuoi/8021_tien-tai-nhu-nuoc.jpg' },
        { Name: 'Strong belief', Price: '1200000', Category: 'hoa-lan', PriceOld: '', Images: '/HopHoaTuoi/13186_strong-belief.jpg' },
        { Name: 'Lời chúc thành công', Price: '1200000', Category: 'hoa-lan', PriceOld: '', Images: '/BoHoaLy/14346_loi-chuc-thanh-cong.jpg' },
        { Name: 'Điều khác biệt', Category: 'hoa-lan', Price: '2200000', PriceOld: '', Images: '/GioHoaTuoi/14344_dieu-khac-biet.jpg' },
        { Name: 'Gentle Bloom', Price: '1300000', Category: 'hoa-lan', PriceOld: '', Images: '/HopHoaTuoi/14456_gentle-bloom.jpg' },
        { Name: 'Blushing Joy', Price: '500000', Category: 'hoa-lan', PriceOld: '', Images: '/GioHoaTuoi/14450_blushing-joy.jpg' },
        { Name: 'Thiên thanh', Price: '2000000', Category: 'hoa-lan', PriceOld: '2200000', Images: '/HopHoaTuoi/14694_thien-thanh.jpg' },
        { Name: 'Ngọt ngào sắc hoa', Price: '200000', Category: 'hoa-lan', PriceOld: '', Images: '/HopHoaTuoi/14688_ngot-ngao-sac-hoa.jpg' },
        { Name: 'Lightly 2', Price: '1500000', Category: 'hoa-lan', PriceOld: '1700000', Images: '/HopHoaTuoi/14683_lightly.jpg' },
        { Name: 'Lối rẽ', Price: '1800000', Category: 'hoa-lan', PriceOld: '', Images: '/HopHoaTuoi/14679_loi-re.jpg' },
        { Name: 'Premium vase 5', Price: '8000000', Category: 'hoa-lan', PriceOld: '1629000', Images: '/HoaLan/14661_premium-vase.jpg' },
        //Giỏ quà tết
        { Name: 'Giỏ quà tết 27', Price: '500000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-27_300x300.jpg' },
        { Name: 'Giỏ quà tết 31', Price: '600000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-31_300x300.jpg' },
        { Name: 'Giỏ quà tết 17', Price: '650000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-17_300x300.jpg' },
        { Name: 'Giỏ quà tết 24', Price: '650000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-24_300x300.jpg' },
        { Name: 'Giỏ quà tết 26', Price: '650000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-26_300x300.jpg' },
        { Name: 'Giỏ quà tết 30', Price: '750000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-30_300x300.jpg' },
        { Name: 'Giỏ quà tết 32', Price: '750000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-32_300x300.jpg' },
        { Name: 'Giỏ quà tết 14', Price: '790000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-14_300x300.jpg' },
        { Name: 'Giỏ quà tết 18', Price: '790000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-18_300x300.jpg' },
        { Name: 'Giỏ quà tết 05', Price: '800000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-05_300x300.jpg' },
        { Name: 'Giỏ quà tết 07', Price: '800000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-07_300x300.jpg' },
        { Name: 'Giỏ quà tết 25', Price: '800000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-25_300x300.jpg' },
        { Name: 'Giỏ quà tết 34', Price: '800000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-34_300x300.jpg' },
        { Name: 'Giỏ quà tết 38', Price: '800000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-38_300x300.jpg' },
        { Name: 'Giỏ quà tết 10', Price: '850000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-10_300x300.jpg' },
        { Name: 'Giỏ quà tết 36', Price: '850000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-36_300x300.jpg' },
        { Name: 'Giỏ quà tết 13', Price: '890000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-13_300x300.jpg' },
        { Name: 'Giỏ quà tết 04', Price: '900000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-04_300x300.jpg' },
        { Name: 'Giỏ quà tết 08', Price: '900000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-08_300x300.jpg' },
        { Name: 'Giỏ quà tết 15', Price: '900000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-15_300x300.jpg' },
        { Name: 'Giỏ quà tết 43', Price: '900000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-43_300x300.jpg' },
        { Name: 'Giỏ quà tết 16', Price: '950000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-16_1_300x300.jpg' },
        { Name: 'Giỏ quà tết 35', Price: '950000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-35_300x300.jpg' },
        { Name: 'Giỏ quà tết 42', Price: '950000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-42_300x300.jpg' },
        { Name: 'Giỏ quà tết 03', Price: '980000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-03_300x300.jpg' },
        { Name: 'Giỏ quà tết 01', Price: '1050000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-01_300x300.jpg' },
        { Name: 'Giỏ quà tết 41', Price: '1050000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-41_300x300.jpg' },
        { Name: 'Giỏ quà tết 161', Price: '1150000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-16_300x300.jpg' },
        { Name: 'Giỏ quà tết 11', Price: '1250000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-11_300x300.jpg' },
        { Name: 'Giỏ quà tết 45', Price: '1250000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-45_300x300.jpg' },
        { Name: 'Giỏ quà tết 21', Price: '1500000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-21_300x300.jpg' },
        { Name: 'Giỏ quà tết 29', Price: '1500000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-29_300x300.jpg' },
        { Name: 'Giỏ quà tết 19', Price: '1650000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-19_300x300.jpg' },
        { Name: 'Giỏ quà tết 20', Price: '1650000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-20_300x300.jpg' },
        { Name: 'Giỏ quà tết 12', Price: '1700000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-12_300x300.jpg' },
        { Name: 'Giỏ quà tết 02', Price: '1800000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-02_300x300.jpg' },
        { Name: 'Giỏ quà tết 22', Price: '1800000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-22_300x300.jpg' },
        { Name: 'Giỏ quà tết 44', Price: '2500000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-44_300x300.jpg' },
        { Name: 'Giỏ quà tết 39', Price: '3500000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-39_300x300.jpg' },
        { Name: 'Giỏ quà tết 23', Price: '5000000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-23_300x300.jpg' },
        { Name: 'Giỏ quà tết 40', Price: '7000000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-40_300x300.jpg' },
        { Name: 'Giỏ quà tết 46', Price: '1800000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-46_300x300.jpg' },
        { Name: 'Giỏ quà tết 09', Price: '1850000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-09_300x300.jpg' },
        { Name: 'Giỏ quà tết 33', Price: '1850000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-33_300x300.jpg' },
        { Name: 'Giỏ quà tết 28', Price: '2000000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-28_300x300.jpg' },
        { Name: 'Giỏ quà tết 06', Price: '2250000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-06_300x300.jpg' },
        { Name: 'Giỏ quà tết 37', Price: '2300000', Category: 'gio-qua-tet', PriceOld: '', Images: '/GioQuaTet/gio-qua-tet-37_300x300.jpg' },
        // Xong quà tết
        // Gấu bông
        { Name: 'Mèo HT nón lotso 25cm', Price: '190000', Category: 'gau-bong', PriceOld: '', Images: '/GauBong/14416_meo-ht-non-lotso-cm.jpg' },
        { Name: 'Mèo HT nón tai thỏ hồng', Price: '200000', Category: 'gau-bong', PriceOld: '', Images: '/GauBong/14415_meo-ht-non-tai-tho-hong.jpg' },
        { Name: 'Mèo HT nón gấu trúc 25cm', Price: '200000', Category: 'gau-bong', PriceOld: '', Images: '/GauBong/14417_meo-ht-non-gau-truc-cm.jpg' },
        { Name: 'Mèo HT galaxy 25cm', Price: '210000', Category: 'gau-bong', PriceOld: '', Images: '/GauBong/14412_meo-ht-galaxy-cm.jpg' },
        { Name: 'Lena ngồi Lotso 25cm', Price: '195000', Category: 'gau-bong', PriceOld: '', Images: '/GauBong/14413_lena-ngoi-lotso-cm.jpg' },
        { Name: 'Heo nơ ôm trân châu 45cm', Price: '550000', Category: 'gau-bong', PriceOld: '', Images: '/GauBong/14411_heo-no-om-tran-chau-cm.jpg' },
        { Name: 'Thỏ ôm kem đính hoa size 40cm', Price: '265000', Category: 'gau-bong', PriceOld: '', Images: '/GauBong/14410_tho-om-kem-dinh-hoa-size-cm.jpg' },
        { Name: 'Shiba Đầu Hoa Size 35cm', Price: '310000', Category: 'gau-bong', PriceOld: '', Images: '/GauBong/14409_shiba-dau-hoa-size-cm.jpg' },
        { Name: 'Gấu trúc ôm tre hoa 40cm', Price: '450000', Category: 'gau-bong', PriceOld: '', Images: '/GauBong/14408_gau-truc-om-tre-hoa-cm.jpg' },
        { Name: 'White teddy áo đỏ', Price: '150000', Category: 'gau-bong', PriceOld: '180000', Images: '/GauBong/13271_white-teddy-ao-do.jpg' },
        { Name: 'Teddy cá tính size 30', Price: '160000', Category: 'gau-bong', PriceOld: '180000', Images: '/GauBong/14309_teddy-ca-tinh-size.jpg' },
        { Name: 'Mini teddy ILU áo hồng', Price: '180000', Category: 'gau-bong', PriceOld: '200000', Images: '/GauBong/13274_mini-teddy-ilu-ao-hong.jpg' },
        { Name: 'Teddy áo nón nâu', Price: '220000', Category: 'gau-bong', PriceOld: '250000', Images: '/GauBong/11227_teddy-ao-non-nau.jpg' },
        { Name: 'Thỏ cô đơn', Price: '200000', Category: 'gau-bong', PriceOld: '250000', Images: '/GauBong/13612_tho-co-don.jpg' },
        { Name: 'Gấu ngồi yếm xanh', Price: '300000', Category: 'gau-bong', PriceOld: '330000', Images: '/GauBong/11715_gau-ngoi-yem-xanh.jpg' },
        { Name: 'Baby teddy trắng', Price: '350000', Category: 'gau-bong', PriceOld: '380000', Images: '/GauBong/11426_baby-teddy-trang.jpg' },
        { Name: 'Teddy áo nón vàng', Price: '220000', Category: 'gau-bong', PriceOld: '250000', Images: '/GauBong/11229_teddy-ao-non-vang.jpg' },
        { Name: 'Gấu ngực tim xanh', Price: '350000', Category: 'gau-bong', PriceOld: '380000', Images: '/GauBong/11713_gau-nguc-tim-xanh.jpg' },
        { Name: 'Husky xám', Price: '375000', Category: 'gau-bong', PriceOld: '429000', Images: '/GauBong/13615_husky-xam.jpg' },
        { Name: 'Gấu ngồi yếm nâu', Price: '390000', Category: 'gau-bong', PriceOld: '440000', Images: '/GauBong/11719_gau-ngoi-yem-nau.jpg' },
        { Name: 'Hươu váy hồng', Price: '590000', Category: 'gau-bong', PriceOld: '644000', Images: '/GauBong/14290_huou-vay-hong.jpg' },
        { Name: 'Sweetie Bunny', Price: '590000', Category: 'gau-bong', PriceOld: '644000', Images: '/GauBong/11714_sweetie-bunny.jpg' },
        { Name: 'Teddy thân thiện', Price: '300000', Category: 'gau-bong', PriceOld: '350000', Images: '/GauBong/11226_teddy-than-thien.jpg' },
        { Name: 'Gấu ngực tim nâu', Price: '350000', Category: 'gau-bong', PriceOld: '404000', Images: '/GauBong/11712_gau-nguc-tim-nau.jpg' },
        { Name: 'Gấu áo tim kem', Price: '450000', Category: 'gau-bong', PriceOld: '504000', Images: '/GauBong/11718_gau-ao-tim-kem.jpg' },
        { Name: 'Gấu Dano đứng', Price: '500000', Category: 'gau-bong', PriceOld: '554000', Images: '/GauBong/13046_gau-dano-dung.jpg' },
        { Name: 'Nai Peter Nâu Ngồi', Price: '500000', Category: 'gau-bong', PriceOld: '554000', Images: '/GauBong/8683_nai-peter-nau-ngoi.png' },
        { Name: 'Gấu áo tim xanh', Price: '750000', Category: 'gau-bong', PriceOld: '804000', Images: '/GauBong/11707_gau-ao-tim-xanh.jpg' },
        { Name: 'Gấu áo tim hồng', Price: '1000000', Category: 'gau-bong', PriceOld: '1054000', Images: '/GauBong/11708_gau-ao-tim-hong.jpg' },
        { Name: 'Teddy áo tim I loveyou', Price: '500000', Category: 'gau-bong', PriceOld: '554000', Images: '/GauBong/12918_teddy-ao-tim-i-loveyou.jpg' },
        { Name: 'Gấu áo gấu nâu', Price: '825000', Category: 'gau-bong', PriceOld: '879000', Images: '/GauBong/7302_heo-mac-ao-thun.png' },
        { Name: 'Teddy Pink I Love You', Price: '1100000', Category: 'gau-bong', PriceOld: '1154000', Images: '/GauBong/7315_teddy-pink-i-love-you.png' },
        { Name: 'White teddy áo vàng', Price: '150000', Category: 'gau-bong', PriceOld: '180000', Images: '/GauBong/13272_white-teddy-ao-vang.jpg' },
        { Name: 'Mini teddy ILU áo xanh', Price: '180000', Category: 'gau-bong', PriceOld: '200000', Images: '/GauBong/13275_mini-teddy-ilu-ao-xanh.jpg' },
        { Name: 'Gấu cử nhân', Price: '220000', Category: 'gau-bong', PriceOld: '250000', Images: '/GauBong/13270_gau-cu-nhan.jpg' },
        { Name: 'Gấu Pinky Nâu', Price: '500000', Category: 'gau-bong', PriceOld: '554000', Images: '/GauBong/8680_gau-pinky-nau.jpg' },
        { Name: 'Búp bê tóc xoăn đầm đỏ', Price: '650000', Category: 'gau-bong', PriceOld: '704000', Images: '/GauBong/13042_bup-be-toc-xoan-dam-do.jpg' },
        { Name: 'Gấu Ami Gato', Price: '580000', Category: 'gau-bong', PriceOld: '634000', Images: '/GauBong/13739_gau-ami-gato.jpg' },
        { Name: 'Baby teddy nâu', Price: '350000', Category: 'gau-bong', PriceOld: '404000', Images: '/GauBong/11398_baby-teddy-nau.jpg' },
        { Name: 'Búp bê nón phù thủy', Price: '650000', Category: 'gau-bong', PriceOld: '704000', Images: '/GauBong/13043_bup-be-non-phu-thuy.jpg' },
        { Name: 'Rồng Benzamin ngồi', Price: '600000', Category: 'gau-bong', PriceOld: '654000', Images: '/GauBong/12244_rong-benzamin-ngoi.jpg' },
        { Name: 'Teddy cá tính size 47', Price: '385000', Category: 'gau-bong', PriceOld: '439000', Images: '/GauBong/12522_teddy-ca-tinh-size.jpg' },
        { Name: 'Gấu lười', Price: '400000', Category: 'gau-bong', PriceOld: '454000', Images: '/GauBong/11720_gau-luoi.jpg' },
        { Name: 'Teddy Ngực Quả Thơm', Price: '550000', Category: 'gau-bong', PriceOld: '604000', Images: '/GauBong/7974_teddy-nguc-qua-thom.png' },
        { Name: 'Vịt Tai Thỏ', Price: '440000', Category: 'gau-bong', PriceOld: '494000', Images: '/GauBong/7294_vit-tai-tho.png' },
        { Name: 'Teddy Bear áo sọc love xanh', Price: '350000', Category: 'gau-bong', PriceOld: '404000', Images: '/GauBong/11399_teddy-bear-ao-soc-love-xanh.jpg' },
        { Name: 'Chó Shiba Tím', Price: '495000', Category: 'gau-bong', PriceOld: '549000', Images: '/GauBong/7310_cho-shiba-tim.png' },
        { Name: 'Chó Shiba Mặt Cười', Price: '495000', Category: 'gau-bong', PriceOld: '549000', Images: '/GauBong/7297_cho-shiba-mat-cuoi.png' },
        { Name: 'Totoro Áo Totoro', Price: '550000', Category: 'gau-bong', PriceOld: '604000', Images: '/GauBong/7296_totoro-ao-totoro.png' },
        { Name: 'Teddy honey', Price: '320000', Category: 'gau-bong', PriceOld: '374000', Images: '/GauBong/11228_teddy-honey.jpg' },
        { Name: 'Gấu Nâu Xù', Price: '495000', Category: 'gau-bong', PriceOld: '549000', Images: '/GauBong/7291_gau-nau-xu.png' },
        { Name: 'King Kong Nâu', Price: '330000', Category: 'gau-bong', PriceOld: '385000', Images: '/GauBong/7295_king-kong-nau.jpg' },
        { Name: 'Gấu Angel Tím', Price: '495000', Category: 'gau-bong', PriceOld: '549000', Images: '/GauBong/7311_gau-angel-tim.png' },
        { Name: 'Grown Áo Thun', Price: '275000', Category: 'gau-bong', PriceOld: '329000', Images: '/GauBong/7300_grown-ao-thun.png' },
        { Name: 'Rainbown Teddy Bear', Price: '550000', Category: 'gau-bong', PriceOld: '604000', Images: '/GauBong/7299_rainbown-teddy-bear.png' },
        //xong
        // cây cho phòng khách
        { Name: 'Chậu cọ Nhật 1m5', Price: '980000', Category: 'cay-cho-phong-khach', PriceOld: '', Images: '/CayChoPhongKhach/14507_chau-co-nhat-m.jpg' },
        { Name: 'Cây Cọ Nhật', Price: '690000', Category: 'cay-cho-phong-khach', PriceOld: '', Images: '/CayChoPhongKhach/14494_cay-co-nhat.jpg' },
        { Name: 'Bạch mã chậu gốm', Price: '660000', Category: 'cay-cho-phong-khach', PriceOld: '', Images: '/CayChoPhongKhach/12701_bach-ma-chau-gom.jpg' },
        { Name: 'Kim tiền MSTO', Price: '950000', Category: 'cay-cho-phong-khach', PriceOld: '', Images: '/CayChoPhongKhach/9464_kim-tien-msto.jpg' },
        { Name: 'Đế vương xanh MSTO', Price: '1160000', Category: 'cay-cho-phong-khach', PriceOld: '', Images: '/CayChoPhongKhach/8784_de-vuong-xanh-msto.jpg' },
        { Name: 'Chậu cây văn phòng mix 1', Price: '1350000', Category: 'cay-cho-phong-khach', PriceOld: '', Images: '/CayChoPhongKhach/9544_chau-cay-van-phong-mix.jpg' },
        { Name: 'Vạn lộc mix ngọc ngân 1', Price: '650000', Category: 'cay-cho-phong-khach', PriceOld: '712000', Images: '/CayChoPhongKhach/7992_van-loc-mix-ngoc-ngan.jpg' },
        { Name: 'Dương xỉ răng cưa Size S', Price: '770000', Category: 'cay-cho-phong-khach', PriceOld: '', Images: '/CayChoPhongKhach/13566_duong-xi-rang-cua-size-s.jpg' },
        { Name: 'Kim ngân đại chậu gân', Price: '1170000', Category: 'cay-cho-phong-khach', PriceOld: '', Images: '/CayChoPhongKhach/10192_kim-ngan-dai-chau-gan.jpg' },
        { Name: 'Chậu cây văn phòng mix 2', Price: '1300000', Category: 'cay-cho-phong-khach', PriceOld: '1425000', Images: '/CayChoPhongKhach/11085_chau-cay-van-phong-mix.jpg' },
        { Name: 'Chậu cây văn phòng mix 3', Price: '950000', Category: 'cay-cho-phong-khach', PriceOld: '1018000', Images: '/CayChoPhongKhach/9948_chau-cay-van-phong-mix.jpg' },
        { Name: 'Cây Lan Tuyết', Price: '500000', Category: 'cay-cho-phong-khach', PriceOld: '', Images: '/CayChoPhongKhach/13432_cay-lan-tuyet.jpg' },
        { Name: 'Bạch mã hoàng tử 62', Price: '650000', Category: 'cay-cho-phong-khach', PriceOld: '1018000', Images: '/CayChoPhongKhach/8177_bach-ma-hoang-tu.jpg' },
        { Name: 'Kim tiền văn phòng', Price: '690000', Category: 'cay-cho-phong-khach', PriceOld: '8400000', Images: '/CayChoPhongKhach/13434_kim-tien-van-phong.jpg' },
        { Name: 'Đại phú gia 2', Price: '860000', Category: 'cay-cho-phong-khach', PriceOld: '', Images: '/CayChoPhongKhach/13564_dai-phu-gia.jpg' },
        { Name: 'Chậu cây văn phòng mix 4', Price: '1300000', Category: 'cay-cho-phong-khach', PriceOld: '1018000', Images: '/CayChoPhongKhach/13431_chau-cay-van-phong-mix.jpg' },
        { Name: 'ZZ Plant MSP30', Price: '650000', Category: 'cay-cho-phong-khach', PriceOld: '692000', Images: '/CayChoPhongKhach/9947_zz-plant-msp.jpg' },
        { Name: 'Hope MTS28', Price: '560000', Category: 'cay-cho-phong-khach', PriceOld: '614000', Images: '/CayChoPhongKhach/9951_hope-mts.png' },
        { Name: 'Trầu bà MST28', Price: '540000', Category: 'cay-cho-phong-khach', PriceOld: '580000', Images: '/CayChoPhongKhach/8137_trau-ba-mst.png' },
        { Name: 'Đại đế vương xanh MST28', Price: '850000', Category: 'cay-cho-phong-khach', PriceOld: '904000', Images: '/CayChoPhongKhach/11071_dai-de-vuong-xanh-mst.png' },
        { Name: 'Ngọc ngân VP MST28', Price: '535000', Category: 'cay-cho-phong-khach', PriceOld: '580000', Images: '/CayChoPhongKhach/12702_ngoc-ngan-vp-mst.jpg' },
        { Name: 'Lưỡi Hổ MST28', Price: '715000', Category: 'cay-cho-phong-khach', PriceOld: '750000', Images: '/CayChoPhongKhach/8128_luoi-ho-mst.jpg' },
        { Name: 'Trầu Bà Thanh Xuân MSP30', Price: '730000', Category: 'cay-cho-phong-khach', PriceOld: '790000', Images: '/CayChoPhongKhach/9418_trau-ba-thanh-xuan-msp.png' },
        { Name: 'Vạn lộc MST28', Price: '560000', Category: 'cay-cho-phong-khach', PriceOld: '600000', Images: '/CayChoPhongKhach/7833_van-loc-mst.png' },
        { Name: 'Đón lộc MSP30', Price: '670000', Category: 'cay-cho-phong-khach', PriceOld: '730000', Images: '/CayChoPhongKhach/11073_don-loc-msp.png' },
        { Name: 'Kim tiền 30', Price: '670000', Category: 'cay-cho-phong-khach', PriceOld: '730000', Images: '/CayChoPhongKhach/7832_kim-tien.png' },
        // Cây để bàn
        { Name: 'Love family', Price: '440000', Category: 'cay-de-ban', PriceOld: '', Images: '/CayDeBan/11526_love-family.jpg' },
        { Name: 'You are so great', Price: '320000', Category: 'cay-de-ban', PriceOld: '', Images: '/CayDeBan/11057_you-are-so-great.jpg' },
        { Name: 'You are special to me', Price: '295000', Category: 'cay-de-ban', PriceOld: '', Images: '/CayDeBan/8923_you-are-special-to-me.jpg' },
        { Name: 'I can do it', Price: '275000', Category: 'cay-de-ban', PriceOld: '', Images: '/CayDeBan/11871_i-can-do-it.jpg' },
        { Name: 'Mochi Tùng La Hán', Price: '295000', Category: 'cay-de-ban', PriceOld: '', Images: '/CayDeBan/11052_mochi-tung-la-han.jpg' },
        { Name: 'Mochi Kim Ngân Bính', Price: '405000', Category: 'cay-de-ban', PriceOld: '600000', Images: '/CayDeBan/7747_mochi-kim-ngan-binh.jpg' },
        { Name: 'Mochi Sen Đá Mix', Price: '300000', Category: 'cay-de-ban', PriceOld: '', Images: '/CayDeBan/11158_mochi-sen-da-mix.jpg' },
        { Name: 'Mochi Lưỡi Hổ', Price: '270000', Category: 'cay-de-ban', PriceOld: '', Images: '/CayDeBan/7740_mochi-luoi-ho.jpg' },
        { Name: 'Mochi Bàng Sing Mini', Price: '280000', Category: 'cay-de-ban', PriceOld: '', Images: '/CayDeBan/7753_mochi-bang-sing-mini.jpg' },
        { Name: 'Mochi trầu bà kim cương', Price: '310000', Category: 'cay-de-ban', PriceOld: '', Images: '/CayDeBan/7748_mochi-trau-ba-kim-cuong.jpg' },
        { Name: 'Mochi xương rồng mix', Price: '270000', Category: 'cay-de-ban', PriceOld: '', Images: '/CayDeBan/7746_mochi-xuong-rong-mix.jpg' },
        { Name: 'Everyday with you', Price: '385000', Category: 'cay-de-ban', PriceOld: '585000', Images: '/CayDeBan/8204_everyday-with-you.jpg' },
        { Name: 'Bình yên và hạnh phúc', Price: '434000', Category: 'cay-de-ban', PriceOld: '', Images: '/CayDeBan/11261_binh-yen-va-hanh-phuc.jpg' },
        { Name: 'Lí do bắt đầu', Price: '255000', Category: 'cay-de-ban', PriceOld: '730000', Images: '/CayDeBan/11056_li-do-bat-dau.jpg' },
        { Name: 'Vượt thử thách', Price: '260000', Category: 'cay-de-ban', PriceOld: '', Images: '/CayDeBan/13556_vuot-thu-thach.jpg' },
        { Name: 'Give what you want', Price: '220000', Category: 'cay-de-ban', PriceOld: '', Images: '/CayDeBan/13561_give-what-you-want.jpg' },
        { Name: 'Không gì là không thể', Price: '320000', Category: 'cay-de-ban', PriceOld: '374000', Images: '/CayDeBan/11165_khong-gi-la-khong-the.jpg' },
        { Name: 'Make me smile everyday', Price: '280000', Category: 'cay-de-ban', PriceOld: '334000', Images: '/CayDeBan/11165_khong-gi-la-khong-the.jpg' },
        { Name: 'Dont quit 2', Price: '245000', Category: 'cay-de-ban', PriceOld: '299000', Images: '/CayDeBan/7819_dont-quit.png' },
        { Name: 'Happy day 12', Price: '445000', Category: 'cay-de-ban', PriceOld: '299000', Images: '/CayDeBan/9953_happy-day.jpg' },
        { Name: 'Happy day 11', Price: '270000', Category: 'cay-de-ban', PriceOld: '324000', Images: '/CayDeBan/7599_happy-day.jpg' },
        { Name: 'Happy day 4', Price: '280000', Category: 'cay-de-ban', PriceOld: '305000', Images: '/CayDeBan/12762_happy-day.jpg' },
        { Name: 'Happy day 1', Price: '280000', Category: 'cay-de-ban', PriceOld: '315000', Images: '/CayDeBan/12759_happy-day.jpg' },
        { Name: 'Happy day 14', Price: '350000', Category: 'cay-de-ban', PriceOld: '387000', Images: '/CayDeBan/12894_happy-day.jpg' },
        { Name: 'Nhà Là Nơi Có Mami', Price: '280000', Category: 'cay-de-ban', PriceOld: '334000', Images: '/CayDeBan/10227_nha-la-noi-co-mami.png' },
        { Name: 'Strong Woman', Price: '190000', Category: 'cay-de-ban', PriceOld: '209000', Images: '/CayDeBan/10221_strong-woman.png' },
        { Name: 'Mom In My Heart', Price: '245000', Category: 'cay-de-ban', PriceOld: '269000', Images: '/CayDeBan/10228_mom-in-my-heart.png' },
        { Name: 'Make you smile', Price: '180000', Category: 'cay-de-ban', PriceOld: '198000', Images: '/CayDeBan/11523_make-you-smile.jpg' },
        { Name: 'Mẹ Là Nhất', Price: '285000', Category: 'cay-de-ban', PriceOld: '339000', Images: '/CayDeBan/11483_me-la-nhat.jpg' },
        { Name: 'I love you 3000', Price: '220000', Category: 'cay-de-ban', PriceOld: '240000', Images: '/CayDeBan/8199_i-love-you.jpg' },
        { Name: 'Never give up', Price: '190000', Category: 'cay-de-ban', PriceOld: '244000', Images: '/CayDeBan/7751_never-give-up.png' },
        { Name: 'Công việc thành đạt 1', Price: '230000', Category: 'cay-de-ban', PriceOld: '284000', Images: '/CayDeBan/7804_cong-viec-thanh-dat.png' },
        { Name: 'Believe In Yourself', Price: '300000', Category: 'cay-de-ban', PriceOld: '355000', Images: '/CayDeBan/11488_believe-in-yourself.jpg' },
        { Name: 'Million dreams 2', Price: '210000', Category: 'cay-de-ban', PriceOld: '300000', Images: '/CayDeBan/7801_million-dreams.png' },
        { Name: 'Be okay', Price: '235000', Category: 'cay-de-ban', PriceOld: '344000', Images: '/CayDeBan/7725_be-okay.png' },
        { Name: 'Team together', Price: '290000', Category: 'cay-de-ban', PriceOld: '344000', Images: '/CayDeBan/7755_team-together.png' },
        { Name: 'Tấn Lộc Thịnh Vượng', Price: '275000', Category: 'cay-de-ban', PriceOld: '329000', Images: '/CayDeBan/11154_tan-loc-thinh-vuong.jpg' },
        { Name: 'Phúc an khang 2', Price: '350000', Category: 'cay-de-ban', PriceOld: '404000', Images: '/CayDeBan/11067_phuc-an-khang.jpg' },
        { Name: 'Kim ngân bính tài lộc', Price: '475000', Category: 'cay-de-ban', PriceOld: '529000', Images: '/CayDeBan/7773_kim-ngan-binh-tai-loc.jpg' },
        { Name: 'Tùng la hán tài lộc', Price: '360000', Category: 'cay-de-ban', PriceOld: '414000', Images: '/CayDeBan/13562_tung-la-han-tai-loc.jpg' },
        { Name: 'May mắn đầu năm', Price: '280000', Category: 'cay-de-ban', PriceOld: '334000', Images: '/CayDeBan/11055_may-man-dau-nam.jpg' },
        { Name: 'Tiểu trâm như ý', Price: '190000', Category: 'cay-de-ban', PriceOld: '244000', Images: '/CayDeBan/13557_tieu-tram-nhu-y.jpg' },
        { Name: 'Như Ý', Price: '500000', Category: 'cay-de-ban', PriceOld: '544000', Images: '/CayDeBan/7771_nhu-y.jpg' },
        { Name: 'Hạnh phúc tài lộc', Price: '480000', Category: 'cay-de-ban', PriceOld: '534000', Images: '/CayDeBan/11063_hanh-phuc-tai-loc.jpg' },
        { Name: 'Nữ Hoàng Của Anh', Price: '330000', Category: 'cay-de-ban', PriceOld: '363000', Images: '/CayDeBan/10393_nu-hoang-cua-anh.png' },
        { Name: 'Yêu Bản Thân', Price: '250000', Category: 'cay-de-ban', PriceOld: '275000', Images: '/CayDeBan/11484_yeu-ban-than.jpg' },
        { Name: 'Successful women 1', Price: '235000', Category: 'cay-de-ban', PriceOld: '258000', Images: '/CayDeBan/7800_successful-women.png' },
        { Name: 'Lets Be The Queen', Price: '280000', Category: 'cay-de-ban', PriceOld: '308000', Images: '/CayDeBan/10229_lets-be-the-queen.png' },
        { Name: 'Im A Queen', Price: '390000', Category: 'cay-de-ban', PriceOld: '429000', Images: '/CayDeBan/10242_im-a-queen.png' },
        { Name: 'Believe in yourself', Price: '260000', Category: 'cay-de-ban', PriceOld: '314000', Images: '/CayDeBan/7754_believe-in-yourself.jpg' },
        { Name: 'Need you', Price: '235000', Category: 'cay-de-ban', PriceOld: '289000', Images: '/CayDeBan/7805_need-you.png' },
        { Name: 'Go old together', Price: '390000', Category: 'cay-de-ban', PriceOld: '334000', Images: '/CayDeBan/12908_go-old-together.jpg' },
        { Name: 'Happy day 7', Price: '280000', Category: 'cay-de-ban', PriceOld: '299000', Images: '/CayDeBan/10051_happy-day.jpg' },
        { Name: 'Happy day 13', Price: '245000', Category: 'cay-de-ban', PriceOld: '299000', Images: '/CayDeBan/11803_happy-day.jpg' },
        { Name: 'Happy day 8', Price: '245000', Category: 'cay-de-ban', PriceOld: '365000', Images: '/CayDeBan/10045_happy-day.jpg' },
        { Name: 'Happy day 19', Price: '320000', Category: 'cay-de-ban', PriceOld: '730000', Images: '/CayDeBan/12895_happy-day.jpg' },
        { Name: 'Happy day 15', Price: '310000', Category: 'cay-de-ban', PriceOld: '450000', Images: '/CayDeBan/12838_happy-day.jpg' },
        { Name: 'Happy day 16', Price: '250000', Category: 'cay-de-ban', PriceOld: '275000', Images: '/CayDeBan/12896_happy-day.jpg' },
        { Name: 'Mừng sinh nhật', Price: '245000', Category: 'cay-de-ban', PriceOld: '299000', Images: '/CayDeBan/8203_mung-sinh-nhat.png' },
        { Name: 'Gorgeous', Price: '270000', Category: 'cay-de-ban', PriceOld: '324000', Images: '/CayDeBan/10238_gorgeous.png' },
        { Name: 'Be awesome', Price: '245000', Category: 'cay-de-ban', PriceOld: '300000', Images: '/CayDeBan/8235_be-awesome.jpg' },
        { Name: 'Never Fails', Price: '245000', Category: 'cay-de-ban', PriceOld: '300000', Images: '/CayDeBan/7758_never-fails.png' },
        { Name: 'Love you', Price: '330000', Category: 'cay-de-ban', PriceOld: '384000', Images: '/CayDeBan/7926_love-you.jpg' },
        { Name: 'Im will be with you', Price: '245000', Category: 'cay-de-ban', PriceOld: '299000', Images: '/CayDeBan/7929_im-will-be-with-you.jpg' },
        { Name: 'Nắng ấm bên nhau', Price: '280000', Category: 'cay-de-ban', PriceOld: '344000', Images: '/CayDeBan/7910_nang-am-ben-nhau.jpg' },
        { Name: 'Cùng Nhau Già Đi', Price: '235000', Category: 'cay-de-ban', PriceOld: '289000', Images: '/CayDeBan/10391_cung-nhau-gia-di.png' },
        { Name: 'Là Chính Mình', Price: '260000', Category: 'cay-de-ban', PriceOld: '286000', Images: '/CayDeBan/10232_la-chinh-minh.png' },
        { Name: 'Play nice', Price: '330000', Category: 'cay-de-ban', PriceOld: '384000', Images: '/CayDeBan/7813_play-nice.png' },
        { Name: 'Mãi xanh', Price: '520000', Category: 'cay-de-ban', PriceOld: '574000', Images: '/CayDeBan/11525_mai-xanh.jpg' },
        { Name: 'Gia Đình', Price: '285000', Category: 'cay-de-ban', PriceOld: '339000', Images: '/CayDeBan/10211_gia-dinh.png' },
        { Name: 'Đường về nhà', Price: '310000', Category: 'cay-de-ban', PriceOld: '364000', Images: '/CayDeBan/11264_duong-ve-nha.jpg' },
        { Name: 'Hồng môn cát tường', Price: '420000', Category: 'cay-de-ban', PriceOld: '474000', Images: '/CayDeBan/11738_hong-mon-cat-tuong.jpg' },
        { Name: 'Tiểu quỳnh', Price: '190000', Category: 'cay-de-ban', PriceOld: '244000', Images: '/CayDeBan/13541_tieu-quynh.jpg' },
        { Name: 'Never quit', Price: '220000', Category: 'cay-de-ban', PriceOld: '274000', Images: '/CayDeBan/7810_never-quit.png' },
        { Name: 'Lá xanh', Price: '280000', Category: 'cay-de-ban', PriceOld: '334000', Images: '/CayDeBan/7802_la-xanh.png' },
        { Name: 'Keep calm and believe', Price: '250000', Category: 'cay-de-ban', PriceOld: '275000', Images: '/CayDeBan/8242_keep-calm-and-believe.jpg' },
        { Name: 'Hồng môn may mắn', Price: '405000', Category: 'cay-de-ban', PriceOld: '459000', Images: '/CayDeBan/11737_hong-mon-may-man.jpg' },
        { Name: 'Be happy', Price: '330000', Category: 'cay-de-ban', PriceOld: '384000', Images: '/CayDeBan/7922_be-happy.png' },
        { Name: 'My Family', Price: '200000', Category: 'cay-de-ban', PriceOld: '254000', Images: '/CayDeBan/10220_my-family.png' },
        { Name: 'Hãy Là Chính Mình', Price: '180000', Category: 'cay-de-ban', PriceOld: '234000', Images: '/CayDeBan/10351_hay-la-chinh-minh.png' },
        { Name: 'Cuteness overload', Price: '235000', Category: 'cay-de-ban', PriceOld: '289000', Images: '/CayDeBan/7960_cuteness-overload.png' },
        { Name: 'Yêu Đời', Price: '405000', Category: 'cay-de-ban', PriceOld: '479000', Images: '/CayDeBan/10241_yeu-doi.png' },
        { Name: 'Million dreams 1', Price: '280000', Category: 'cay-de-ban', PriceOld: '295000', Images: '/CayDeBan/7808_million-dreams.png' },
        { Name: 'Dont quit 1', Price: '280000', Category: 'cay-de-ban', PriceOld: '295000', Images: '/CayDeBan/7811_dont-quit.png' },
        { Name: 'My Sunshine', Price: '290000', Category: 'cay-de-ban', PriceOld: '334000', Images: '/CayDeBan/11486_my-sunshine.jpg' },
        { Name: 'Đừng Bỏ Cuộc', Price: '350000', Category: 'cay-de-ban', PriceOld: '404000', Images: '/CayDeBan/8568_dung-bo-cuoc.jpg' },
        { Name: 'Mơ Ước Lớn', Price: '270000', Category: 'cay-de-ban', PriceOld: '324000', Images: '/CayDeBan/8938_mo-uoc-lon.jpg' },
        { Name: 'The Best Is Yet To Come', Price: '280000', Category: 'cay-de-ban', PriceOld: '345000', Images: '/CayDeBan/8937_the-best-is-yet-to-come.jpg' },
        { Name: 'Home Is Where The Heart', Price: '270000', Category: 'cay-de-ban', PriceOld: '324000', Images: '/CayDeBan/8911_home-is-where-the-heart.jpg' },
        { Name: 'Ông Bà Anh', Price: '390000', Category: 'cay-de-ban', PriceOld: '456000', Images: '/CayDeBan/8583_ong-ba-anh.jpg' },
        { Name: 'Best Friends', Price: '300000', Category: 'cay-de-ban', PriceOld: '354000', Images: '/CayDeBan/8582_best-friends.jpg' },
        { Name: 'The Best', Price: '260000', Category: 'cay-de-ban', PriceOld: '314000', Images: '/CayDeBan/8565_the-best.jpg' },
        { Name: 'Luôn Chiến Đấu', Price: '345000', Category: 'cay-de-ban', PriceOld: '399000', Images: '/CayDeBan/8561_luon-chien-dau.jpg' },
        { Name: 'Nỗ Lực Hơn', Price: '200000', Category: 'cay-de-ban', PriceOld: '224000', Images: '/CayDeBan/8559_no-luc-hon.jpg' },
        { Name: 'Làm ngay đi', Price: '230000', Category: 'cay-de-ban', PriceOld: '244000', Images: '/CayDeBan/8255_lam-ngay-di.jpg' },
        { Name: 'The cat', Price: '245000', Category: 'cay-de-ban', PriceOld: '299000', Images: '/CayDeBan/8197_the-cat.png' },
        { Name: 'Chiếc lá', Price: '280000', Category: 'cay-de-ban', PriceOld: '295000', Images: '/CayDeBan/8253_chiec-la.jpg' },
        { Name: 'Thử thách', Price: '250000', Category: 'cay-de-ban', PriceOld: '275000', Images: '/CayDeBan/8248_thu-thach.jpg' },
        { Name: 'Will be okay', Price: '240000', Category: 'cay-de-ban', PriceOld: '294000', Images: '/CayDeBan/8246_will-be-okay.jpg' },
        // Xong
        // Hoa cưới
        { Name: 'Bên nhau mãi mãi', Category: "hoa-cuoi", Price: '1100000', PriceOld: "", Images: '/HoaCuoi/ben-nhau-mai-mai.jpg' },
        { Name: 'Bên nhau trọn đời', Category: "hoa-cuoi", Price: '880000', PriceOld: "", Images: '/HoaCuoi/ben-nhau-tron-doi.jpg' },
        { Name: 'Cherish day', Category: "hoa-cuoi", Price: '650000', PriceOld: "", Images: '/HoaCuoi/cherish-day.png' },
        { Name: 'Chung bước', Category: "hoa-cuoi", Price: '700000', PriceOld: "", Images: '/HoaCuoi/chung-buoc.png' },
        { Name: 'Chuyện trăm năm', Category: "hoa-cuoi", Price: '800000', PriceOld: "", Images: '/HoaCuoi/chuyen-tram-nam.png' },
        { Name: 'Đôi cánh tình yêu', Category: "hoa-cuoi", Price: '3362000', PriceOld: "", Images: '/HoaCuoi/doi-canh-tinh-yeu.jpg' },
        { Name: 'Giây phút êm đềm', Category: "hoa-cuoi", Price: '900000', PriceOld: "", Images: '/HoaCuoi/giay-phut-em-dem.png' },
        { Name: 'Happy ending', Category: "hoa-cuoi", Price: '1320000', PriceOld: "", Images: '/HoaCuoi/happy-ending.jpg' },
        { Name: 'Một nhà', Category: "hoa-cuoi", Price: '850000', PriceOld: "", Images: '/HoaCuoi/mot-nha.png' },
        { Name: 'Hoa rum tinh khôi', Category: "hoa-cuoi", Price: '1100000', PriceOld: "", Images: '/HoaCuoi/hoa-rum-tinh-khoi.jpg' },
        { Name: 'My baby', Category: "hoa-cuoi", Price: '616000', PriceOld: "", Images: '/HoaCuoi/my-baby.jpg' },
        { Name: 'Ngày chung đôi', Category: "hoa-cuoi", Price: '750000', PriceOld: "", Images: '/HoaCuoi/ngay-chung-doi.png' },
        // Xong
        // Hoa để bàn
        { Name: 'Hoa để bàn 36', Price: '550000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-36_300x300.png' },
        { Name: 'Hoa để bàn 35', Price: '500000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-35_300x300.png' },
        { Name: 'Hoa để bàn 20', Price: '750000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-20_300x300.png' },
        { Name: 'Hoa để bàn 08', Price: '550000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-08_300x300.png' },
        { Name: 'Hoa để bàn 15', Price: '700000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-15_300x300.png' },
        { Name: 'Hoa để bàn 05', Price: '1200000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-05_300x300.png' },
        { Name: 'Hoa để bàn 03', Price: '1200000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-03_300x300.png' },
        { Name: 'Hoa để bàn 43', Price: '600000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-43_300x300.png' },
        { Name: 'Hoa để bàn 01', Price: '800000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-01_300x300.png' },
        { Name: 'Hoa để bàn 42', Price: '700000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-42_300x300.png' },
        { Name: 'Hoa để bàn 02', Price: '1200000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-02_300x300.png' },
        { Name: 'Hoa để bàn 11', Price: '750000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-11_300x300.png' },
        { Name: 'Hoa để bàn 07', Price: '600000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-07_300x300.png' },
        { Name: 'Hoa để bàn 19', Price: '600000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-19_300x300.png' },
        { Name: 'Hoa để bàn 37', Price: '600000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-37_300x300.png' },
        { Name: 'Hoa để bàn 38', Price: '600000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-38_300x300.png' },
        { Name: 'Hoa để bàn 09', Price: '650000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-09_300x300.png' },
        { Name: 'Hoa để bàn 18', Price: '650000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-18_300x300.png' },
        { Name: 'Hoa để bàn 10', Price: '700000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-10_300x300.png' },
        { Name: 'Hoa để bàn 24', Price: '700000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-24_300x300.png' },
        { Name: 'Hoa để bàn 31', Price: '700000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-31_300x300.jpg' },
        { Name: 'Hoa để bàn 41', Price: '700000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-41_300x300.png' },
        { Name: 'Hoa để bàn 44', Price: '700000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-44_300x300.png' },
        { Name: 'Hoa để bàn 21', Price: '750000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-21_300x300.png' },
        { Name: 'Hoa để bàn 23', Price: '750000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-23_300x300.png' },
        { Name: 'Hoa để bàn 13', Price: '800000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-13_300x300.png' },
        { Name: 'Hoa để bàn 22', Price: '800000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-22_300x300.png' },
        { Name: 'Hoa để bàn 27', Price: '800000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-27_300x300.jpg' },
        { Name: 'Hoa để bàn 39', Price: '800000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-39_300x300.png' },
        { Name: 'Hoa để bàn 14', Price: '850000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-14_300x300.png' },
        { Name: 'Hoa để bàn 16', Price: '850000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-16_300x300.png' },
        { Name: 'Hoa để bàn 32', Price: '850000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-32_300x300.png' },
        { Name: 'Hoa để bàn 33', Price: '850000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-33_300x300.png' },
        { Name: 'Hoa để bàn 34', Price: '850000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-34_300x300.png' },
        { Name: 'Hoa để bàn 40', Price: '850000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-40_300x300.jpg' },
        { Name: 'Hoa để bàn 17', Price: '900000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-17_300x300.jpg' },
        { Name: 'Hoa để bàn 25', Price: '900000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-25_300x300.png' },
        { Name: 'Hoa để bàn 12', Price: '950000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-12_300x300.png' },
        { Name: 'Hoa để bàn 30', Price: '950000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-30_300x300.jpg' },
        { Name: 'Hoa để bàn 26', Price: '1050000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-26_300x300.png' },
        { Name: 'Hoa để bàn 29', Price: '1050000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-29_300x300.jpg' },
        { Name: 'Hoa để bàn 06', Price: '1100000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-06_300x300.png' },
        { Name: 'Hoa để bàn 28', Price: '1200000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-28_300x300.jpg' },
        { Name: 'Hoa để bàn 04', Price: '1500000', PriceOld: "", Category: 'hoa-de-ban', Images: '/HoaDeBan/hoa-de-ban-04_300x300.png' },
        //Bánh kem
        { Name: 'bánh kem 01', Price: '450000', PriceOld: "", Category: 'banh-kem', Images: '/BanhKem/banhkem1.jpg' },
        { Name: 'bánh kem 02', Price: '450000', PriceOld: "", Category: 'banh-kem', Images: '/BanhKem/banhkem2.jpg' },
        { Name: 'bánh kem 03', Price: '450000', PriceOld: "", Category: 'banh-kem', Images: '/BanhKem/banhkem3.jpg' },
        { Name: 'bánh kem 04', Price: '450000', PriceOld: "", Category: 'banh-kem', Images: '/BanhKem/banhkem4.jpg' },
        { Name: 'bánh kem 05', Price: '450000', PriceOld: "", Category: 'banh-kem', Images: '/BanhKem/banhkem5.jpg' },
        { Name: 'bánh kem 06', Price: '450000', PriceOld: "", Category: 'banh-kem', Images: '/BanhKem/banhkem6.jpg' },
        { Name: 'bánh kem 07', Price: '450000', PriceOld: "", Category: 'banh-kem', Images: '/BanhKem/banhkem7.jpg' },
        { Name: 'bánh kem 08', Price: '450000', PriceOld: "", Category: 'banh-kem', Images: '/BanhKem/banhkem8.jpg' },
        { Name: 'bánh kem 09', Price: '450000', PriceOld: "", Category: 'banh-kem', Images: '/BanhKem/banhkem9.jpg' },
        { Name: 'bánh kem 10', Price: '450000', PriceOld: "", Category: 'banh-kem', Images: '/BanhKem/banhkem10.jpg' },
        { Name: 'bánh kem 11', Price: '450000', PriceOld: "", Category: 'banh-kem', Images: '/BanhKem/banhkem11.jpg' },
        { Name: 'bánh kem 12', Price: '450000', PriceOld: "", Category: 'banh-kem', Images: '/BanhKem/banhkem12.jpg' },
        { Name: 'bánh kem 13', Price: '450000', PriceOld: "", Category: 'banh-kem', Images: '/BanhKem/banhkem13.jpg' },
        { Name: 'bánh kem 14', Price: '450000', PriceOld: "", Category: 'banh-kem', Images: '/BanhKem/banhkem14.jpg' },
        { Name: 'bánh kem 15', Price: '450000', PriceOld: "", Category: 'banh-kem', Images: '/BanhKem/banhkem15.jpg' },
        // Xong
    ]
export default flowerData